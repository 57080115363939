import React from 'react';
import {  useSelector } from "react-redux";
import Login from './Login';
import Profile from './Profile';
import Verification from './Verification';

export default function LoginProfile(props){

    const login = useSelector((state) => state.userData.login);
    
    if(!login) return <Login/>;
    
    switch(login.status){
        case 'off':
            return <Login/>;
        case 'waitForUser':
            return <Verification/>;
        case 'on':
            return <Profile/>;
        default:
            return <Login/>;
    }
}
import React from 'react';
import NewKhatamDialog from './NewKhatamDialog';
import {connect} from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import {bindActionCreators} from 'redux';
import * as quranActions from '../../actions/actionCreators';
import { Button, MenuItem, Select } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import compareObjects from '../../utility/compareObjects';

class KhatamHeader extends React.Component{

    constructor(props){
        super(props);

        this.state={
            lastYPosition:window.pageYOffset,
            showHeader:true,
            sort:'most recent',
            isNewDialogOpen:false
        }
        this.newKhatamOnClick=this.newKhatamOnClick.bind(this);
        this.handleScroll=this.handleScroll.bind(this);
        this.languageDir = this.languageDir.bind(this);
        this.sortOnchange=this.sortOnchange.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
		// Object.entries(this.props).forEach(([key, val]) =>
		// 	prevProps[key] !== val && console.log(`Prop '${key}' changed`)
		// );
		// if (this.state) {
		// 	Object.entries(this.state).forEach(([key, val]) =>
		// 	prevState[key] !== val && console.log(`State '${key}' changed ${JSON.stringify(prevState[key])} to ${JSON.stringify(val)}`)
		// 	);
		// }

	}

    shouldComponentUpdate(nextProps,nextState){
		return(
            this.props.store.userData.theme!==nextProps.store.userData.theme ||
			this.props.store.userData.langInfo.lang!==nextProps.store.userData.langInfo.lang ||
            !compareObjects(nextState,this.state)
		)
	}
    
    componentDidMount(){
        window.addEventListener('scroll',this.handleScroll);
    }

    componentWillUnmount(){
        window.removeEventListener('scroll',this.handleScroll);
    }
    
    languageDir(rtl,ltr){
        return (this.props.store.userData.langInfo.lang !== undefined && this.props.store.userData.langInfo.direction === 'rtl') ?
            rtl : ltr;
    }

    handleScroll(){
        if(this.state.lastYPosition>window.pageYOffset && !this.state.showHeader){
            this.setState({showHeader:true,lastYPosition:window.pageYOffset})
        }else if(this.state.lastYPosition<window.pageYOffset && this.state.showHeader){
            this.setState({showHeader:false,lastYPosition:window.pageYOffset})
        }
    }

    newKhatamOnClick(){
        
        switch(this.props.store.userData.login.status){
            case 'on':
                this.setState({isNewDialogOpen:true});
                break;
            case 'off':
                this.props.actions.setLogin({status:'off',forcedOpen:true});
                break;
            default:

        }
        
    }

    sortOnchange(event){this.setState({sort:event.target.value});}

    render(){
        //determine component class based on drawer status
		let quranPageHeaderStyle = "";

        //determine component class based on language dir
        quranPageHeaderStyle=''.concat(this.props.store.userData.theme==='light'?'quranPageHeader_light ':'quranPageHeader_dark ')
                            .concat(this.props.drawerState.isDrawerOpen?"khatamHeader_od":"khatamHeader_cd");

        let quranHeaderIconsStyle=this.props.store.userData.langInfo.direction === 'rtl'?'quranPageHeaderIcons_rtl':'quranPageHeaderIcons_ltr';
        
        let rtlSelectItem=this.languageDir("drawerItem_rtl","");
        let iconStyle=(this.props.store.userData.theme==='light'?'quranHeaderIcon_light':'quranHeaderIcon_dark')
        return(
            <Slide appear={false} direction="down" in={this.state.showHeader}>
                <AppBar id='khatamHeader' classes={{root:quranPageHeaderStyle}}>
                    <div id="quranPageHeaderIcons" className={quranHeaderIconsStyle}>
                        <Tooltip title={this.props.store.userData.locale.sort}
                            classes={{tooltip:'tooltip'}}
                        >
                            <Select
                                value={this.state.sort}
                                autoWidth={true}
                                onChange={this.sortOnchange}
                                className={"".concat(rtlSelectItem)}
                            >
                                <MenuItem value={'most completed'} label="x1" >most completed</MenuItem> 
                                <MenuItem value={'most recent'} label="x2" >most recent</MenuItem>
                                <MenuItem value={'most contributed'} label="x3" >most contributed</MenuItem>
                            </Select>
                        </Tooltip>
                    </div>

                    <Button
                        id="quranPageHeaderIcons"
                        onClick={this.props.refreshList} 
                    >
                        <SyncIcon className={'quranHeaderIcon '.concat(iconStyle)}/>
                    </Button>

                    <Button 
                        id="quranPageHeaderIcons"
                        variant='outlined'
                        onClick={this.newKhatamOnClick}    
                    >
                        {'+' + this.props.store.userData.locale.newKhatam}
                    </Button>
                    <NewKhatamDialog 
                        open={this.state.isNewDialogOpen}
                        onClose={()=>{this.setState({isNewDialogOpen:false})}}
                    />
                </AppBar>
            </Slide>
        );
    }
}

function mapStateToProps(state, ownProps){
	return {
		'store': {
            userData : {
                langInfo : state.userData.langInfo,
                locale : {
                    sort:state.userData.locale.sort,
                    newKhatam:state.userData.locale.newKhatam,
                },
                theme:state.userData.theme,
                login:{
                    status:state.userData.login.status
                },
            },
        }
	};
}

function mapDispatchToProps(dispatch){
	return {
		actions: bindActionCreators(quranActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(KhatamHeader);
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as quranActions from '../../actions/actionCreators';
import { withRouter } from 'react-router-dom';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { Avatar, Badge, Box, Container } from '@material-ui/core';
import {AddAPhoto} from '@material-ui/icons';
import compareObjects from '../../utility/compareObjects';

class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchOpen: false,
            search: '',
            uName: null,
            cName: null,
            searchResultType:'',
            openSnack: false,
            messageSnack: '',
            searchPhrase:'',
            advancedSearch: false,
            allOfThem: '',
            exacts: '',
            oneOfThem: '',
            noneOfThem: '',
        };

        this.goTo = this.goTo.bind(this);
        this.closeSearch = this.closeSearch.bind(this);
        this.languageDir = this.languageDir.bind(this);
        this.openSearch=this.openSearch.bind(this);
    }

    shouldComponentUpdate(nextProps,nextState){
		return(
            this.props.store.userData.theme!==nextProps.store.userData.theme ||
			this.props.store.userData.langInfo.lang!==nextProps.store.userData.langInfo.lang ||
            !compareObjects(nextState,this.state)
		)
	}

    static getDerivedStateFromProps(nextProps,state) {
        let c=null;
        let u=null;
        if(nextProps.store.userData.login){
            u=((nextProps.store.userData.login.firstname && nextProps.store.userData.login.lasttname)?
                nextProps.store.userData.login.firstname && nextProps.store.userData.login.lasttname:
                nextProps.store.userData.login.username);
        }
        c=null;
        if(nextProps.store.userData.countryList){
            let countryObj=nextProps.store.userData.countryList.find((v,i)=>v.id===nextProps.store.userData.login.country_id);
            if(countryObj){
                switch(nextProps.store.userData.langInfo.lang){
                    case 'fa':
                        c=countryObj.country_fa;
                        break;
                    case 'ur':
                        c=countryObj.country_ur;
                        break;
                    case 'ar':
                        c=countryObj.country_ar;
                        break;
                    default:
                        c=countryObj.country_en;
                }
            }
        }
        return {cName:c,uName:u}
    }


    openSearch() {
        this.setState({searchOpen: true});
    }

    closeSearch() {
        this.setState({searchOpen: false});
    }

    goTo(sura, aya) {
        this.props.history.push("/" + this.props.store.userData.langInfo.lang + "/" + sura + ":" + aya);
        this.props.actions.changeSura(parseInt(sura));
        this.props.actions.changeAya(parseInt(aya));
    }

    languageDir(rtl,ltr){
        return (this.props.store.userData.langInfo.lang !== undefined && this.props.store.userData.langInfo.direction === 'rtl') ?
            rtl : ltr;
    }

    render() {
        
        let iconStyle=(this.props.store.userData.theme==='light'?'appbarIcon_light':'appbarIcon_dark');

        return (
            
        	<React.Fragment >
                <Tooltip title={this.props.store.userData.locale.login}
                    classes={{tooltip:'tooltip'}}
                >
                    <IconButton
                        onClick={this.openSearch}
                        className={'appbarIcon '.concat(iconStyle)}
                    >
                        <Avatar 
                            alt={this.props.store.userData.locale.quran}
                            src='/logo512.png'
                            imgProps={{style:{filter: 'drop-shadow(2px 2px 2px white)'}}}
                            variant='circle'
                        />
                    </IconButton>
                </Tooltip>
                <SwipeableDrawer
                    anchor={this.languageDir('left' , 'right')}
                    open={!!(this.state.searchOpen)}
                    onClose={this.closeSearch}
                    onOpen={this.openSearch}
                    id="loginDrawer"
                    ModalProps={{ onBackdropClick: this.closeSearch }}
                    PaperProps={{style:{backgroundColor:'#008080'}}}
                >
                    <Toolbar id='loginTopBar' 
                            className={"".concat(this.languageDir('searchTopBar_rtl ' ,'searchTopBar_ltr '))
                                        .concat(this.props.store.userData.theme==='light'?'searchTopBar_light':'searchTopBar_dark')}
                            onClick={this.closeSearch}>
                        <IconButton className={this.props.store.userData.theme==='light'?'drawerCloseIcon_light':'drawerCloseIcon_dark'}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    <Box display='flex' justifyContent='center' alignItems='center'
                        flexDirection='column'
                        style={{}}
                    >
                        <Badge
                            overlap="circle"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            badgeContent={<Avatar alt="+" style={{color:'black',backgroundColor:'white'}}><AddAPhoto /></Avatar>}
                        >
                            <Avatar 
                                alt={this.props.store.userData.locale.quran}
                                src='/logo512.png'
                                imgProps={{style:{backgroundColor: 'white'}}}
                                variant='circle'
                                id='avatarImg'
                            />
                        </Badge>
                        <Typography variant='h6' style={{
                                    fontFamily:'vazir',margin:'4vh 0 0 0',
                                    textAlign:'center',color:'#eeeeee'
                                }}
                        >
                            {this.state.uName}
                        </Typography>
                        <Typography variant='h6'style={{
                                    fontFamily:'vazir',margin:'1vh 0 4vh 0',
                                    textAlign:'center',color:'#eeeeee'
                                }}
                        >
                            {this.state.cName}
                        </Typography>
                    </Box>
                    <Container id='loginContainer'>
                        <Box id='profileNotif'>
                            <Typography variant='h3'>
                                    Khatam Notifications
                            </Typography>
                        </Box>
                    </Container>

                </SwipeableDrawer>
        	</React.Fragment>
        );
    }
}

function mapStateToProps(state, ownProps){
    return {
        store:{
            'userData':  {
                langInfo :state.userData.langInfo,
                locale:{
                    login:state.userData.locale.login,
                    quran:state.userData.locale.quran
                },
                theme:state.userData.theme,
                login:{
                    firstname:state.userData.login.firstname,
                    lasttname:state.userData.login.lasttname,
                    username:state.userData.login.username
                },
                countryList:state.userData.countryList,
            },
        }
    };
}

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(quranActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
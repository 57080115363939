import { Box, Button, Grid, LinearProgress, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as quranActions from '../../actions/actionCreators';
import JoinKhatamDialog from './JoinKhatamDialog';
import ShareKhatamDialog from './ShareKhatamDialog';
import { withRouter } from 'react-router-dom';
import compareObjects from '../../utility/compareObjects';

class KhatamListItem extends React.Component{
    constructor(props){
        super(props);
        this.state={
            shareKhatamIsOpen:false,
            joinKhatamIsOpen:false,
        }

        this.clickHandler=this.clickHandler.bind(this);
    }

    shouldComponentUpdate(nextProps,nextState){
		
		return (
			this.props.store.userData.langInfo.lang!==nextProps.store.userData.langInfo.lang ||
            !compareObjects(nextState,this.state)
		)
	}

    clickHandler(e){
        this.props.history.push('/'+ this.props.store.userData.langInfo.lang+'/khatam/'+this.props.khatam.khatam_uuid);
    }
    render(){
        let progress=Math.round((parseInt(this.props.khatam.progress)/parseInt(this.props.khatam.slicing))*100)%100;
        
        return(
            <Grid item xs={12} sm={12} md={6} lg={3} className='khatamListItemGrid'>
                <Paper variant="outlined" onClick={this.clickHandler}
                    style={{background:'white',height:'100%',position: 'relative',cursor:'pointer'}}
                >

                    {/* header */}
                    <Typography variant='h5' style={{direction:this.props.store.userData.langInfo.direction}}
                        className='khatamListItemTitle'
                    >
                        {this.props.khatam.title}
                    </Typography>
                    
                        {/* progress */}
                        <Box flexDirection='column' display="flex" style={{margin:'0 1vw'}}>
                            <LinearProgress
                                variant="determinate"
                                value={progress}
                                className='khatamListItemProgress'
                            />
                            <Typography variant="caption" component="div" color="textSecondary" style={{direction:this.props.store.userData.langInfo.direction}}>
                                {progress+'% '+this.props.store.userData.locale.ofCourse + ' ' + (parseInt(this.props.khatam.completed_course)+1)}
                            </Typography>
                        </Box>
                        
                    <Box component='p'
                        style={{
                            margin:'0',padding:'5px',direction:this.props.store.userData.langInfo.direction,display: 'block',
                            display: '-webkit-box',
                            maxWidth: '100%',
                            height: '43px',
                            margin: '0 auto',
                            lineHeight: '1',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {this.props.khatam.description}
                    </Box>

                    <Box style={{position:'absolute',bottom:'0'}}>
                        <Button onClick={(e)=>{
                            e.stopPropagation();
                            this.setState({shareKhatamIsOpen:true});
                        }}>
                            {this.props.store.userData.locale.share}
                        </Button>
                        <Button onClick={(e)=>{
                            e.stopPropagation();
                            this.setState({joinKhatamIsOpen:true});
                        }}>
                            {this.props.store.userData.locale.join}
                        </Button>
                    </Box>
                </Paper>
                <ShareKhatamDialog 
                    open={this.state.shareKhatamIsOpen}
                    onClose={()=>{this.setState({shareKhatamIsOpen:false})}}
                    link={'https://api.forghan.com/khatam/' + this.props.khatam.khatam_uuid}
                />
                <JoinKhatamDialog
                    open={this.state.joinKhatamIsOpen}
                    onClose={()=>{this.setState({joinKhatamIsOpen:false})}}
                    khatam={{uuid:this.props.khatam.khatam_uuid,slicing:this.props.khatam.slicing}}
                />
            </Grid>
        );
    };

}
function mapStateToProps(state, ownProps) {
    return {
        'store':{
            'userData': {
                langInfo : state.userData.langInfo,
                locale:{
                    join : state.userData.locale.join,
                    share : state.userData.locale.share,
                    ofCourse : state.userData.locale.ofCourse
                }
            }
        }
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(quranActions, dispatch)
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KhatamListItem));
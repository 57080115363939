import * as types from '../actions/actions';

export default function quranReducer(state = [], action) {
      // console.log(JSON.stringify(action.payload));
	switch(action.type){

            case types.SET_FIRST_TIME:
                  return{...state,firstTime:action.payload};
            case types.CHANGE_LANGUAGE:
                  let locale = require('../locale/' + action.payload.lang + '.js');
                  return {...state,langInfo: action.payload,locale: locale.default};
            case types.CHANGE_SURA:
                  return {...state,sura: action.payload}
            case types.CHANGE_AYA:
                  return {...state,aya:action.payload};
            case types.SET_THEME:
      		return {...state,theme:action.payload};
            case types.SET_COUNTRY:
                  return {...state,country:action.payload};
            case types.SET_LOGIN:
                  return {...state, login:action.payload};
            case types.SET_COUNTRY_LIST:
                  return {...state,countryList:action.payload};
            case types.SET_SUGGEST_LOGIN:
                  return {...state,suggestLogin:action.payload};
		default: 
      		return state;
	}
}
import * as types from '../actions/actions';

export default function reciteReducer(state = [], action) {
    // console.log(JSON.stringify(action.payload));

	switch(action.type){

		case types.GET_RECITE_LIST_DONE:
			return {...state , reciteList:action.payload};

		case types.CHANGE_RECITATION:
			return {...state,
				selectedRecite:{
					recitorId:action.payload.id,
					reciteType : action.payload.type,
					reciteQuality : action.payload.quality,
				}
			}

		case types.CHANGE_PLAYERCNF:
			return {...state,playerCnf: action.payload}

		default:
      		return state;
	}
}
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as quranActions from '../../actions/actionCreators';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FloatingActionButton from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import ToTopIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Snackbar from '@material-ui/core/Snackbar';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemText from '@material-ui/core/ListItemText';
import env from '../../env.js';
import QuranMeta from '../../quranMeta/QuranMeta';
import convertToArabicNumber from '../../utility/convertToArabicNumber';
import { Container } from '@material-ui/core';

class Search extends React.Component {
//
    constructor(props) {
        super(props);
        this.state = {
            searchOpen: false,
            search: '',
            searchResults: [],
            searchType: 'Quran',
            searchResultType:'',
            openSnack: false,
            messageSnack: '',
            searchPhrase:'',
            advancedSearch: false,
            allOfThem: '',
            exacts: '',
            oneOfThem: '',
            noneOfThem: '',
        };

        this.getSearch = this.getSearch.bind(this);
        this.changeSearch = this.changeSearch.bind(this);
        this.generateSearchResults = this.generateSearchResults.bind(this);
        this.clickResult = this.clickResult.bind(this);
        this.goTo = this.goTo.bind(this);
        this.closeSearch = this.closeSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.changeInput = this.changeInput.bind(this);
        this.changeAdvanced = this.changeAdvanced.bind(this);
        this.changeAllOfThem = this.changeAllOfThem.bind(this);
        this.changeExacts = this.changeExacts.bind(this);
        this.changeOneOfThem = this.changeOneOfThem.bind(this);
        this.changeNoneOfThem = this.changeNoneOfThem.bind(this);
        this.languageDir = this.languageDir.bind(this);
        this.openSearch=this.openSearch.bind(this);
    }

    componentDidUpdate(){
        setTimeout(() => {
            //set font family to search result list item from display options
            let listItems = document.querySelectorAll("#search_result span[class^=MuiTypography-root]");
            let font='Quran Taha';
            if(this.state.searchType==='Quran'){
                font=this.props.store.quranData.quranDisplay.fontFamily;
            }
            else if(this.state.searchType==='Tarjoman') font=this.props.store.transData.transDisplay.fontFamily;
            listItems.forEach(element => {
                element.style.fontFamily= font;
            });
        }, 100);
    }

    openSearch() {
        this.setState({searchOpen: true});
    }

    closeSearch() {
        this.setState({searchOpen: false});
    }

    changeSearch(event) {
        this.setState({search: event.target.value});
    }

    getSearch() {
        let self = this;

        let search = '';
        let searchType=this.state.searchType;
        switch(searchType){
            case 'Tafseer': 
                search =  {simple:this.state.searchPhrase,type:searchType};
                break;
            case 'Tarjoman': 
                search =  {simple:this.state.searchPhrase,type:searchType,translist:this.props.store.transData.selectedTrans};
                break;
            case 'Quran':
                if (this.state.advancedSearch) {
                    search = {
                        allOfThem: this.state.allOfThem,
                        exacts: this.state.exacts,
                        oneOfThem: this.state.oneOfThem,
                        noneOfThem: this.state.noneOfThem
                        ,type:searchType
                    };
                } else {
                    search = {simple:this.state.searchPhrase,type:searchType};
                }
                break;
            case 'albalaq': 
                search =  {simple:this.state.searchPhrase,type:searchType};
                break;
            default:
                return;
        }
        
        if (search === '' || search===null || search===undefined) { return; }

        axios.get(env.baseURL +'/search' , {params:search},{crossdomain: true}).then(results => {
            let resultCount=this.languageDir(convertToArabicNumber(results.data.length),results.data.length);
            let message = (results.data.length !== 0)?  resultCount + ' نتیجه یافت شد.' : 'نتیجه ای یافت نشد.';
            
            self.setState({
                searchResults: results.data,
                openSnack: true,
                messageSnack: message,
                searchResultType:searchType
            });

        }).catch(error => {
            throw(error);
        });
    }

    goTo(sura, aya) {
        this.props.history.push("/" + this.props.store.userData.langInfo.lang + "/" + sura + ":" + aya);
        this.props.actions.changeSura(parseInt(sura));
        this.props.actions.changeAya(parseInt(aya));
    }

    clickResult(nav){
        this.closeSearch();
        this.goTo(nav[0], nav[1]);
    }

    generateSearchResults(result,index){
        let suraDetails={};
        try{
            suraDetails=QuranMeta.getSuraDetails(result.sura);
        }catch(er){
        }
        let sura=this.languageDir(suraDetails.ar_name,suraDetails.en_name);
        let aya=this.languageDir(convertToArabicNumber(result.aya),result.aya);
        switch(this.state.searchResultType){
            case 'Quran':
                return(
                    <ListItem
                        key={index}
                        onClick={()=>this.clickResult([result.sura, result.aya])}
                        className={"listItem_rtl"}
                    >
                        <ListItemText 
                            primary={ convertToArabicNumber(result.text).replace(/<span>.......<\/span>/g, '') }
                            secondary={  sura + ' | ' +  aya}

                            />
                    </ListItem>
                );
            case 'Tarjoman':
                return(
                    <ListItem
                        key={index}
                        onClick={()=>this.clickResult([result.sura, result.aya])}
                        className={"listItem_rtl"}
                    >
                        <ListItemText 
                            primary={convertToArabicNumber(result.text).replace(/<span>.......<\/span>/g, '') }
                            secondary={  sura + ' | ' + aya}
                        />
                        
                    </ListItem>
                );
            default:
                return null;
        }
        
    }

    handleChange(event,value) {
        this.setState({
            searchType: value,
        });
    };

    handleRequestClose() {
        this.setState({
            openSnack: false,
        });
    };

    changeInput(event){
        if (parseInt(event.charCode) === 13) {
            this.getSearch();
        }
    }

    changeAdvanced(){
        this.setState({advancedSearch: !this.state.advancedSearch});
    }

    generateToTopAdvancedSearch(){
        if (this.state.advancedSearch && this.state.searchType === "Quran") {
            return (
                <FloatingActionButton
                    mini={"true"}
                    className={"searchButton ".concat(this.languageDir("searchButton_ltr","searchButton_rtl"))}
                    onClick={this.changeAdvanced}
                    >
                  <ToTopIcon/>
                </FloatingActionButton>
            );
        }
        return (<span></span>);
    }

    changeAllOfThem(e){

        this.setState({allOfThem: e.target.value});
    }

    changeExacts(e){
        this.setState({exacts: e.target.value});
    }

    changeOneOfThem(e){
        this.setState({oneOfThem: e.target.value});
    }

    changeNoneOfThem(e){
        this.setState({noneOfThem: e.target.value});
    }


    generateAdvancedSearch(){
        if (this.state.advancedSearch) {

            return (
                <Card id="advancedSearchContainer">
                        <TextField
                            className={"textField ".concat(this.languageDir("textField_rtl","textField_ltr"))}
                            label={this.props.store.userData.locale.allOfThem}
                            fullWidth={true}
                            value={this.state.allOfThem}
                            onChange={this.changeAllOfThem}
                        />
                        <TextField
                            className={"textField ".concat(this.languageDir("textField_rtl","textField_ltr"))}
                            label={this.props.store.userData.locale.exacts}
                            fullWidth={true}
                            value={this.state.exacts}
                            onChange={this.changeExacts}
                        />
                        <TextField
                            className={"textField ".concat(this.languageDir("textField_rtl","textField_ltr"))}
                            label={this.props.store.userData.locale.oneOfThem}
                            fullWidth={true}
                            value={this.state.oneOfThem}
                            onChange={this.changeOneOfThem}
                        />
                        <TextField
                            className={"textField ".concat(this.languageDir("textField_rtl","textField_ltr"))}
                            label={this.props.store.userData.locale.noneOfThem}
                            fullWidth={true}
                            value={this.state.noneOfThem}
                            onChange={this.changeNoneOfThem}
                        />

                </Card>
            );
        }
        return (
            <span></span>
        );
    }

    languageDir(rtl,ltr){
        return (this.props.store.userData.langInfo.lang !== undefined && this.props.store.userData.langInfo.direction === 'rtl') ?
            rtl : ltr;
    }

    render() {
        let iconStyle=(this.props.store.userData.theme==='light'?'appbarIcon_light':'appbarIcon_dark');
        
        return (
            
        	<React.Fragment >
                <Tooltip title={this.props.store.userData.locale.search}
                    classes={{tooltip:'tooltip'}}
                >
                    <IconButton
                        onClick={this.openSearch}
                        className={'appbarIcon '.concat(iconStyle)}
                    >
                        <SearchIcon fontSize='small'/>
                    </IconButton>
                </Tooltip>
                <SwipeableDrawer
                    anchor={this.languageDir('left' , 'right')}
                    open={!!(this.state.searchOpen)}
                    onClose={this.closeSearch}
                    onOpen={this.openSearch}
                    id="searchDrawer"
                    ModalProps={{ onBackdropClick: this.closeSearch }}
                >
                    <Toolbar id='searchTopBar' 
                            className={"".concat(this.languageDir('searchTopBar_rtl ' ,'searchTopBar_ltr '))
                                        .concat(this.props.store.userData.theme==='light'?'searchTopBar_light':'searchTopBar_dark')}
                            onClick={this.closeSearch}>
                        <IconButton className={this.props.store.userData.theme==='light'?'drawerCloseIcon_light':'drawerCloseIcon_dark'}>
                            <CloseIcon />
                        </IconButton>
                        <Typography 
                                id={this.props.store.userData.theme==='light'?'searchTitle_light':'searchTitle_dark'}
                                variant="h6" noWrap >
                            {this.props.store.userData.locale.search} 
                        </Typography>
                    </Toolbar>
                    <Container id='searchContent' className={""
                                    .concat(this.languageDir('searchContent_rtl ' ,'searchContent_ltr '))
                                    .concat(this.props.store.userData.theme==='light'?'searchContent_light':'searchContent_dark')}>
                        <Tabs
                            value={this.state.searchType}
                            onChange={this.handleChange}
                            className={this.languageDir("tabs_rtl","tabs_ltr")}
                            variant='fullWidth'
                        >
                            <Tab  label={this.props.store.userData.locale.quran} value="Quran"/>
                            <Tab  label={this.props.store.userData.locale.translation} value="Tarjoman"/>
                            {/* <Tab  label={this.props.store.userData.locale.albalaq} value="Albalaq"/>
                            <Tab  label={this.props.store.userData.locale.tafseer} value="Tafseer"/> */}
                        </Tabs>

                        {/* tab contents */}
                        <div style = {{display : (this.state.searchType!=="Quran" ? "none":"block")}} >
                            <div style = {{width:'100%',display:'flex',position:"relative"}}>
                                <input
                                    type="text"
                                    placeholder={`${this.props.store.userData.locale.search} ${this.props.store.userData.locale.in} ${this.props.store.userData.locale.quran}`}
                                    name="search"
                                    id="searchQ"
                                    className={"searchBox ".concat(this.languageDir("searchBox_rtl","searchBox_ltr"))}
                                    onKeyPress={this.changeInput} 
                                    disabled={this.state.advancedSearch ? "disabled":null}
                                    onBlur={(e)=>{this.setState({searchPhrase:e.target.value})}}
                                    />

                                    <Button onClick={this.changeAdvanced}
                                            disabled={this.state.advancedSearch}
                                            id="advanceSearchButton"
                                            className={"".concat(this.languageDir("advanceSearchButton_rtl","advanceSearchButton_ltr"))}
                                            primary={toString(this.state.advancedSearch)} 
                                            >
                                        {`${this.props.store.userData.locale.advancedSearch}`}
                                    </Button>
                                </div>

                                {this.generateAdvancedSearch()}
                        </div>
                        <div style = {{display : (this.state.searchType!=="Tarjoman" ? "none":"flex")}}>
                            <input
                                type="text"
                                placeholder={`${this.props.store.userData.locale.search} ${this.props.store.userData.locale.in} ${this.props.store.userData.locale.translation}`}
                                name="search"
                                id="searchTar"
                                className={"searchBox ".concat(this.languageDir("searchBox_rtl","searchBox_ltr"))}
                                onBlur={(e)=>{this.setState({searchPhrase:e.target.value})}}
                                onKeyPress={this.changeInput} />
                        </div>
                        <div style = {{display : (this.state.searchType!=="Albalaq" ? "none":"flex")}}>
                             <input
                                type="text"
                                placeholder={`${this.props.store.userData.locale.search} ${this.props.store.userData.locale.in} ${this.props.store.userData.locale.albalaq}`}
                                name="search"
                                id="searchA"
                                className={"searchBox ".concat(this.languageDir("searchBox_rtl","searchBox_ltr"))}
                                onBlur={(e)=>{this.setState({searchPhrase:e.target.value})}}
                                onKeyPress={this.changeInput} />
                        </div>
                        <div style = {{display : (this.state.searchType!=="Tafseer" ? "none":"flex")}}>
                            <input
                                disabled="disabled"
                                type="text"
                                placeholder={`${this.props.store.userData.locale.search} ${this.props.store.userData.locale.in} ${this.props.store.userData.locale.tafseer}`}
                                name="search"
                                id="searchTaf"
                                className={"searchBox ".concat(this.languageDir("searchBox_rtl","searchBox_ltr"))}
                                onBlur={(e)=>{this.setState({searchPhrase:e.target.value})}}
                                onKeyPress={this.changeInput} />
                        </div>

                        <FloatingActionButton
                            mini="false"
                            className={"searchButton ".concat(this.languageDir("searchButton_rtl","searchButton_ltr"))}
                            onClick={this.getSearch}
                            >
                          <SearchIcon />
                        </FloatingActionButton>

                        {this.generateToTopAdvancedSearch()}

                    </Container>

                    <Snackbar
                        open={this.state.openSnack}
                        message={this.state.messageSnack}
                        action="Ok"
                        autoHideDuration={5000}
                        onClose={this.handleRequestClose}
                    />

                    <List id="search_result" style={{visibility: (this.state.searchType===this.state.searchResultType) ? 'visible': 'hidden'}}>
                        
                        { this.state.searchResults.map(this.generateSearchResults) }

                    </List>

                </SwipeableDrawer>
        	</React.Fragment>
        );
    }
}

function mapStateToProps(state, ownProps){
    return {
        store:{
            'userData':  {
                langInfo :state.userData.langInfo,
                locale:state.userData.locale,
                theme:state.userData.theme
            },
            transData :{
                selectedTrans:state.transData.selectedTrans,
                transDisplay:{
                    fontFamily:state.transData.transDisplay.fontFamily
                }
            },
            quranData:{
                quranDisplay :{
                    fontFamily: state.quranData.quranDisplay.fontFamily,
                }
            }
        }
    };
}

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(quranActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
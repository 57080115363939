import {Box, Button, Chip, CircularProgress, FormControlLabel, IconButton, List, ListItem, ListItemText, Paper, Snackbar, Switch, Typography} from '@material-ui/core';
import axios from 'axios';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as quranActions from '../../actions/actionCreators';
import ActionBar from './ActionBar';
import Slider from './Slider';
import env from '../../env.js';
import sha256 from 'js-sha256';
import ShareKhatamDialog from './ShareKhatamDialog';
import JoinKhatamDialog from './JoinKhatamDialog';
import {Settings} from '@material-ui/icons';

class KhatamPage extends React.Component{

    constructor(props){
        super(props);

        this.state={
            isDrawerOpen:false,
            khatamData:null,
            snack:{open:false,msg:''},
            waitForServer:true,
            amIOwner:false,
            slicingName:'',
            shareKhatamIsOpen:false,
            joinKhatamIsOpen:false,
            waitForContributers:false,
            contributers:[]
        }

        this.generateChips=this.generateChips.bind(this);
        this.getContributers=this.getContributers.bind(this);
        this.generateContributers=this.generateContributers.bind(this);
    }

    componentDidMount(){
        this.setState({khatamData:null,waitForServer:true});
        axios.post(env.baseURL+'/get_khatam',{khatamUUId:this.props.match.params.khatamId})
        .then((result)=>{
            let temp=false;
            if(this.props.store.userData.login.username){
                temp=(result.data.owner===sha256(this.props.store.userData.login.username));
            }

            let slicingName='';
            switch(result.data.slicing){
                case 604:
                    slicingName=this.props.store.userData.locale.page;
                    break;
                case 240:
                    slicingName=this.props.store.userData.locale.rubElHizb;
                    break;
                case 60:
                    slicingName=this.props.store.userData.locale.hizb;
                    break;
                case 30:
                    slicingName=this.props.store.userData.locale.juz;
                    break;
                case 7:
                    slicingName=this.props.store.userData.locale.manzil;
                    break;
                default:
                    slicingName='';
            }

            this.setState({
                khatamData:result.data,
                amIOwner:temp,
                slicingName:slicingName,
            });
        })
        .catch((err)=>{this.setState({snack:{open:true,msg:'server connection error: '+ err}})})
        .finally(()=>{this.setState({waitForServer:false});});
    }

    getContributers(){
        this.setState({waitForContributers:true,contributers:[]});
        axios.post(env.baseURL+'/get_contributers',{khatamUuid:this.state.khatamData.khatam_uuid,username:this.props.store.userData.login.username})
        .then((result)=>{console.log(result.data)
            this.setState({contributers:result.data});
        })
        .catch((err)=>{this.setState({snack:{open:true,msg:'server connection error: '+ err}})})
        .finally(()=>{this.setState({waitForContributers:false})});
    }

    generateChips(){
        return(
            this.state.khatamData.tags.map((v,i)=>{
                return (<Chip key={i} label={'#'+v} 
                            style={{marginInlineEnd:'7px',color:'#008080',backgroundColor:'rgba(238, 238, 238, 0.5)'}}
                        />);
            })
        );
    }

    generateContributers(){
        return(
            this.state.contributers.map((v,i)=>
                <ListItem>
                    <ListItemText primary={v.phone} secondary={v.amountt} />
                </ListItem>
            )
        )
    }

    render(){

        let drawerState = {
			isDrawerOpen: this.state.isDrawerOpen,
			toggleDrawer: () => { this.setState({ isDrawerOpen: !this.state.isDrawerOpen }); }
        }

        let wrapperStyle = "";
        if (this.state.isDrawerOpen) {
			wrapperStyle = "khatamSection_od ".concat(this.selectByLang('wrapper_rtl','wrapper_ltr'));
		} else {
			wrapperStyle = "khatamSection_cd";
		}

        let paperTypoStyle={
            textAlign:'center',
        }

        let paperButtonStyle={
            boxShadow: 'none' ,
            backgroundColor: 'var(--primary-light)',
            color: 'var(--white)',
            width:'50%'
        }

        return(
            <React.Fragment>
                <ActionBar drawerState={drawerState}/>
                <Slider/>
                <section id={"khatamSection"} className={wrapperStyle}>

                    {this.state.waitForServer===true || this.state.khatamData===null ? <CircularProgress id='mainKhatamProgress'/> :

                        <Box id='khatamPageContent' display='flex' flexDirection='column' justifyContent='flex-start'
                            alignItems='center'                            
                        >
                            
                            <Typography variant='h3' style={{marginTop:'5vh'}}>
                                {this.state.khatamData.title}
                            </Typography>

                            {/* <Typography variant='caption'>
                                {this.state.khatamData.owner}
                            </Typography> */}

                            <Typography variant='subtitle2' style={{marginTop:'6vh'}}>
                                {this.state.khatamData.description}
                            </Typography>

                            <Box display='flex' flexDirection='row' justifyContent='space-around' width='100%' marginTop='7vh'
                                flexWrap='wrap'
                            >
                                <Paper id='khatamPagePaper' variant="elevation" elevation={3}>
                                    <Box display='flex' flexDirection='column' alignItems='center' height='100%' justifyContent='space-around'>
                                        <Typography variant='h2' style={paperTypoStyle}>{this.state.khatamData.progress}</Typography>
                                        <Typography variant='body1' style={paperTypoStyle}>{this.state.slicingName + ' Done'}</Typography>
                                        {
                                            this.state.amIOwner ? 
                                                <Button style={paperButtonStyle} variant='text' onClick={this.getContributers}>
                                                    {this.props.store.userData.locale.contributers}
                                                </Button> : null}
                                    </Box>
                                </Paper>

                                <Paper id='khatamPagePaper' variant="elevation" elevation={3}>
                                    <Box display='flex' flexDirection='column' alignItems='center' height='100%' justifyContent='space-around'>
                                        <Typography variant='h2' style={paperTypoStyle}>{this.state.khatamData.slicing-this.state.khatamData.progress}</Typography>
                                        <Typography variant='body1' style={paperTypoStyle}>{this.state.slicingName + ' Left'}</Typography>
                                            <Button
                                                style={paperButtonStyle}
                                                variant='text'
                                                onClick={()=>{this.setState({joinKhatamIsOpen:true});}}
                                            >
                                                {this.props.store.userData.locale.join}
                                            </Button>
                                    </Box>
                                </Paper>

                                <Paper id='khatamPagePaper' variant="elevation" elevation={3}>
                                    <Box display='flex' flexDirection='column' alignItems='center' height='100%' justifyContent='space-around'>
                                        <Typography variant='h2' style={paperTypoStyle}>{this.state.khatamData.completed_course}</Typography>
                                        <Typography variant='body1' style={paperTypoStyle}>{'Courses Over'}</Typography>
                                            <Button
                                                style={paperButtonStyle}
                                                variant='text'
                                                onClick={()=>{this.setState({shareKhatamIsOpen:true});}}
                                            >
                                                {this.props.store.userData.locale.share}
                                            </Button>
                                    </Box>
                                </Paper>
                            </Box>
                            
                            {/* <Button><Settings/></ Button> */}
                            {/* <Box id='khtamaPageSwitches' display='flex'>
                                <FormControlLabel
                                    value="bottom"
                                    control={<Switch color="primary" />}
                                    label={this.props.store.userData.locale.doNotShowToPublic}
                                    labelPlacement="bottom"
                                 />
                                 <FormControlLabel
                                    value="bottom"
                                    control={<Switch color="primary" />}
                                    label={this.props.store.userData.locale.doNotShowToPublic}
                                    labelPlacement="bottom"
                                 />
                                 <FormControlLabel
                                    value="bottom"
                                    control={<Switch color="primary" />}
                                    label={this.props.store.userData.locale.doNotShowToPublic}
                                    labelPlacement="bottom"
                                 />
                                 <FormControlLabel
                                    value="bottom"
                                    control={<Switch color="primary" />}
                                    label={this.props.store.userData.locale.doNotShowToPublic}
                                    labelPlacement="bottom"
                                 />
                            </Box> */}

                            <Typography variant='h6' 
                                style={{
                                    alignSelf: this.props.store.userData.langInfo.direction==='rtl' ? 'flex-end':'flex-start',
                                    marginTop: '7vh'
                                }}
                            >
                                {this.props.store.userData.locale.tags}
                            </Typography>

                            <Box display='flex' flexDirection='row'
                                style={{
                                    alignSelf: this.props.store.userData.langInfo.direction==='rtl' ? 'flex-end':'flex-start',
                                }}
                            >
                                {this.generateChips()}
                            </Box>

                            {this.state.amIOwner ?
                                <React.Fragment>
                                    <Typography variant='h6' 
                                        style={{
                                            alignSelf: this.props.store.userData.langInfo.direction==='rtl' ? 'flex-end':'flex-start',
                                            marginTop: '1vh'
                                        }}
                                    >
                                        {this.props.store.userData.locale.contributers}
                                    </Typography>
                                    
                                    {
                                        this.state.waitForContributers?
                                            <CircularProgress /> :
                                            <List style={{
                                                    alignSelf: this.props.store.userData.langInfo.direction==='rtl' ? 'flex-end':'flex-start',
                                                    marginTop: '1vh'
                                                }}
                                            >
                                                {this.generateContributers()}
                                            </List>
                                    }
                                </React.Fragment>
                                : null
                            }

                            
                        </Box>

                    }
                                       
                </section>

                <Snackbar
                    open={this.state.snack.open}
                    message={this.state.snack.msg}
                    action="Ok"
                    autoHideDuration={3000}
                    onClose={()=>{this.setState({snack:{open:false,msg:''}})}}
                />
                <ShareKhatamDialog
                    open={this.state.shareKhatamIsOpen}
                    onClose={()=>{this.setState({shareKhatamIsOpen:false})}}
                    link={this.state.khatamData ? 'https://api.forghan.com/khatam/' + this.state.khatamData.khatam_uuid : ''}
                />
                <JoinKhatamDialog
                    open={this.state.joinKhatamIsOpen}
                    onClose={()=>{this.setState({joinKhatamIsOpen:false})}}
                    khatam={this.state.khatamData ? {uuid:this.state.khatamData.khatam_uuid,slicing:this.state.khatamData.slicing}:null}
                />
            </React.Fragment>
        );
    }

}

function mapStateToProps(state, ownProps){
	return {
		'store': {
            userData : {
                sura:state.userData.sura,
                aya:state.userData.aya,
                langInfo : state.userData.langInfo,
                locale : {
                    readingMode: state.userData.locale.readingMode,
                    nightmode:state.userData.locale.nightmode,
                    sort:state.userData.locale.sort,
                    newKhatam:state.userData.locale.newKhatam,
                    page:state.userData.locale.page,
                    rubElHizb:state.userData.locale.rubElHizb,
                    hizb:state.userData.locale.hizb,
                    juz:state.userData.locale.juz,
                    manzil:state.userData.locale.manzil,
                    share:state.userData.locale.share,
                    join:state.userData.locale.join,
                    doNotShowToPublic:state.userData.locale.doNotShowToPublic,
                    tags:state.userData.locale.tags,
                    contributers:state.userData.locale.contributers,
                },
                theme:state.userData.theme,
                login:{
                    status:state.userData.login.status,
                    username:state.userData.login.username
                },
            },
            transData:{
                showTranslate:state.transData.showTranslate,
            }
        }
	};
}

function mapDispatchToProps(dispatch){
	return {
		actions: bindActionCreators(quranActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(KhatamPage);
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as quranActions from '../../actions/actionCreators';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Tooltip from '@material-ui/core/Tooltip';
import SidebarItems from './SidebarItems';

class SidebarDrawer extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            iconStyle:(props.store.userData.theme==='light'?'appbarIcon_light':'appbarIcon_dark')
        };		
        
        this.generateDrawer=this.generateDrawer.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.generateMenuIcon = this.generateMenuIcon.bind(this);
        this.generateLogo = this.generateLogo.bind(this);
        this.selectByLang=this.selectByLang.bind(this);
    }

    handleToggle() {
        this.props.drawerState.toggleDrawer();
    }

    generateMenuIcon() {

        //if drawer is open change icon of menu to arrow 
        if (this.props.drawerState.isDrawerOpen) {
            if (this.props.store.userData.langInfo.lang !== undefined && this.props.store.userData.langInfo.direction === 'rtl') {
                return (
                    <Tooltip title={this.props.store.userData.locale.closeMenu}
                    classes={{tooltip:'tooltip'}}
                >
                        <IconButton
                            onClick={this.handleToggle}
                            className={'appbarIcon '.concat(this.state.iconStyle)}
                        >
                            <ArrowForwardIcon fontSize='small'/>
                        </IconButton>
                    </Tooltip>
                );
            }
            return (
                <Tooltip title={this.props.store.userData.locale.closeMenu}
                classes={{tooltip:'tooltip'}}
            >
                    <IconButton
                        onClick={this.handleToggle}
                        className={'appbarIcon '.concat(this.state.iconStyle)}
                    >
                        <ArrowBackIcon fontSize='small'/>
                    </IconButton>
                </Tooltip>
            );
        };
        //if drawer is closed menu icon is a menu icon
        return (
            <Tooltip title={this.props.store.userData.locale.searchAndFilter}
            classes={{tooltip:'tooltip'}}
        >
                <IconButton
                    onClick={this.handleToggle}
					className={'appbarIcon '.concat(this.state.iconStyle)}
                >
                    <MenuIcon fontSize='small' />
                </IconButton>
            </Tooltip>
        );
    }

    generateLogo() {
        if (this.props.drawerState.isDrawerOpen) {
            return;
        };
        return (
            <Typography 
                id="logo" 
                className={this.props.store.userData.theme==='light'?'logo_light':'logo_dark'}
            >
                {this.props.store.userData.locale.forghan}
            </Typography>
        );
    }

    generateDrawer(){
        //device size
        let xs = window.matchMedia(`(max-width: 600px)`);
        let sm = window.matchMedia(`(min-width: 600px) and (max-width: 768px)`);
        let md = window.matchMedia(`(min-width: 768px) and (max-width: 992px)`);
        let lg = window.matchMedia(`(min-width: 992px) and (max-width: 1200px)`);
        let xl = window.matchMedia(`(min-width: 1200px)`);

       if(md.matches || xs.matches || sm.matches){
           
           return (<SwipeableDrawer
                        anchor={this.selectByLang('right' , 'left')}
                        open={!!(this.props.drawerState.isDrawerOpen)}
                        onClose={this.handleToggle.bind(this)}
                        onOpen={this.handleToggle.bind(this)}
                        id='sidebarDrawer'
                        ModalProps={{ onBackdropClick: this.handleToggle.bind(this) }}
                    >
                        <SidebarItems drawerState={this.props.drawerState}/>
                    </SwipeableDrawer>);
       }else if(lg.matches || xl.matches){
   
           return (<Drawer
                        variant={"persistent"}
                        anchor={this.selectByLang('right' , 'left')}
                        open={!!(this.props.drawerState.isDrawerOpen)}
                        id='sidebarDrawer'
                   >
               
                   <SidebarItems drawerState={this.props.drawerState}/>
                   
               </Drawer>)
       }
    }

    selectByLang(rtl,ltr){
        return (this.props.store.userData.langInfo.direction === 'rtl') ? rtl : ltr;
    }

    render() {

        return (
            <div id='appbarMenuTitle' className={this.selectByLang('appbarMenuTitle_rtl','appbarMenuTitle_ltr')}>
                {this.generateMenuIcon()}

                {this.generateLogo()}

                {this.generateDrawer()}
                
            </div>
        );
    }
}

function mapStateToProps(state, ownProps){
    return {
        store:{
            'userData':  {
                langInfo:state.userData.langInfo,
                locale:state.userData.locale,
                theme:state.userData.theme
            }
        }
    }
}

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(quranActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarDrawer);
export const LOAD_QURANTEXT_SUCCESS = 'LOAD_QURANTEXT_SUCCESS';
export const LOAD_TRANSLATETEXT_SUCCESS = 'LOAD_TRANSLATETEXT_SUCCESS';
export const SET_SHOW_TRANSLATION = 'SET_SHOW_TRANSLATION';
export const LOAD_USER_DATA = 'LOAD_USER_DATA';
export const CHANGE_SURA = 'CHANGE_SURA';
export const CHANGE_AYA = 'CHANGE_AYA';
export const CHANGE_QURAN_DISPLAY_OPTIONS = 'CHANGE_QURAN_DISPLAY_OPTIONS';
export const CHANGE_TRANSLATE_DATA = 'CHANGE_TRANSLATE_DATA';
export const ADD_TRANSLATE_DATA = 'ADD_TRANSLATE_DATA';
export const CHANGE_RECITATION = 'CHANGE_RECITATION';
export const DELETE_TRANSLATETEXT = 'DELETE_TRANSLATETEXT';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_PLAYERCNF = 'CHANGE_PLAYERCNF';
export const GET_TRANSLATION_LIST_DONE = 'GET_TRANSLATION_LIST_DONE';
export const SET_SELECTED_TRANS = 'SET_SELECTED_TRANS';
export const DELETE_TRANSLATION = 'DELETE_TRANSLATION';
export const GET_RECITE_LIST_DONE='GET_RECITE_LIST_DONE';
export const SET_THEME= 'SET_THEME';
export const SET_TAJWID_COLORS='SET_TAJWID_COLORS';
export const SET_FIRST_TIME='SET_FIRST_TIME';
export const SET_COUNTRY='SET_COUNTRY';
export const SET_LOGIN='SET_LOGIN';
export const GET_VERIFICATION='GET_VERIFICATION';
export const CHECK_VERIFICATION='CHECK_VERIFICATION';
export const GET_COUNTRY_LIST='GET_COUNTRY_LIST';
export const SET_COUNTRY_LIST='SET_COUNTRY_LIST';
export const SET_SUGGEST_LOGIN='SET_SUGGEST_LOGIN';
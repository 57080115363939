import {createStore, applyMiddleware,compose} from 'redux';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';


export default function configureStore(initialState) {

  const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;


  //redux-immutable-state-invariant is a middleware used to avoid store mutation
  //thunk is a middleware used to allow add action creators that return a function other than an object
  // Be sure to ONLY add this middleware in development!
  const middleware = process.env.NODE_ENV !== 'production' ?
            [require('redux-immutable-state-invariant').default(), thunk] :
            [thunk];


  const enhancer = composeEnhancers(
    applyMiddleware(...middleware),
    // other store enhancers if any
  );

  return createStore(rootReducer,initialState,enhancer);

  // return createStore(
  //   rootReducer,
  //   initialState,
  //   applyMiddleware(...middleware)
  // );
}
import React from 'react';
import {  useSelector } from "react-redux";
import convertToArabicNumber from '../../utility/convertToArabicNumber';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { 
    Box, Checkbox, Grid, Paper,Typography 
} from "@material-ui/core";
export default function SlicingCards(props){
    const langInfo = useSelector((state) => state.userData.langInfo);
    const localePage = useSelector((state) => state.userData.locale.page);
    const localePages = useSelector((state) => state.userData.locale.pages);
    const localeJuz = useSelector((state) => state.userData.locale.juz);
    const localeHizb = useSelector((state) => state.userData.locale.hizb);
    const localeRubElHizb = useSelector((state) => state.userData.locale.rubElHizb);
    const localeManzil = useSelector((state) => state.userData.locale.manzil);
    const localeInclude = useSelector((state) => state.userData.locale.include);
    const localeAbout = useSelector((state) => state.userData.locale.about);
    let title='';
    let desc='';
    switch(Number.parseInt(props.count)){
        case 604:
            title=localePage;
            desc=localeInclude+' ' + (langInfo.direction==='rtl'?convertToArabicNumber(604):604) + ' ' + localePages  ;
            break;
        case 30:
            title=localeJuz;
            desc=localeAbout+' ' + (langInfo.direction==='rtl'?convertToArabicNumber(21):21) + ' ' + localePages ;
            break;
        case 60:
            title=localeHizb;
            desc=localeAbout+' ' + (langInfo.direction==='rtl'?convertToArabicNumber(10):10) + ' ' + localePages ;
            break;
        case 240:
            title=localeRubElHizb;
            desc=localeAbout+' ' + (langInfo.direction==='rtl'?convertToArabicNumber(3):3) + ' ' + localePages  ;
            break;
        case 7:
            title=localeManzil;
            desc=localeAbout+' ' + (langInfo.direction==='rtl'?convertToArabicNumber(100):100) + ' ' + localePages  ;
            break;
        default:
            title=localePage;
            desc=localeInclude+' ' + (langInfo.direction==='rtl'?convertToArabicNumber(604):604) + ' ' + localePages  ;
            break;
    }
    return(
        <Grid item xs={6} sm={3} md={2} lg={2} xl={2} className='slicingCard'>
            <Paper elevation={3} className='slicingPaper' onClick={()=>props.onClick(props.count)}>
                <Box display='flex' flexDirection='column'>
                    <Typography align='center' variant='h2' style={{fontWeight:'bold'}} className={(props.selected?'selectedSlicing ':'')+'slicingNumber'} >
                        {(langInfo.direction==='rtl'?convertToArabicNumber(props.count):props.count)}
                        <Checkbox
                            disabled
                            checked={props.selected}
                            checkedIcon={<CheckCircleIcon style={{color:'#008080'}}/>}
                            icon={<FiberManualRecordIcon style={{color:'rgba(0, 0, 0, 0.05)'}}/>}
                            style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                            }}
                        />
                    </Typography>
                    <Typography variant='body1' style={{direction:langInfo.direction,marginInlineStart:'10%',fontWeight:'bold'}}>{title}</Typography>
                    <Typography variant='caption' style={{direction:langInfo.direction,marginInlineStart:'10%'}}>{desc}</Typography>
                </Box>
            </Paper>
        </Grid>
    );
}
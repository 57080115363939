import React from 'react';
import ActionBar from './ActionBar';
import { bindActionCreators } from 'redux';
import * as quranActions from '../../actions/actionCreators';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import Slider from './Slider';
import KhatamHeader from './KhatamHeader';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import KhatamListItem from './KhatamListItem';
import axios from 'axios';
import env from '../../env.js';
import Divider from '@material-ui/core/Divider';
import sha256 from 'js-sha256';
import compareObjects from '../../utility/compareObjects';

class MainKhatam extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isDrawerOpen:false,
            khatamData:[],
            waitForServer:true,
            connectionError:false
        }

        //in lg and xl drawer is open by default
		if(window.matchMedia(`(min-width: 992px) and (max-width: 1200px)`).matches ||
                window.matchMedia(`(min-width: 1200px)`).matches){
            this.state.isDrawerOpen=false;
        }

        this.getKhatamData=this.getKhatamData.bind(this);
        this.generateOtherKhatamList=this.generateOtherKhatamList.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
		// Object.entries(this.props).forEach(([key, val]) =>
		// 	prevProps[key] !== val && console.log(`Prop '${key}' changed`)
		// );
		// if (this.state) {
		// 	Object.entries(this.state).forEach(([key, val]) =>
		// 	prevState[key] !== val && console.log(`State '${key}' changed ${JSON.stringify(prevState[key])} to ${JSON.stringify(val)}`)
		// 	);
		// }

	}

    shouldComponentUpdate(nextProps,nextState){
		
		return (
			this.props.store.userData.login.username!==nextProps.store.userData.login.username ||
			this.props.store.userData.langInfo.lang!==nextProps.store.userData.langInfo.lang ||
            !compareObjects(nextState,this.state)
		)
	}

    componentDidMount(){
        this.getKhatamData();
    }

    getKhatamData(){
        this.setState({waitForServer:true,connectionError:false});
        axios.post(env.baseURL+'/get_khatam_list',{username:this.props.store.userData.login.username})
        .then((result)=>{this.setState({khatamData:result.data})})
        .catch(()=>{this.setState({connectionError:true})})
        .finally(()=>{this.setState({waitForServer:false})});
    }

    selectByLang(rtl,ltr){
        return (this.props.store.userData.langInfo.direction === 'rtl') ? rtl : ltr;
    }

    generateMyKhatamList(){

        return this.state.khatamData.myKhatams ? 
            this.state.khatamData.myKhatams.map((khtm,indx)=>{
                if(khtm.username===sha256(this.props.store.userData.login.username))
                    return <KhatamListItem key={khtm.khatam_uuid} khatam={khtm}/>;
                else
                    return null;
            })
            :
            null;
    }

    generateOtherKhatamList(){
       
        return this.state.khatamData.otherKhatams?
            this.state.khatamData.otherKhatams.map((khtm,indx)=>{
                    return <KhatamListItem key={khtm.khatam_uuid} khatam={khtm}/>;
            })
            :
            null;
        
    }

    render(){
        let drawerState = {
			isDrawerOpen: this.state.isDrawerOpen,
			toggleDrawer: () => { this.setState({ isDrawerOpen: !this.state.isDrawerOpen }); }
        }
        let wrapperStyle = "";
        if (this.state.isDrawerOpen) {
			wrapperStyle = "khatamSection_od ".concat(this.selectByLang('wrapper_rtl','wrapper_ltr'));
		} else {
			wrapperStyle = "khatamSection_cd";
		}
        
        return (
            <React.Fragment>
                <Slider/>
                <ActionBar drawerState={drawerState}/>
                
                <section id={"khatamSection"} className={wrapperStyle}>
                    <KhatamHeader  drawerState={drawerState} refreshList={this.getKhatamData}/>

                    {(
                        this.state.waitForServer?
                        <CircularProgress id='mainKhatamProgress'/>
                        :
                        (
                        <Box display='flex' flexDirection='column' id='khatamList'>
                            <Grid
                                container
                                id='myKhatamList'
                                direction="row"
                                justify='flex-start'
                                alignItems='flex-start'
                                spacing={2}
                                alignContent='flex-start'
                            >
                                {this.generateMyKhatamList()}                                
                            </Grid>
                            {!(this.state.khatamData.otherKhatams) || !(this.state.khatamData.myKhatams)?
                            null:
                            <Divider style={{width:'90%',backgroundColor:'#008080',margin:'auto'}} />
                            }
                            <Grid
                                container
                                id='otherKhatamList'
                                direction="row"
                                justify='flex-start'
                                alignItems='flex-start'
                                spacing={2}
                                alignContent='flex-start'
                            >
                                {this.generateOtherKhatamList()}                 
                            </Grid>
                        </Box>
                        )
                    )}
                    
                </section>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state, ownProps) {
	return {
		'store':{
			'userData':{
                langInfo:state.userData.langInfo,
                login:{
                    username:state.userData.login.username
                }
            } 
		}
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(quranActions, dispatch)
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainKhatam));
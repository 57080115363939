import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as quranActions from '../../actions/actionCreators';
import convertToArabicNumber from '../../utility/convertToArabicNumber';
import ListSubheader from '@material-ui/core/ListSubheader';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import BrowseIcon from '@material-ui/icons/FindInPage';
import TranslateIcon from '@material-ui/icons/GTranslate';
import RecitationIcon from '@material-ui/icons/RecordVoiceOver';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import { withRouter } from 'react-router-dom';
import QuranMeta from '../../quranMeta/QuranMeta';
import { ListItemIcon, ListItem, ListItemText, Collapse, InputLabel, TextField } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

class SidebarItems extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            browseMenu: true,
            translationMenu: false,
            recitationMenu: false,
            availableTrans:this.props.store.transData.availableTrans,
            currentReciterObj:{},
            currentReciteTypeObj:{},
            currentReciteQualityObj:{}
        };
        
        this.changeType=this.changeType.bind(this);
        this.changeSura             = this.changeSura.bind(this);
        this.changeAya              = this.changeAya.bind(this);
        this.changePage             = this.changePage.bind(this);
        this.changeJuz              = this.changeJuz.bind(this);
        this.changeHizb             = this.changeHizb.bind(this);
        this.changeManzil           = this.changeManzil.bind(this);
        this.handleMenu             = this.handleMenu.bind(this);
        this.generateBrowseMenu     = this.generateBrowseMenu.bind(this);
        this.generateTranslateMenu  = this.generateTranslateMenu.bind(this);
        this.generateRecitationMenu = this.generateRecitationMenu.bind(this);
        this.addTranslate           = this.addTranslate.bind(this);
        this.deleteTranslateChip    = this.deleteTranslateChip.bind(this);
        this.touchTranslateChip     = this.touchTranslateChip.bind(this);
        this.changeRecitation       = this.changeRecitation.bind(this);
        this.changeQuality=this.changeQuality.bind(this);
        this.changePlayMode         = this.changePlayMode.bind(this);
        this.changeLoop             = this.changeLoop.bind(this);
        this.languageDir = this.languageDir.bind(this);

    }

    static getDerivedStateFromProps(nextProps,state){
        
        let Recitations = nextProps.store.reciteData.reciteList;
        let currentReciterObj={};
        let currentReciteTypeObj={};
        let currentReciteQualityObj={};
        let availableTrans=nextProps.store.transData.availableTrans;
        if(Recitations.length>0){//there is a fresh list from server

            let recitorId=nextProps.store.reciteData.selectedRecite.recitorId;
            //there is a reciterId in store and it is different from current one
            if(recitorId){
                //if there is a change in reciter then update currentReciterObj(this if-else reduce search in Recitation array if there is no change in recitor)
                if(recitorId!==state.currentReciterObj.id){
                    //get recite object by reciterId
                    currentReciterObj=Recitations.find(el=>parseInt(el.id)===recitorId);
                    if(!currentReciterObj){//reciterId in store is invalid then get types from first reciter
                        currentReciterObj=Recitations[0];
                    }
                }else{
                    currentReciterObj=state.currentReciterObj;
                }
            }else{//there is no reciterId in store then get types from first reciter
                currentReciterObj=Recitations[0];
            }

            let type_en=nextProps.store.reciteData.selectedRecite.reciteType;
            //there is a type in store AND reciterId does not change
            if(type_en){
                //reciter not changed but type changed OR reciter changed
                if(currentReciterObj.id===state.currentReciterObj.id &&
                            type_en!==state.currentReciteTypeObj.type_en){
                    //get type object by type_en
                    currentReciteTypeObj=currentReciterObj.recitations.find(el=>el.type_en===type_en);
                    if(!currentReciteTypeObj){//type in store is invalid then get qualities from first type
                        currentReciteTypeObj=currentReciterObj.recitations[0];
                    }
                //reciter has changed
                }else if(recitorId!==state.currentReciterObj.id){
                    currentReciteTypeObj=currentReciterObj.recitations[0];
                }else{
                    currentReciteTypeObj=state.currentReciteTypeObj;
                }
            }else{//there is no type in store then get qualities from first type
                currentReciteTypeObj=currentReciterObj.recitations[0];
            }

            let defaultQuality=currentReciteTypeObj.quality[0];
            currentReciteTypeObj.quality.forEach((element) => {
                if(parseInt(element.quality_bitrate)<parseInt(defaultQuality.quality_bitrate)){
                    defaultQuality=element;
                }
            });
            
            //there is a quality in store
            if(nextProps.store.reciteData.selectedRecite.reciteQuality && 
                nextProps.store.reciteData.selectedRecite.reciteQuality.quality_en){
                //nothing has changed but quality
                let quality_en=nextProps.store.reciteData.selectedRecite.reciteQuality.quality_en;
                if(currentReciterObj.id===state.currentReciterObj.id &&
                        currentReciteTypeObj.type_en===state.currentReciteTypeObj.type_en &&
                        quality_en!==state.currentReciteQualityObj.quality_en)
                {
                    currentReciteQualityObj=currentReciteTypeObj.quality.find(el=>el.quality_en===quality_en);
                    if(!currentReciteQualityObj){//auality in store is invalid then select first quality from current types of current reciter
                        currentReciteQualityObj=defaultQuality;
                    }
                }else if(currentReciterObj.id!==state.currentReciterObj.id ||
                        currentReciteTypeObj.type_en!==state.currentReciteTypeObj.type_en)
                {
                    currentReciteQualityObj=defaultQuality;
                }else{
                    currentReciteQualityObj=state.currentReciteQualityObj;
                }             
            }else{//there is a NO quality in store
                currentReciteQualityObj=defaultQuality;
            }

        }
        return {
            currentReciterObj, currentReciteTypeObj, currentReciteQualityObj,availableTrans
        }
    }

    //Change
    goTo(lang, sura, aya) {
        this.props.history.push("/" + lang + "/" + sura + ":" + aya);
        this.props.actions.changeSura(parseInt(sura));
        this.props.actions.changeAya(parseInt(aya));
    } 

    changeSura(event,value) {
        this.goTo(this.props.store.userData.langInfo.lang, value.number, 1);
        //this.props.drawerState.setDrawer(false);
    }

    changeAya(event,value) {
        this.goTo(this.props.store.userData.langInfo.lang, this.props.store.userData.sura, value);
        //this.props.drawerState.setDrawer(false);
    }

    changePage(event,value) {
        let PageFirstSA = QuranMeta.pageToSA(value);
        this.goTo(this.props.store.userData.langInfo.lang, PageFirstSA.sura, PageFirstSA.aya);
        
        //this.props.drawerState.setDrawer(false);
    }

    changeJuz(event,value) {
        let PageFirstSA = undefined;
        try{
            PageFirstSA=QuranMeta.getJuzDetails(value);
        }catch(er){
            PageFirstSA=QuranMeta.getJuzDetails(1);
        }
        this.goTo(this.props.store.userData.langInfo.lang, PageFirstSA.sura, PageFirstSA.aya);
        //this.props.drawerState.setDrawer(false);
    }

    changeHizb(event,value) {
        let PageFirstSA = QuranMeta.getHizbDetails(1);
        try{
            PageFirstSA=QuranMeta.getHizbDetails(value);
        }catch(er){};
        this.goTo(this.props.store.userData.langInfo.lang, PageFirstSA.sura, PageFirstSA.aya);
        //this.props.drawerState.setDrawer(false);
    }

    changeManzil(event,value) {
        let PageFirstSA = QuranMeta.getManzilDetails(value);
        this.goTo(this.props.store.userData.langInfo.lang, PageFirstSA.sura, PageFirstSA.aya);
        //this.props.drawerState.setDrawer(false);
    }

    languageDir(rtl,ltr){
        return (this.props.store.userData.langInfo.lang !== undefined && this.props.store.userData.langInfo.direction === 'rtl') ?
            rtl : ltr;
    }

    handleMenu(menuId) {
        
        switch(menuId){
            case 1: 
                this.setState({
                    browseMenu: true,
                    translationMenu: false,
                    recitationMenu: false
                });
                break;
            case 2: 
                this.setState({
                    browseMenu: false,
                    translationMenu: true,
                    recitationMenu: false
                });
                break;
            case 3: 
                this.setState({
                    browseMenu: false,
                    translationMenu: false,
                    recitationMenu: true
                });
                break;
            default:
                this.setState({
                    browseMenu: true,
                    translationMenu: false,
                    recitationMenu: false
                });
        }
    }

    
    generateBrowseMenu() {
        let rtlClass=this.languageDir("drawerItem_rtl","");

        let suras = [];
        for (let i = 1; i < 115; i++ ) {
            let suraData = QuranMeta.getSuraDetails(i);
            switch(this.props.store.userData.langInfo.lang) {
                case 'en' : suras.push({number:i , name:suraData.en_name});break;
                case 'fa' : suras.push({number:i , name: suraData.name});break;
                case 'ur' : suras.push({number:i , name: suraData.name});break;
                default: suras.push({number:i , name:suraData.en_name});
            }
        }

        let ayas = [];
        for (let i = 1; i <= QuranMeta.getSuraDetails(this.props.store.userData.sura).ayas; i++) {
            ayas.push(i);
        };

        let pages = [];
        for (let i = 1; i < 605; i++ ) {
            pages.push(i);
        }

        let juzs = [];
        for (let i = 1; i <= 30; i++ ) {
            juzs.push(i);
        }

        let hizbs = [];
        for (let i = 1; i <= 120; i++ ) {
            hizbs.push(i);
        }
        
        let manzils = [];
        for (let i = 1; i <= 7; i++ ) {
            manzils.push(i);
        }

        //prepare data for selectFields
        let sura = this.props.store.userData.sura;
        let aya = this.props.store.userData.aya;
        let page = 1;
        let juz = 1;
        let hizb=1;
        let manzil=1;
        try{
            page=QuranMeta.SAToPage(sura, aya);
            juz=QuranMeta.SAToJuz(sura, aya);
            hizb=QuranMeta.SAToHizb(sura,aya);
            manzil = QuranMeta.SAToManzil(sura, aya);
        }catch(err){
            console.error(err)
        }

        return (
            <Collapse in={this.state.browseMenu} timeout="auto" unmountOnExit>
                    <Autocomplete
                        options={suras}
                        getOptionLabel={(option) => this.languageDir(convertToArabicNumber(option.number),option.number) + '.' + option.name}
                        renderOption={(option)=>{
                            return <div>
                                        {this.languageDir(convertToArabicNumber(option.number),option.number) + '.' + option.name}
                                    </div>
                            }
                        }
                        // ListboxProps={{direction:'rtl'}}
                        disableClearable
                        classes={{option:this.languageDir('listItem_rtl','listItem_ltr')}}
                        onChange={this.changeSura}
                        className={"drawerItem ".concat(rtlClass)}
                        value={suras[parseInt(this.props.store.userData.sura)-1]}
                        getOptionSelected={(o,v)=>{return o.number===v.number;}}
                        renderInput={(params) => <TextField {...params} className={'autocompelete-textfield'} variant="standard" />}
                    />

                    <Autocomplete
                        options={ayas}
                        getOptionLabel={(option) => this.props.store.userData.locale.aya + ' ' + this.languageDir(convertToArabicNumber(option),option)}
                        renderOption={(option)=>{
                            return <div >{this.props.store.userData.locale.aya + ' ' + convertToArabicNumber(option)} </div>
                            }
                        }
                        disableClearable
                        classes={{option:this.languageDir('listItem_rtl','listItem_ltr')}}
                        onChange={this.changeAya}
                        className={"drawerItem ".concat(rtlClass)}
                        value={parseInt(this.props.store.userData.aya)}
                        getOptionSelected={(o,v)=>{return o===v;}}
                        renderInput={(params) => <TextField {...params} className={'autocompelete-textfield'} variant="standard" />}
                    />

                    <Autocomplete
                        options={pages}
                        getOptionLabel={(option) => this.props.store.userData.locale.page + ' ' + this.languageDir(convertToArabicNumber(option),option)}
                        renderOption={(option)=>{
                            return <div>{this.props.store.userData.locale.page + ' ' + this.languageDir(convertToArabicNumber(option),option)} </div>
                            }
                        }
                        disableClearable
                        classes={{option:this.languageDir('listItem_rtl','listItem_ltr')}}
                        onChange={this.changePage}
                        className={"drawerItem ".concat(rtlClass)}
                        value={page}
                        getOptionSelected={(o,v)=>{return o===v;}}
                        renderInput={(params) => <TextField {...params} className={'autocompelete-textfield'} variant="standard" />}
                    />

                    <Autocomplete
                        options={juzs}
                        getOptionLabel={(option) => this.props.store.userData.locale.juz + ' ' + this.languageDir(convertToArabicNumber(option),option)}
                        renderOption={(option)=>{
                            return <div >{this.props.store.userData.locale.juz + ' ' + this.languageDir(convertToArabicNumber(option),option)} </div>
                            }
                        }
                        disableClearable
                        classes={{option:this.languageDir('listItem_rtl','listItem_ltr')}}
                        onChange={this.changeJuz}
                        className={"drawerItem ".concat(rtlClass)}
                        value={juz}
                        getOptionSelected={(o,v)=>{return o===v;}}
                        renderInput={(params) => <TextField {...params} className={'autocompelete-textfield'} variant="standard" />}
                    />

                    <Autocomplete
                        options={hizbs}
                        getOptionLabel={(option) => this.props.store.userData.locale.hizb + ' ' + this.languageDir(convertToArabicNumber(option),option)}
                        renderOption={(option)=>{
                            return <div >{this.props.store.userData.locale.hizb + ' ' + this.languageDir(convertToArabicNumber(option),option)} </div>
                            }
                        }
                        disableClearable
                        classes={{option:this.languageDir('listItem_rtl','listItem_ltr')}}
                        onChange={this.changeHizb}
                        className={"drawerItem ".concat(rtlClass)}
                        value={hizb}
                        getOptionSelected={(o,v)=>{return o===v;}}
                        renderInput={(params) => <TextField {...params} className={'autocompelete-textfield'} variant="standard" />}
                    />

                    <Autocomplete
                        options={manzils}
                        getOptionLabel={(option) => this.props.store.userData.locale.manzil + ' ' + this.languageDir(convertToArabicNumber(option),option)}
                        renderOption={(option)=>{
                            return <div >{this.props.store.userData.locale.manzil + ' ' + this.languageDir(convertToArabicNumber(option),option)} </div>
                            }
                        }
                        disableClearable
                        classes={{option:this.languageDir('listItem_rtl','listItem_ltr')}}
                        onChange={this.changeManzil}
                        className={"drawerItem ".concat(rtlClass)}
                        value={manzil}
                        getOptionSelected={(o,v)=>{return o===v;}}
                        renderInput={(params) => <TextField {...params} className={'autocompelete-textfield'} variant="standard" />}
                    />

            </Collapse>
        );
    }

    addTranslate(event,value) {
        let transArr = this.props.store.transData.selectedTrans.slice(0);
        let flag = true;

        //if translate has been selected previousely set flag to false
        transArr.forEach(function(item){
            if (item.id === value.id) {
                flag = false;
            };
        });

        if (flag) {
            let obj=this.state.availableTrans.find((el,index)=>el.id===value.id);
            if(obj){//if selected translation exist in available translations
                this.props.actions.setSelectedTranslation(obj);
                this.props.actions.loadTranslateText(obj);
            }
        };        
    }

    deleteTranslateChip(trans){
        var transArr = this.props.store.transData.selectedTrans.slice(0);
        if (transArr.length <= 1) {
            return;
        };

        this.props.actions.deleteTranslation(trans);
        this.props.actions.deleteTranslateText(trans.id);
    }

    touchTranslateChip(){

    }

  

    generateTranslateMenu() {
        let rtlClass=this.languageDir("drawerItem_rtl","");

        let translates = [];
        // translates.push(<MenuItem className='listItem_rtl'  value="0" key="-1">{this.props.store.userData.locale.addTranslation}</MenuItem>);
        // this.state.availableTrans.forEach(function(translate, index){
        //     translates.push(<MenuItem className='listItem_rtl' value={translate} key={index} >
        //         <ListItemIcon><AddIcon/></ListItemIcon>
        //         {`${translate.language_ln}: ${translate.name_ln}`}
        //     </MenuItem>  
        // )});
        translates.push({id:0,title:this.props.store.userData.locale.addTranslation});
        this.state.availableTrans.forEach(function(translate, index){
            translates.push({id:translate.id,title:`${translate.language_ln}: ${translate.name_ln}`})});

        return (
            <Collapse in={this.state.translationMenu} timeout="auto" unmountOnExit>
            
                <Autocomplete
                    id="autocompelete-sidebar"
                    options={translates}
                    getOptionLabel={(option) => option.title}
                    renderOption={(option)=>{
                        return <div >{option.title} </div>
                        }
                    }
                    disableClearable
                    classes={{option:this.languageDir('listItem_rtl','listItem_ltr')}}
                    onChange={this.addTranslate}
                    className={"drawerItem ".concat(rtlClass)}
                    value={{id:0,title:this.props.store.userData.locale.addTranslation}}
                    getOptionSelected={(o,v)=>{return o.id===v.id;}}
                    renderInput={(params) => <TextField {...params} className={'autocompelete-textfield'} variant="standard" />}
                />

‌‌
            </Collapse>
        );
    }

    changeRecitation(event,value) {

        let currentRecite=this.props.store.reciteData.reciteList.find(el=>parseInt(el.id)===value.id);

        let defaultQuality=currentRecite.recitations[0].quality[0];
        currentRecite.recitations[0].quality.forEach((element) => {
            if(parseInt(element.quality_bitrate)<parseInt(defaultQuality.quality_bitrate)){
                defaultQuality=element;
            }
        });

        this.props.actions.changeRecitation({
            id:value.id,
            type:currentRecite.recitations[0].type_en,
            quality:defaultQuality});

    }

    changeType(event,value){

        let currentType=this.state.currentReciterObj.recitations.find(el=>el.type_en===value);
        let defaultQuality=currentType.quality[0];
        currentType.quality.forEach((element) => {
            if(parseInt(element.quality_bitrate)<parseInt(defaultQuality.quality_bitrate)){
                defaultQuality=element;
            }
        });
        this.props.actions.changeRecitation({
            id:this.state.currentReciterObj.id,
            type:currentType.type_en,
            quality:defaultQuality});
    }

    changeQuality(event,value){
        let currentQuality = this.state.currentReciteTypeObj.quality.find(el=>value.quality_en===el.quality_en);
        this.props.actions.changeRecitation({id:this.state.currentReciterObj.id,
                                             type:this.state.currentReciteTypeObj.type_en,
                                             quality:currentQuality});
    }

    changePlayMode(event){
        let value=event.target.value;
        let playerCnf = {...this.props.store.reciteData.playerCnf,playMode:parseInt(value)};
        this.props.actions.changePlayerCnf(playerCnf);
    }

    changeLoop(event){
        let value=event.target.value;
        let playerCnf = {...this.props.store.reciteData.playerCnf,loop:parseInt(value)};
        this.props.actions.changePlayerCnf(playerCnf);
    }

    generateRecitationMenu() {

        let rtlSelectItem=this.languageDir("drawerItem_rtl","");
        let rtlSelectLabel=this.languageDir("drawerItemLabel_rtl","");

        let recites=this.props.store.reciteData.reciteList.map((item,index)=>{
            return {id:item.id , name:this.languageDir(`${item.nickname_ln}`,`${item.nickname_en}`)};
        });
        let types=[];
        let qualities=[];
       
        //if there is currentReciterObj,currentReciteQualityObj and currentReciteTypeObj
        //fill types and qualities otherwise leave them empty 
        if(Object.keys(this.state.currentReciterObj).length>0 &&
                Object.keys(this.state.currentReciteQualityObj).length>0 &&
                Object.keys(this.state.currentReciteTypeObj).length>0
        ){
            this.state.currentReciterObj.recitations.forEach((item,index)=>{
                types.push(item.type_en);
            });
            qualities=this.state.currentReciteTypeObj.quality;
        }

        let currentRecitor={id:this.state.currentReciterObj.id,name:this.languageDir(`${this.state.currentReciterObj.nickname_ln}`,`${this.state.currentReciterObj.nickname_en}`)};
        let type_en=this.state.currentReciteTypeObj.type_en;
        let quality=this.state.currentReciteQualityObj;
        return (
            <Collapse in={this.state.recitationMenu} timeout="auto" unmountOnExit>
                <InputLabel className={'drawerItemLabel '.concat(rtlSelectLabel)} id="reciter">{this.props.store.userData.locale.reciter}</InputLabel>
                <Autocomplete
                    id="autocompelete-sidebar"
                    options={recites}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option)=>{
                        return <div >{option.name} </div>
                        }
                    }
                    disableClearable
                    classes={{option:this.languageDir('listItem_rtl','listItem_ltr')}}
                    onChange={this.changeRecitation}
                    className={"drawerItem ".concat(rtlSelectItem)}
                    value={currentRecitor}
                    getOptionSelected={(o,v)=>{return o.id===v.id;}}
                    renderInput={(params) => <TextField {...params} className={'autocompelete-textfield'} variant="standard" />}
                />

                <InputLabel className={'drawerItemLabel '.concat(rtlSelectLabel)} id="type">{this.props.store.userData.locale.type}</InputLabel>
                <Autocomplete
                    id="autocompelete-sidebar"
                    options={types}
                    getOptionLabel={(option) => option}
                    renderOption={(option)=>{
                        return <div >{option} </div>
                        }
                    }
                    disableClearable
                    classes={{option:this.languageDir('listItem_rtl','listItem_ltr')}}
                    onChange={this.changeType}
                    className={"drawerItem ".concat(rtlSelectItem)}
                    value={type_en}
                    getOptionSelected={(o,v)=>{return o===v;}}
                    renderInput={(params) => <TextField {...params} className={'autocompelete-textfield'} variant="standard" />}
                />

                {/* <InputLabel className={'drawerItemLabel '.concat(rtlSelectLabel)} id="language">Language</InputLabel>
                <Select
                    labelId="language"
                    value={this.props.store.userData.recitor}
                    autoWidth={true}
                    onChange={this.changeRecitation}
                    className={"drawerItem ".concat(rtlSelectItem)} 
                >
                    {this.state.Recitations}
                </Select>

                <InputLabel className={'drawerItemLabel '.concat(rtlSelectLabel)} id="style">Style</InputLabel>
                <Select
                    labelId="style"
                    value={this.props.store.userData.recitor}
                    autoWidth={true}
                    onChange={this.changeRecitation}
                    className={"drawerItem ".concat(rtlSelectItem)} 
                >
                    {this.state.Recitations}
                </Select>

                <InputLabel className={'drawerItemLabel '.concat(rtlSelectLabel)} id="riwayt">Riwayt</InputLabel>
                <Select
                    labelId="riwayt"
                    value={this.props.store.userData.recitor}
                    autoWidth={true}
                    onChange={this.changeRecitation}
                    className={"drawerItem ".concat(rtlSelectItem)} 
                >
                    {this.state.Recitations}
                </Select>
*/}
                <InputLabel className={'drawerItemLabel '.concat(rtlSelectLabel)} id="quality">{this.props.store.userData.locale.quality}</InputLabel>
                <Autocomplete
                    id="autocompelete-sidebar"
                    options={qualities}
                    getOptionLabel={(option) => option.quality_en+ "("+ option.quality_bitrate+")"}
                    renderOption={(option)=>{
                        return <div >{option.quality_en+ "("+ option.quality_bitrate+")"} </div>
                        }
                    }
                    disableClearable
                    classes={{option:this.languageDir('listItem_rtl','listItem_ltr')}}
                    onChange={this.changeQuality}
                    className={"drawerItem ".concat(rtlSelectItem)}
                    value={quality}
                    getOptionSelected={(o,v)=>{return o===v;}}
                    renderInput={(params) => <TextField {...params} className={'autocompelete-textfield'} variant="standard" />}
                />
                <InputLabel className={'drawerItemLabel '.concat(rtlSelectLabel)} id="mode">{this.props.store.userData.locale.playMode}</InputLabel>
                 <Select
                    value={this.props.store.reciteData.playerCnf.playMode}
                    labelId="mode"
                    autoWidth={true}
                    onChange={this.changePlayMode}
                    className={"drawerItem ".concat(rtlSelectItem)}
                >
                    <MenuItem className='listItem_rtl' value={1} label="Aya" >{this.props.store.userData.locale.aya}</MenuItem>
                    <MenuItem className='listItem_rtl' value={2} label="Page">{this.props.store.userData.locale.page}</MenuItem>
                    <MenuItem className='listItem_rtl' value={3} label="Sura">{this.props.store.userData.locale.sura}</MenuItem>
                    <MenuItem className='listItem_rtl' value={4} label="Juz" >{this.props.store.userData.locale.juz}</MenuItem>
                    <MenuItem className='listItem_rtl' value={5} label="Hizb">{this.props.store.userData.locale.hizb}</MenuItem>
                    <MenuItem className='listItem_rtl' value={6} label="Manzil">{this.props.store.userData.locale.manzil}</MenuItem>
                    <MenuItem className='listItem_rtl' value={7} label="Ruku" >{this.props.store.userData.locale.ruku}</MenuItem>
                    <MenuItem className='listItem_rtl' value={8} label="Continuous">{this.props.store.userData.locale.continious}</MenuItem>
                </Select>

                <InputLabel className={'drawerItemLabel '.concat(rtlSelectLabel)} id="loop">{this.props.store.userData.locale.loop}</InputLabel>
                <Select
                    labelId="loop"
                    value={this.props.store.reciteData.playerCnf.loop}
                    autoWidth={true}
                    onChange={this.changeLoop}
                    className={"drawerItem ".concat(rtlSelectItem)} 
                >
                    <MenuItem value={1} label="x1" >x1</MenuItem> 
                    <MenuItem value={2} label="x2" >x2</MenuItem>
                    <MenuItem value={3} label="x3" >x3</MenuItem>
                    <MenuItem value={4} label="x4" >x4</MenuItem>
                    <MenuItem value={5} label="x5" >x5</MenuItem>
                    <MenuItem value={6} label="x6" >x6</MenuItem>
                    <MenuItem value={7} label="x7" >x7</MenuItem>
                    <MenuItem value={8} label="x8" >x8</MenuItem>
                    <MenuItem value={9} label="x9" >x9</MenuItem>
                </Select> 

            </Collapse>
        );
    }

    render() {
        let listItemClass=this.languageDir("listItem_rtl","listItem_ltr");
        return (
                <List
                    component='ul'
                    aria-labelledby="drawerTitle"
                    subheader={
                        <ListSubheader 
                                component="div"
                                id="drawerTitle"
                                className={this.languageDir('drawerTitle_rtl','drawerTitle_ltr')}
                        >
                            {this.props.store.userData.locale.forghan}
                        </ListSubheader>
                    }
                >

                    <ListItem className={listItemClass} button onClick={()=>{this.handleMenu(1)}}>
                        <ListItemIcon className={this.props.store.userData.theme==='light'?'listItemIcon_light':'listItemIcon_dark'}>
                            <BrowseIcon />
                        </ListItemIcon>
                        <ListItemText disableTypography primary={this.props.store.userData.locale.browse}/>
                    </ListItem>
                    {this.generateBrowseMenu()}

                    <ListItem className={listItemClass} button onClick={()=>{this.handleMenu(2)}}>
                        <ListItemIcon className={this.props.store.userData.theme==='light'?'listItemIcon_light':'listItemIcon_dark'}>
                            <TranslateIcon />
                        </ListItemIcon>
                        <ListItemText disableTypography primary={this.props.store.userData.locale.translation}/>
                    </ListItem>
                    {this.generateTranslateMenu()}

                    <ListItem className={listItemClass} button onClick={()=>{this.handleMenu(3)}}>
                        <ListItemIcon className={this.props.store.userData.theme==='light'?'listItemIcon_light':'listItemIcon_dark'}>
                            <RecitationIcon/>
                        </ListItemIcon>
                        <ListItemText disableTypography primary={this.props.store.userData.locale.recitation}/>
                    </ListItem>
                    {this.generateRecitationMenu()}

                </List>
        );
    }
}

function mapStateToProps(state, ownProps){
    return {
        'store':{
            'userData': state.userData,
            transData:{
                'availableTrans':state.transData.availableTrans,
                'selectedTrans':state.transData.selectedTrans,
            },
            'reciteData':{
                playerCnf:state.reciteData.playerCnf,
                reciteList : state.reciteData.reciteList,
                selectedRecite : state.reciteData.selectedRecite,
            }
        }
    };
}

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(quranActions, dispatch)
    };
}

export default withTheme(withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarItems)));
import { 
        Box,Button, Checkbox, Dialog, DialogTitle, FormControlLabel, Grid, TextareaAutosize, TextField,
        Typography,Snackbar, CircularProgress
    } from "@material-ui/core";
import React, { useState } from 'react';
import SlicingCards from "./SlicingCards";
import convertToArabicNumber from '../../utility/convertToArabicNumber';
import ChipInput from 'material-ui-chip-input';
import axios from "axios";
import env from '../../env.js';
import { bindActionCreators } from 'redux';
import * as quranActions from '../../actions/actionCreators';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import compareObjects from "../../utility/compareObjects";

class NewKhatamDialog extends React.Component{

    constructor(props){
        super(props);

        this.state={
            isOpen:props.open,
            title:'',
            tags:[],
            slicing:30,
            isPublic:true,
            anonym:true,
            autoExtend:true,
            description:'',
            course:1,
            errors:{},
            snack:{open:false,msg:''},
            waitForServer:false
        }

        this.selectSlicingOnClick=this.selectSlicingOnClick.bind(this);
        this.handleCloseDialog=this.handleCloseDialog.bind(this);
        this.sendNewKhatam=this.sendNewKhatam.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
		// Object.entries(this.props).forEach(([key, val]) =>
		// 	prevProps[key] !== val && console.log(`Prop '${key}' changed`)
		// );
		// if (this.state) {
		// 	Object.entries(this.state).forEach(([key, val]) =>
		// 	prevState[key] !== val && console.log(`State '${key}' changed ${JSON.stringify(prevState[key])} to ${JSON.stringify(val)}`)
		// 	);
		// }

	}
    
    shouldComponentUpdate(nextProps,nextState){
		return(
			this.props.store.userData.langInfo.lang!==nextProps.store.userData.langInfo.lang ||
            !compareObjects(nextState,this.state)
		)
	}

    static getDerivedStateFromProps(nextProps,state) {
        return {
            isOpen:nextProps.open
        }
    }

    selectSlicingOnClick(e){
        this.setState({slicing:parseInt(e)});
    }

    handleCloseDialog(){
        this.props.onClose();
    }

    sendNewKhatam(){
        
        let tErr={};
        //new khatam data validation
        if(this.props.store.userData.login.username.length===0){
            tErr.username=true;
            this.setState({snack:{open:true,msg:'invalid user: logout then login'}});
        }
        if(this.state.title.length===0 || this.state.title.length>40){
            tErr.title=true;
        }
        if(![604,30,60,240,7].includes(this.state.slicing)){
            tErr.slicing=true;
        }
        if(this.state.course<1){
            tErr.course=true;
        }
        let hasError=false;
        for(const p in tErr){
            hasError=hasError || tErr[p];
        }
        this.setState({errors:tErr});
        if(hasError){
            return;
        }
        this.setState({waitForServer:true});
        let data={
            username:this.props.store.userData.login.username,
            title:this.state.title,
            tags:this.state.tags,
            isPublic:this.state.isPublic,
            slicing:this.state.slicing,
            description:this.state.description,
            anonym:this.state.anonym,
            autoExtend:this.state.autoExtend};
        
        axios.post(env.baseURL + '/create_new_khatam',data)
        .then((result)=>{console.log(result.data)
            this.setState({title:'',course:1,isPublic:true,anonym:true,autoExtend:false})
            this.props.onClose();
            this.setState({waitForServer:false});
            this.props.history.push('/'+this.props.store.userData.langInfo.lang+'/khatam/'+result.data.newKhatamId);
            
        })
        .catch((error)=>{
            this.setState({snack:{open:true,msg:(error.response?error.response.data:"Server Connection Error!")}});
        });
    }

    render(){
        let heplerTextStyle={
            position:'absolute',
            right:(this.props.store.userData.langInfo.direction==='rtl'?'auto':0),
            left:(this.props.store.userData.langInfo.direction==='ltr'?'auto':0),
            top:'50%',
            transform: 'translateY(-50%)',
            fontFamily:'vazir'
        }
        let labelStyle={
            position:'absolute',
            right:(this.props.store.userData.langInfo.direction==='ltr'?'auto':'5%'),
            left:(this.props.store.userData.langInfo.direction==='rtl'?'auto':'5%'),
            fontFamily:'vazir'
        }

        let helperText=(this.props.store.userData.langInfo.direction==='rtl'?
                            convertToArabicNumber(this.state.title.length):
                            this.state.title.length)+'/'+
                                (this.props.store.userData.langInfo.direction==='rtl'?convertToArabicNumber(40):40);
        let titleErrorStyle=this.state.errors.title?"1px solid red":'';
        let slicingErrorStyle=this.state.errors.slicing?"1px solid red":'';

        return(
        <Dialog open={this.state.isOpen} maxWidth='lg' BackdropProps={{style:{backgroundColor:'#00000000'}}}
                id='newKhatamBackdrop'
                onClose={this.handleCloseDialog}
                PaperProps={{id:'newKhatamDialog'}}
        >

            <DialogTitle id='newKhatamTitle' style={{direction:this.props.store.userData.langInfo.direction}} disableTypography>
                {this.props.store.userData.locale.newKhatam}
                </DialogTitle>

            <Box display='flex' flexDirection='column' style={{marginBottom:'5%'}}>
                <TextField 
                        inputProps={{maxLength: 40,style:{fontFamily:'vazir',border:titleErrorStyle}}}
                        variant='filled'
                        label={this.props.store.userData.locale.titleOfKhatam}
                        helperText={helperText}
                        style={{width:'90%',margin:'0 5%',direction:this.props.store.userData.langInfo.direction,}}
                        FormHelperTextProps={{style:heplerTextStyle}}
                        InputLabelProps={{style:labelStyle}}
                        onChange={(e)=>{this.setState({title:e.target.value})}}
                    />
                <Typography
                        style={{margin:'0 7%',direction:this.props.store.userData.langInfo.direction}}
                        variant='subtitle2'
                    >
                        {this.props.store.userData.locale.titleOfKhatamCaption}
                    </Typography>
            </Box>

            <Box display='flex' flexDirection='column' style={{margin: '1% 0% 5%'}}>
                <Typography variant='body1' style={{direction:this.props.store.userData.langInfo.direction,margin:'0 5%'}}>
                    {this.props.store.userData.locale.selectKhatamSections}
                    </Typography>
                    <Grid container direction='row' justify='space-around' alignItems='stretch' style={{border:slicingErrorStyle}}>
                        <SlicingCards count='604' selected={this.state.slicing===604} onClick={this.selectSlicingOnClick}/>
                        <SlicingCards count='30' selected={this.state.slicing===30} onClick={this.selectSlicingOnClick}/>
                        <SlicingCards count='60' selected={this.state.slicing===60} onClick={this.selectSlicingOnClick}/>
                        <SlicingCards count='240' selected={this.state.slicing===240} onClick={this.selectSlicingOnClick}/>
                        <SlicingCards count='7' selected={this.state.slicing===7} onClick={this.selectSlicingOnClick}/>
                    </Grid>
            </Box>

            <Box id='newKhDescTagBox' display='flex' style={{margin: '0% 0% 5%'}}>
                    <TextareaAutosize 
                        id='newKhDesc'
                        style={{direction:this.props.store.userData.langInfo.direction}} 
                        rowsMin={4} placeholder={this.props.store.userData.locale.description}
                        onBlur={(e)=>{this.setState({description:e.target.value})}}
                    />
                    <Box display='flex' flexDirection='column' style={{margin: '0% 2%'}}>
                        <Typography variant='body1' style={{direction:this.props.store.userData.langInfo.direction}}>
                            {this.props.store.userData.locale.tags+":"}
                        </Typography>
                        <ChipInput style={{direction:this.props.store.userData.langInfo.direction}} onChange={(chips)=>{this.setState({tags:chips})}}/>
                        <Typography variant='caption' style={{direction:this.props.store.userData.langInfo.direction}}>
                            {this.props.store.userData.locale.tagsCaption}
                        </Typography>
                    </Box>
            </Box>
            <Box display='flex' flexDirection='column' style={{margin: '0% 1% 5%',direction:this.props.store.userData.langInfo.direction}}>
                    <FormControlLabel
                        control={<Checkbox style={{color:'#008080'}}/>}
                        label={this.props.store.userData.locale.doNotShowToPublic}
                        onClick={(e)=>{this.setState({isPublic:!e.target.checked})}}
                    />
                    <FormControlLabel
                        control={<Checkbox style={{color:'#008080'}}/>}
                        label={this.props.store.userData.locale.onlySignedUsers}
                        onClick={(e)=>{this.setState({anonym:!e.target.checked})}}
                    />
                    <FormControlLabel
                            control={<Checkbox style={{color:'#008080'}}/>}
                            label={this.props.store.userData.locale.dontExtend}
                            onClick={(e)=>{this.setState({autoExtend:!e.target.checked})}}
                        />
                </Box>
            <Box display='flex' flexDirection='row-reverse'>
                    <Button className="button_dark" onClick={this.sendNewKhatam} disabled={this.state.waitForServer}>
                            {this.state.waitForServer?
                                <CircularProgress style={{color:'#7BC5BE',width:'100%',height:'100%',
                                    visibility:(this.state.waitForServer ?'visible':'hidden')}}
                                />
                                :
                                this.props.store.userData.locale.create
                            }
                    </Button>
                    <Button className="button_dark" onClick={this.handleCloseDialog}>
                        {this.props.store.userData.locale.cancel}
                    </Button>
            </Box>
            <Snackbar
                    open={this.state.snack.open}
                    message={this.state.snack.msg}
                    action="Ok"
                    autoHideDuration={3000}
                    onClose={()=>{this.setState({snack:{open:false,msg:''}})}}
                />
        </Dialog>
        );
    }
}



function mapStateToProps(state, ownProps) {
	return {
		'store':{
			'userData':{
                langInfo:state.userData.langInfo,
                login:{
                    username:state.userData.login.username
                },
                locale:{
                    newKhatam:state.userData.locale.newKhatam,
                    titleOfKhatam:state.userData.locale.titleOfKhatam,
                    titleOfKhatamCaption:state.userData.locale.titleOfKhatamCaption,
                    selectKhatamSections:state.userData.locale.selectKhatamSections,
                    description:state.userData.locale.description,
                    tags:state.userData.locale.tags,
                    tagsCaption:state.userData.locale.tagsCaption,
                    create:state.userData.locale.create,
                    cancel:state.userData.locale.cancel,
                    doNotShowToPublic:state.userData.locale.doNotShowToPublic,
                    onlySignedUsers:state.userData.locale.onlySignedUsers,
                    dontExtend:state.userData.locale.dontExtend
                }
            } 
		}
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(quranActions, dispatch)
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewKhatamDialog));
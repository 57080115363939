import * as types from './actions';
import axios from 'axios';
import env from '../env.js';
import Dexie from 'dexie';

export function loadQuranTextSuccess(quranText,edition) {
	
 	return { type: types.LOAD_QURANTEXT_SUCCESS, payload:{quranText,edition}};
}

export function loadTranslateTextSuccess(translatetext,transId) {
	
 	return { type: types.LOAD_TRANSLATETEXT_SUCCESS, payload:{translatetext,transId}};
}

export function deleteTranslateText(transId) {
 	return { type: types.DELETE_TRANSLATETEXT, payload:transId};
}

export function changeTranslateData(translate) {
 	return { type: types.CHANGE_TRANSLATE_DATA, payload:translate};
}

export function setSelectedTranslation(trans){
	return {type: types.SET_SELECTED_TRANS , payload:trans};
}

export function setTheme(theme){
	return {type : types.SET_THEME , payload: theme}
}

export function changeLanguage(language) {
 	return { type: types.CHANGE_LANGUAGE, payload:language};
}

export function changePlayerCnf(playerCnf) {
 	return { type: types.CHANGE_PLAYERCNF, payload:playerCnf};
}

export function changeRecitation(recitorId) {
 	return { type: types.CHANGE_RECITATION, payload:recitorId};
}

export function setShowTranslation(showTranslate) {
	return { type: types.SET_SHOW_TRANSLATION, payload:showTranslate};
}

export function changeSura(sura) {
	return { type: types.CHANGE_SURA,payload:sura};
}

export function changeAya(aya) {
	return { type: types.CHANGE_AYA, payload:aya};
}

export function changeQuranDisplay(displayOptions) {
	return { type: types.CHANGE_QURAN_DISPLAY_OPTIONS, payload:displayOptions};
}

export function getTranslationListDone(transList){
	return {type:types.GET_TRANSLATION_LIST_DONE,payload:transList};
}

export function getReciteListDone(reciteList){
	return {type:types.GET_RECITE_LIST_DONE,payload:reciteList};
}

export function setCountryList(countryList){
	return {type:types.SET_COUNTRY_LIST,payload:countryList};
}

export function setTajwidColors(colors){
	return {type:types.SET_TAJWID_COLORS,payload:colors};
}

export function setFirstTime(isFirstTime){
	return {type:types.SET_FIRST_TIME,payload:isFirstTime};
}

export function setCountry(country){
	return {type:types.SET_COUNTRY,payload:country};
}

export function setLogin(loginObj){
	return {type:types.SET_LOGIN,payload:loginObj};
}

export function setSuggestLogin(suggestLogin){
	return {type:types.SET_SUGGEST_LOGIN,payload:suggestLogin};
}

//
export function loadQuranText(edition) {
	return function(dispatch) {
		
		//check if translate exist in index db then load it from db
		// Create your instance
		let db = new Dexie("forghan.com");
		
		// Define your schema
		db.version(1).stores({
			transText : "++id,tid,vid",
			quranText: "++id,edition,vid"
		});

		// Open the database
		db.open().catch(function (e) {
			console.error("Open failed: " + e);
		});

		//check if quran text edition has downloaded before
		//first query from db 
		//then check if edition exist in db
		let text=[];
		db.transaction("r",db.quranText,function(){
			db.quranText.where('edition').equals(edition).sortBy('vid').then((result)=>{
				if(result.length===6236){
					result.forEach((tt)=>{
						text.push(tt.text);
					});
					dispatch(loadQuranTextSuccess(text,edition));
				}else{
					let fullEdition='';
					switch(edition){
						case 'si':
							fullEdition='simple';
							break;
						case 'se':
							fullEdition='simple-enhanced';
							break;
						case 'sm':
							fullEdition='simple-minimal';
							break;
						case 'sc':
							fullEdition='simple-clean';
							break;
						case 'ut':
							fullEdition='uthmani';
							break;
						case 'um':
							fullEdition='uthmani-minimal';
							break;
						case 'tj':
							fullEdition='tajwid';
							break;
						default:
							fullEdition='tajwid';
							break;
					}
					axios.get(env.baseURL +'/quran_text/' + fullEdition ,{crossdomain: true})
					.then(quran => {
						dispatch(loadQuranTextSuccess(quran.data,edition));
					})
					.catch(error => {
						throw(error);
					});
				}
			});
		}).catch(function(error){
			console.error("actionCreator->loadQuranText():\nload quran text from indexdb :\n" + error);
		});
	};
}

//params :translate is object of translate that we want to load
//		 :selectedTrans is array of selected translation, the reason that we pass this array is to remove translation from it in case of load failure 
export function loadTranslateText(translate,selectedTrans) {
	
	return function(dispatch) {
		
		//check if translate exist in index db then load it from db
		// Create your instance
		let db = new Dexie("forghan.com");

		// Define your schema
		db.version(1).stores({
			transText : "++id,tid,vid",
			quranText: "++id,edition,vid"
		});

		// Open the database
		db.open().catch(function (e) {
			console.error("Open failed: " + e);
			dispatch(setSelectedTranslation(selectedTrans.filter((v,i)=>v.id!==translate.id)));
		});
		//check if translation has downloaded before
		//first query from db 
		//then check if translation exist in db
		//then check the result of first then clause and if there is no translation in db download it
		let text=[];
		db.transaction("rw",db.transText,function(){
				db.transText.where('tid').equals(translate.id).sortBy('vid')
				.then((result)=>{
					if(result.length===6236){
						result.forEach((tt)=>{
							text.push(tt.text);
						});
						dispatch(loadTranslateTextSuccess(text,translate.id));
					}else if(result.length!==0){//result is not 6236 that means this tranlation is not in db and it should be 0 not anything else
						dispatch(setSelectedTranslation(selectedTrans.filter((v,i)=>v.id!==translate.id)));
						db.transText.where('tid').equals(translate.id).delete();
					}
				}).then(()=>{
					//if text from pervious then clause is a complete translation do nothing(return)
					if(text.length===6236)
						return;
					axios.get(translate.url,{crossdomain: true}).then(trans => {
						let temp=trans.data.split(/\r?\n/);
						if(temp.length===6236){
							dispatch(loadTranslateTextSuccess(temp,translate.id));
						}else{
							dispatch(setSelectedTranslation(selectedTrans.filter((v,i)=>v.id!==translate.id)));
						}
					}).catch(error => {
						throw(error);
					});
				})
				.catch((err)=>{
					console.log("eee "+err);
					dispatch(setSelectedTranslation(selectedTrans.filter((v,i)=>v.id!==translate.id)));
				});
		}).catch(function(error){
			console.error("actionCreator->loadTranslateText():\nload translation from indexdb:\n" + error);
			dispatch(setSelectedTranslation(selectedTrans.filter((v,i)=>v.id!==translate.id)));
		});
	};
}

export function getTranslationList(userTrans){
	
	return function(dispatch){
		return axios.get(env.baseURL+'/translation_list',{crossdomain:true})
			.then(list=>{
				let listData=list.data;
				dispatch(getTranslationListDone(listData));
			})
			.catch(error => {
				throw(error);
			});
	}
}

export function getRecitationList(){
	return function(dispatch){
		return axios.get(env.baseURL+'/recitation_list',{crossdomain:true})
			.then(list=>{
				dispatch(getReciteListDone(list.data));
			}).catch(error=>{
				throw(error);
			});
	}
}

export function getCountryList(){
	return function(dispatch){
		return axios.get(env.baseURL+'/country_list',{crossdomain:true})
		.then(list=>{
			dispatch(setCountryList(list.data));
		})
		.catch(err=>{throw(err);});
	}
}

export function getLangInfoByLocale(){
	return function(dispatch){
		let langInfo={'lang' :'en','direction':'ltr','font':'Roboto'};
		let country='';
		let firstTime=true;
		//return fetch('https://ipapi.co/json/',{mode:'cors'})
		//https://extreme-ip-lookup.com/json/
		return axios.get('https://extreme-ip-lookup.com/json/',{crossdomain:true})
			.then( res => res.data)
			.then(response => {
				country=response.country;
				firstTime=false;
				let cnt= response.countryCode;
				//farsi
				if (cnt==='IR' || cnt==='AF'){
					langInfo= {'lang':'fa','direction': 'rtl','font': 'vazir',displayName:'فارسی'};
				}else if(cnt==='PK' || cnt==='IN'){
					langInfo={'lang':'ur','direction': 'rtl','font': 'vazir',displayName:'اردو'}
				}else{
					langInfo={'lang' :'en','direction':'ltr','font':'Roboto',displayName:'English'}
				}
			}).finally(()=>{
				dispatch(changeLanguage(langInfo));
				dispatch(setCountry(country));
				dispatch(setFirstTime(firstTime));
			});    
	}
}

export function getVerification(data,stopWaitingFunc){
	return function(dispatch){
		
		return axios.post(env.baseURL + '/sms_verification',data,{"content-type":"application/json"})
			.then(
				(res)=>{
					if(res.data===null){//no response from kavenegar
						dispatch(setLogin({status:'off',verifTime:0}));
						stopWaitingFunc('kavenegar error',true);
					}
					stopWaitingFunc("",false);
					dispatch(setLogin({
								status:'waitForUser',
								verifTime:Math.round(new Date().getTime()/1000),
								username:res.data.username,
								firstname:res.data.firstname,
								lastname:res.data.lastname,
								country_id:res.data.country_id,
							}));
					
				}
			)
			.catch(
				(err)=>{
					dispatch(setLogin({status:'off',verifTime:0}));
					stopWaitingFunc(err.response?err.response.data:"Server Connection Error!",true);
				}
			);
	}
}

export function checkVerification(data,stopWaitingFunc){
	return function(dispatch){
		return axios.post(env.baseURL+'/check_verification',data)
		.then((res)=>{
			if(Object.keys(res.data).length===4 && res.data.username && res.data.firstname!==undefined 
							&& res.data.lastname!==undefined && res.data.country_id){
				stopWaitingFunc("",false);
				dispatch(setLogin({
							status:'on',
							verifTime:0,
							username:res.data.username,
							firstname:res.data.firstname,
							lastname:res.data.lastname,
							country_id:res.data.country_id,
						}));
			}else{
				stopWaitingFunc("invalid data from server! it is a server problem, call admin!",true);
			}
		})
		.catch(
			(err)=>{
				stopWaitingFunc(err.response?err.response.data:"Server Connection Error!",true);
			}
		);
	}

}

import * as types from '../actions/actions';

export default function translateTextReducer(state = [], action) {
	
	switch(action.type){

		case types.LOAD_TRANSLATETEXT_SUCCESS:{
			let newTranslateText = state.transText.slice(0);
			newTranslateText.push({id:action.payload.transId,text:action.payload.translatetext});
			return {...state,transText:newTranslateText};
		};

		case types.DELETE_TRANSLATETEXT:{
			let newTranslateText = state.transText.slice(0);
			let index=-1;
			newTranslateText.forEach((t,i)=>{if(t.id===action.payload){index=i;}})
			if(index>=0){
				newTranslateText.splice(index, 1);
			}
			return {...state,transText:newTranslateText};
		};
		case types.GET_TRANSLATION_LIST_DONE:{
			//availableTrans is a complete list of translation recieved from server
			return {...state, availableTrans:action.payload};
		}
		case types.CHANGE_TRANSLATE_DATA:{
			let obj =  Object.assign({}, state, {
				  selectedTrans: action.payload
			});
			return obj
		}
		case types.SET_SELECTED_TRANS:
			// let selectedTrans=state.selectedTrans;
			// let availableTrans=state.availableTrans;
			// selectedTrans.push(action.payload);
			//availableTrans=availableTrans.filter((value)=>{return value.id !== action.payload.id});
			// return {...state , selectedTrans:selectedTrans /*, availableTrans:availableTrans*/};
			return {...state,selectedTrans:action.payload};
		case types.DELETE_TRANSLATION:
			let index=-1;
			let selectedTrans1=state.selectedTrans;
			selectedTrans1.forEach((el,ind)=>{if(el.id===action.payload.id){index=ind;}});
			if(index>=0){
				selectedTrans1.splice(index,1);
			}
			let availableTrans1=state.availableTrans;
			availableTrans1.push(action.payload);
			return {...state , selectedTrans:selectedTrans1 , availableTrans:availableTrans1};
		case types.SET_SHOW_TRANSLATION:
			return {...state,showTranslate:action.payload}
		default:
      		return state;
	}
}
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as quranActions from '../../actions/actionCreators';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { Box, CircularProgress, Container, Snackbar } from '@material-ui/core';
import {AccountCircle, Facebook, Instagram,Twitter} from '@material-ui/icons';
import {Search} from '@trejgun/material-ui-icons-google';
import compareObjects from '../../utility/compareObjects';

var timer;

class Verification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isDrawerOpen: true,
            txtfldFocus: 0,//index of textfield under focus
            txtfldRef:[React.createRef(),React.createRef(),React.createRef(),React.createRef()],
            userName: '',
            remainTime: 120-((Math.round(new Date().getTime()/1000))-(props.store.userData.login.verifTime)),
            code:[null,null,null,null],
            openSnack: false,
            messageSnack: '',
            waitForServer:false
        };

        timer=setInterval(() => {
            this.setState({remainTime:this.state.remainTime-1},()=>{
                if(this.state.remainTime<1 ||
                        ((Math.round(new Date().getTime()/1000))-(props.store.userData.login.verifTime))<0 ||
                        ((Math.round(new Date().getTime()/1000))-(props.store.userData.login.verifTime))>120){

                    this.props.actions.setLogin({status:'off',forcedOpen:true});
                }
            });
        }, 1000);

        this.txtfldOnChange = this.txtfldOnChange.bind(this);
        this.txtfldOnFocus = this.txtfldOnFocus.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.languageDir = this.languageDir.bind(this);
        this.openDrawer=this.openDrawer.bind(this);
        this.sendCode=this.sendCode.bind(this);
        this.backToLogin=this.backToLogin.bind(this);
    }

    shouldComponentUpdate(nextProps,nextState){
		return(
            this.props.store.userData.theme!==nextProps.store.userData.theme ||
			this.props.store.userData.langInfo.lang!==nextProps.store.userData.langInfo.lang ||
            !compareObjects(nextState,this.state)
		)
	}

    sendCode(){
        let err=false;
        this.state.code.forEach((v,i)=>{
            if(!err){//if there is no error (!err) continue checking 
                if(!v){
                    this.state.txtfldRef[i].current.focus();
                    err=true;
                }else if(v.length!==1){
                    this.state.txtfldRef[i].current.focus();
                    err=true;
                }
            }
        });
        if (err){
            this.setState({openSnack:true,messageSnack:'invalid code'});
            return;
        }else if(!this.props.store.userData.login || !this.props.store.userData.login.username){
            this.setState({openSnack:true,messageSnack:'there is no username!call admin'});
            return;
        }
        else{
            let data={
                username: this.props.store.userData.login.username ,
                verifCode: ""+this.state.code[0]+this.state.code[1]+this.state.code[2]+this.state.code[3]
            }
            this.setState({waitForServer:true});
            this.props.actions.checkVerification(data,(messageSnack,isError)=>{
                                    this.setState({waitForServer:false,openSnack:isError,messageSnack:messageSnack});
                                });
            clearInterval(timer);
        }
    }

    backToLogin(){
        this.props.actions.setLogin({status:'off',verifTime:10});
    }

    txtfldOnChange(event,index){
        let val=event.target.value;
        let txtfldFocus=this.state.txtfldFocus;
        if(val.length>0 && val.length<2 && !isNaN(val)){
            let next=++txtfldFocus%4;
            this.state.txtfldRef[next].current.focus();
            this.state.txtfldRef[next].current.select();
            //set txtfldFocus and code in its proper index
            let arr=this.state.code;
            arr[index]=val;
            this.setState({txtfldFocus:(next),code:arr});//this.state.code.map((v,i)=>(i===index?val:v))});
        }else{
            this.state.txtfldRef[this.state.txtfldFocus].current.select();
        }
    }

    txtfldOnFocus(i){
        this.setState({txtfldFocus:i});
    }

    openDrawer() {
        this.setState({isDrawerOpen: true});
    }

    closeDrawer() {
        this.setState({isDrawerOpen: false});
    }

    goTo(sura, aya) {
        this.props.history.push("/" + this.props.store.userData.langInfo.lang + "/" + sura + ":" + aya);
        this.props.actions.changeSura(parseInt(sura));
        this.props.actions.changeAya(parseInt(aya));
    }

   
    handleRequestClose() {
        this.setState({
            openSnack: false,
        });
    };
    languageDir(rtl,ltr){
        return (this.props.store.userData.langInfo.lang !== undefined && this.props.store.userData.langInfo.direction === 'rtl') ?
            rtl : ltr;
    }

    render() {
        
        let iconStyle=(this.props.store.userData.theme==='light'?'appbarIcon_light':'appbarIcon_dark');
        let txtfldStyle={width:'5vw',height:'5vw',padding:'0',textAlign:'center'};
        let d = new Date(this.state.remainTime*1000);
        let remainTime=('0'+d.getUTCMinutes()).slice(-2) + ":" + ('0'+d.getSeconds()).slice(-2)

        return (    
        	<React.Fragment >
                <Tooltip title={this.props.store.userData.locale.login}
                    classes={{tooltip:'tooltip'}}
                >
                    <IconButton
                        onClick={this.openDrawer}
                        className={'appbarIcon '.concat(iconStyle)}
                    >
                        <AccountCircle fontSize='small'/>
                    </IconButton>
                </Tooltip>
                <SwipeableDrawer
                    anchor={this.languageDir('left' , 'right')}
                    open={!!(this.state.isDrawerOpen)}
                    onClose={this.closeDrawer}
                    onOpen={this.openDrawer}
                    id="loginDrawer"
                    ModalProps={{ onBackdropClick: this.closeDrawer }}
                    PaperProps={{style:{backgroundColor:'#008080'}}}
                >
                    <Toolbar id='loginTopBar' 
                            className={"".concat(this.languageDir('loginTopBar_rtl ' ,'loginTopBar_ltr '))
                                        .concat(this.props.store.userData.theme==='light'?'loginTopBar_light':'loginTopBar_dark')}
                            onClick={this.closeDrawer}>
                        <IconButton className={this.props.store.userData.theme==='light'?'drawerCloseIcon_light':'drawerCloseIcon_dark'}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    <img id='drawerlogo' src='/logo512white.png' 
                        alt={this.props.store.userData.locale.quran}
                    />
                    <Container id='loginContainer'>
                        <Box id='loginVerification' display='flex' flexDirection='column'>

                            <Typography variant='h6'
                                style={{
                                    color:'#005253',fontFamily:'vazir',margin:'4vh 0',
                                    textAlign:'center'
                                }}
                            >
                                {this.props.store.userData.locale.verificationDescription}
                            </Typography>

                            <Box display='flex' flexDirection='row' justifyContent='center'>
                                <TextField style={{margin:'0 1vw'}}
                                    inputProps={{style:txtfldStyle,ref:this.state.txtfldRef[0]}}
                                    variant='filled' autoFocus={this.state.txtfldFocus===0}
                                    onChange={(e)=>this.txtfldOnChange(e,0)}
                                    onFocus={()=>this.txtfldOnFocus(0)}
                                />
                                <TextField style={{margin:'0 1vw'}}
                                    inputProps={{style:txtfldStyle,ref:this.state.txtfldRef[1]}}
                                    variant='filled'
                                    onChange={(e)=>this.txtfldOnChange(e,1)}
                                    onFocus={()=>this.txtfldOnFocus(1)}
                                />
                                <TextField style={{margin:'0 1vw'}}
                                    inputProps={{style:txtfldStyle,ref:this.state.txtfldRef[2]}}
                                    variant='filled'
                                    onChange={(e)=>this.txtfldOnChange(e,2)}
                                    onFocus={()=>this.txtfldOnFocus(2)}
                                />
                                <TextField style={{margin:'0 1vw'}}
                                    inputProps={{style:txtfldStyle,ref:this.state.txtfldRef[3]}}
                                    variant='filled'
                                    onChange={(e)=>this.txtfldOnChange(e,3)}
                                    onFocus={()=>this.txtfldOnFocus(3)}
                                />
                            </Box>

                            <Button className={'button_light'} style={{fontFamily:'vazir'}}
                                    disabled={this.state.waitForServer}
                                    onClick={this.sendCode}
                            >
                                {this.state.waitForServer?
                                    <CircularProgress style={{color:'#7BC5BE',width:'100%',height:'100%',
                                        visibility:(this.state.waitForServer ?'visible':'hidden')}}
                                    />:
                                    this.props.store.userData.locale.sendCode
                                }
                            </Button>

                            <Typography variant='h4' style={{color:'#005253',fontFamily:'vazir',margin:'4vh 0 1vh 0',}}>
                                {remainTime}
                            </Typography>

                            <Button style={{color:'#005253',fontFamily:'vazir',margin:'1vh 0',}}
                                    onClick={this.backToLogin}
                            >
                                {this.props.store.userData.locale.wrongPhoneNumber}
                            </Button>

                            <Typography 
                                style={{
                                    color:'#003434',fontFamily:'vazir',
                                    margin:'6vh  0 2vh 0',direction:this.languageDir("rtl","ltr")
                                }}
                                variant='subtitle1'
                            >
                                {this.props.store.userData.locale.orLoginWith}
                            </Typography>
                            <Box display='flex' flexDirection='row'>
                                <IconButton className={this.props.store.userData.theme==='dark'?'drawerCloseIcon_light':'drawerCloseIcon_dark'}>
                                    <Facebook />
                                </IconButton>
                                <IconButton className={this.props.store.userData.theme==='dark'?'drawerCloseIcon_light':'drawerCloseIcon_dark'}>
                                    <Search />
                                </IconButton>
                                <IconButton className={this.props.store.userData.theme==='dark'?'drawerCloseIcon_light':'drawerCloseIcon_dark'}>
                                    <Twitter />
                                </IconButton>
                                <IconButton className={this.props.store.userData.theme==='dark'?'drawerCloseIcon_light':'drawerCloseIcon_dark'}>
                                    <Instagram />
                                </IconButton>
                            </Box>
                        </Box>
                        <Snackbar
                            open={this.state.openSnack}
                            message={this.state.messageSnack}
                            action="Ok"
                            autoHideDuration={5000}
                            onClose={this.handleRequestClose}
                        />
                    </Container>

                </SwipeableDrawer>
        	</React.Fragment>
        );
    }
}

function mapStateToProps(state, ownProps){
    return {
        store:{
            'userData':  {
                langInfo :state.userData.langInfo,
                locale:{
                    login:state.userData.locale.login,
                    quran:state.userData.locale.quran,
                    wrongPhoneNumber:state.userData.locale.wrongPhoneNumber,
                    sendCode:state.userData.locale.sendCode,
                    orLoginWith:state.userData.locale.orLoginWith,
                    verificationDescription:state.userData.locale.verificationDescription
                },
                theme:state.userData.theme,
                login:{
                    verifTime : state.userData.login.verifTime,
                    username :state.userData.login.username
                }
            },
        }
    };
}

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(quranActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Verification));
import {combineReducers} from 'redux';
import quranReducer from './quranReducer';
import transReducer from './transReducer';
import userDataReducer from './userDataReducer';
import reciteReducer from './reciteReducer'

const rootReducer = combineReducers({
	'userData':userDataReducer,
	'quranData':quranReducer,
	'transData':transReducer,
	'reciteData':reciteReducer
});

export default rootReducer;
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as quranActions from '../../actions/actionCreators';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { Box, CircularProgress, Container, Snackbar } from '@material-ui/core';
import {AccountCircle, Facebook, Instagram,Twitter} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';
import {Search} from '@trejgun/material-ui-icons-google';
import compareObjects from '../../utility/compareObjects';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isDrawerOpen: false,
            country: '',//object { "name": "Indonesia","dial_code": "+62","code": "ID"}
            phoneNumber: '',
            phoneNumberError: false,
            openSnack: false,
            messageSnack: '',
            waitForServer:false,
        };
        
        if(props.store.userData.countryList && props.store.userData.countryList.length>0){
            this.state.country=props.store.userData.countryList.find(
                (el)=>el.country_en.indexOf(props.store.userData.country)>=0);
        }

        this.sendVerificationOnClick = this.sendVerificationOnClick.bind(this);
        this.goTo = this.goTo.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.languageDir = this.languageDir.bind(this);
        this.openDrawer=this.openDrawer.bind(this);
        
    }

    shouldComponentUpdate(nextProps,nextState){
		return(
            this.props.store.userData.theme!==nextProps.store.userData.theme ||
			this.props.store.userData.langInfo.lang!==nextProps.store.userData.langInfo.lang ||
            this.props.store.userData.countryList.length!==nextProps.store.userData.countryList.length ||
            this.props.store.userData.suggestLogin !== nextProps.store.userData.suggestLogin ||
            this.props.store.userData.login.forcedOpen !== nextProps.store.userData.login.forcedOpen ||
            !compareObjects(nextState,this.state)
		)
	}

    static getDerivedStateFromProps(nextProps,state) {
        let c=null;

        if(nextProps.store.userData.countryList && nextProps.store.userData.countryList.length>0){
            c=nextProps.store.userData.countryList.find(
                (el)=>el.country_en.indexOf(nextProps.store.userData.country)>=0);
        }

        return {
            country:c,
        }
    }

    sendVerificationOnClick(){
        let data={};
        let pn=this.state.phoneNumber;
        if(pn.length!==10){console.log(pn);
            this.setState({
                phoneNumberError:true,
                openSnack:true,
                messageSnack:'phone number error'
            });
        }else if(!this.state.country){
            this.setState({
                phoneNumberError:true,
                openSnack:true,
                messageSnack:'country code error'
            });
        }else{
            data.phoneNumber='+'+this.state.country.dial+pn;
            data.country_id=this.state.country.id;
            this.setState({phoneNumberError:false,waitForServer:true});
            //second parameter is a function that will call on error situation
            this.props.actions.getVerification(data,(messageSnack,isError)=>{this.setState({waitForServer:false,openSnack:isError,messageSnack:messageSnack})});
        }
    }
    openDrawer() {
        this.setState({isDrawerOpen: true});
    }

    closeDrawer() {
        if(this.props.store.userData.suggestLogin){
            this.props.actions.setSuggestLogin(false);
        }
        this.setState({isDrawerOpen: false});
        if(this.props.store.userData.login.forcedOpen ){
            this.props.actions.setLogin({status:'off' , forcedOpen:false});
        }
        
        
    }

    goTo(sura, aya) {
        this.props.history.push("/" + this.props.store.userData.langInfo.lang + "/" + sura + ":" + aya);
        this.props.actions.changeSura(parseInt(sura));
        this.props.actions.changeAya(parseInt(aya));
    }

   
    handleRequestClose() {
        this.setState({
            openSnack: false,
        });
    };
    
    languageDir(rtl,ltr){
        return (this.props.store.userData.langInfo.lang !== undefined && this.props.store.userData.langInfo.direction === 'rtl') ?
            rtl : ltr;
    }

    render() {
        console.log("trender")
        
        let iconStyle=(this.props.store.userData.theme==='light'?'appbarIcon_light':'appbarIcon_dark');
        let rtlClass=this.languageDir("drawerItem_rtl ","");
        
        return (
        	<React.Fragment >
                <Tooltip title={this.props.store.userData.locale.login}
                    classes={{tooltip:'tooltip'}}
                >
                    <IconButton
                        onClick={this.openDrawer}
                        className={'appbarIcon '.concat(iconStyle)}
                    >
                        <AccountCircle fontSize='small'/>
                    </IconButton>
                </Tooltip>
                <SwipeableDrawer 
                    anchor={this.languageDir('left' , 'right')}
                    open={
                            (this.props.store.userData.login.forcedOpen ||//force drawer to open after an action, for example create a khatam while being anonymous 
                            (this.props.store.userData.suggestLogin && this.props.history.location.pathname.indexOf("khatam")>-1) ||//when first time in khatam
                            this.state.isDrawerOpen)
                        }
                    onClose={this.closeDrawer}
                    onOpen={this.openDrawer}
                    id="loginDrawer"
                    ModalProps={{ onBackdropClick: this.closeDrawer }}
                    PaperProps={{style:{ backgroundColor:'#008080'}}}
                >
                    <Toolbar id='loginTopBar' 
                            className={"".concat(this.languageDir('loginTopBar_rtl ' ,'loginTopBar_ltr '))
                                        .concat(this.props.store.userData.theme==='light'?'loginTopBar_light':'loginTopBar_dark')}
                            onClick={this.closeDrawer}>
                        <IconButton 
                            className={this.props.store.userData.theme==='light'?'drawerCloseIcon_light':'drawerCloseIcon_dark'}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    <img 
                        id='drawerlogo' src='/logo512white.png' 
                        alt={this.props.store.userData.locale.quran}
                    />
                    <Container id='loginContainer' >
                        <Box  id='loginUserData' display='flex' flexDirection='column' >
                            <Typography variant='h4' style={{color:'#005253',fontFamily:'vazir',margin:'4vh 0',}}>
                                {this.props.store.userData.locale.login}
                            </Typography>
                            <Autocomplete  
                                options={this.props.store.userData.countryList}
                                disableClearable
                                getOptionLabel={country=>{
                                        let cName=undefined;
                                        switch(this.props.store.userData.langInfo.lang){
                                            case 'fa':
                                                cName=country.country_fa;
                                                break;
                                            case 'ur':
                                                cName=country.country_ur;
                                                break;
                                            case 'ar':
                                                cName=country.country_ar;
                                                break;
                                            default:
                                                cName=country.country_en;
                                        }
                                        return (cName+' (+'+country.dial+')');
                                    }
                                }
                                classes={{option:this.languageDir('listItem_rtl','listItem_ltr')}}
                                onChange={(e,value)=>{
                                    this.setState({country:value});
                                    this.props.actions.setCountry(value.country_en)}
                                }
                                className={"drawerItem ".concat(rtlClass)}
                                value={this.state.country}
                                getOptionSelected={(o,v)=>{return o===v;}}
                                renderInput={(params) => <TextField {...params} className={'autocompelete-textfield'} variant="standard"/>}
                            />
                            
                            <NumberFormat 
                                customInput={
                                    (props)=><TextField {...props}
                                                onBlur={(e)=>{this.setState({phoneNumber:(e.target.value).replace(/\s/g,"")})}}
                                                variant='outlined'
                                                label={this.props.store.userData.locale.phoneNumberNo0}
                                                className={"drawerItem "}
                                                inputProps={this.state.phoneNumberError ? {style:{border:"1px solid red"}} : {}}
                                                onKeyUp={(e)=>{
                                                        if(e.key==='Enter'){
                                                            this.setState({phoneNumber:(e.target.value).replace(/\s/g,"")},
                                                                            ()=>{this.sendVerificationOnClick();});
                                                        }
                                                    }
                                                }
                                            />
                                }
                                format='### ### ####'  allowNegative='false'
                            />
                            <Button style={{justifyContent:'start'}} className={'button_light'} disabled={this.state.waitForServer}
                                onClick={this.sendVerificationOnClick}
                            >
                                {this.state.waitForServer?
                                    <CircularProgress style={{color:'#7BC5BE',width:'100%',height:'100%',
                                        visibility:(this.state.waitForServer ?'visible':'hidden')}}
                                    /> :
                                    this.props.store.userData.locale.sendCode
                                }
                            </Button>
                            
                            <Typography style={{color:'#003434',fontFamily:'vazir',margin:'6vh  0 2vh 0',direction:this.languageDir("rtl","ltr")}}
                                    variant='subtitle1'
                            >
                                {this.props.store.userData.locale.orLoginWith}
                            </Typography>
                            <Box display='flex' flexDirection='row'>
                                <IconButton className={this.props.store.userData.theme==='dark'?'drawerCloseIcon_light':'drawerCloseIcon_dark'}>
                                    <Facebook />
                                </IconButton>
                                <IconButton className={this.props.store.userData.theme==='dark'?'drawerCloseIcon_light':'drawerCloseIcon_dark'}>
                                    <Search />
                                </IconButton>
                                <IconButton className={this.props.store.userData.theme==='dark'?'drawerCloseIcon_light':'drawerCloseIcon_dark'}>
                                    <Twitter />
                                </IconButton>
                                <IconButton className={this.props.store.userData.theme==='dark'?'drawerCloseIcon_light':'drawerCloseIcon_dark'}>
                                    <Instagram />
                                </IconButton>
                            </Box>
                        </Box>
                        <Snackbar
                            open={this.state.openSnack}
                            message={this.state.messageSnack}
                            action="Ok"
                            autoHideDuration={5000}
                            onClose={this.handleRequestClose}
                        />
                    </Container>
                    
                </SwipeableDrawer>
        	</React.Fragment>
        );
    }
}

function mapStateToProps(state, ownProps){
    return {
        store:{
            'userData':  {
                langInfo :state.userData.langInfo,
                locale:{
                    login:state.userData.locale.login,
                    quran:state.userData.locale.quran,
                    phoneNumberNo0:state.userData.locale.phoneNumberNo0,
                    sendCode:state.userData.locale.sendCode,
                    orLoginWith:state.userData.locale.orLoginWith
                },
                theme:state.userData.theme,
                country:state.userData.country,
                countryList:state.userData.countryList,
                suggestLogin:state.userData.suggestLogin,
                login:{
                    forcedOpen:state.userData.login.forcedOpen,
                }
            }
        }
    };
}

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(quranActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
let rubeHezbData = [
  {
    id: 1,
    rub_number: 1,
    hizb_number: 1,
    juz_number: 1,
    quarter_number: 1,
    ayah_number: 1,
    start_surah_number: 1,
    start_ayah_number: 1,
    end_surah_number: 2,
    end_ayah_number: 25,
    ayah_count: 32,
    uthmani_pages_count: 5,
    uthmani_pages_range: [1, 5],
    index: 0,
  },
  {
    id: 2,
    rub_number: 2,
    hizb_number: 1,
    juz_number: 1,
    quarter: 2,
    ayah_number: 33,
    start_surah_number: 2,
    start_ayah_number: 26,
    end_surah_number: 2,
    end_ayah_number: 43,
    ayah_count: 18,
    uthmani_pages_count: 3,
    uthmani_pages_range: [5, 7],
    index: 1,
  },
  {
    id: 3,
    rub_number: 3,
    hizb_number: 1,
    juz_number: 1,
    quarter: 3,
    ayah_number: 51,
    start_surah_number: 2,
    start_ayah_number: 44,
    end_surah_number: 2,
    end_ayah_number: 59,
    ayah_count: 16,
    uthmani_pages_count: 3,
    uthmani_pages_range: [7, 9],
    index: 2,
  },
  {
    id: 4,
    rub_number: 4,
    hizb_number: 1,
    juz_number: 1,
    quarter: 4,
    ayah_number: 67,
    start_surah_number: 2,
    start_ayah_number: 60,
    end_surah_number: 2,
    end_ayah_number: 74,
    ayah_count: 15,
    uthmani_pages_count: 3,
    uthmani_pages_range: [9, 11],
    index: 3,
  },
  {
    id: 5,
    rub_number: 5,
    hizb_number: 2,
    juz_number: 1,
    quarter: 1,
    ayah_number: 82,
    start_surah_number: 2,
    start_ayah_number: 75,
    end_surah_number: 2,
    end_ayah_number: 91,
    ayah_count: 17,
    uthmani_pages_count: 4,
    uthmani_pages_range: [11, 14],
    index: 0,
  },
  {
    id: 6,
    rub_number: 6,
    hizb_number: 2,
    juz_number: 1,
    quarter: 2,
    ayah_number: 99,
    start_surah_number: 2,
    start_ayah_number: 92,
    end_surah_number: 2,
    end_ayah_number: 105,
    ayah_count: 14,
    uthmani_pages_count: 3,
    uthmani_pages_range: [14, 16],
    index: 1,
  },
  {
    id: 7,
    rub_number: 7,
    hizb_number: 2,
    juz_number: 1,
    quarter: 3,
    ayah_number: 113,
    start_surah_number: 2,
    start_ayah_number: 106,
    end_surah_number: 2,
    end_ayah_number: 123,
    ayah_count: 18,
    uthmani_pages_count: 3,
    uthmani_pages_range: [17, 19],
    index: 2,
  },
  {
    id: 8,
    rub_number: 8,
    hizb_number: 2,
    juz_number: 1,
    quarter: 4,
    ayah_number: 131,
    start_surah_number: 2,
    start_ayah_number: 124,
    end_surah_number: 2,
    end_ayah_number: 141,
    ayah_count: 18,
    uthmani_pages_count: 3,
    uthmani_pages_range: [19, 21],
    index: 3,
  },
  {
    id: 9,
    rub_number: 9,
    hizb_number: 3,
    juz_number: 2,
    quarter: 1,
    ayah_number: 149,
    start_surah_number: 2,
    start_ayah_number: 142,
    end_surah_number: 2,
    end_ayah_number: 157,
    ayah_count: 16,
    uthmani_pages_count: 3,
    uthmani_pages_range: [22, 24],
    index: 0,
  },
  {
    id: 10,
    rub_number: 10,
    hizb_number: 3,
    juz_number: 2,
    quarter: 2,
    ayah_number: 165,
    start_surah_number: 2,
    start_ayah_number: 158,
    end_surah_number: 2,
    end_ayah_number: 176,
    ayah_count: 19,
    uthmani_pages_count: 3,
    uthmani_pages_range: [24, 26],
    index: 1,
  },
  {
    id: 11,
    rub_number: 11,
    hizb_number: 3,
    juz_number: 2,
    quarter: 3,
    ayah_number: 184,
    start_surah_number: 2,
    start_ayah_number: 177,
    end_surah_number: 2,
    end_ayah_number: 188,
    ayah_count: 12,
    uthmani_pages_count: 3,
    uthmani_pages_range: [27, 29],
    index: 2,
  },
  {
    id: 12,
    rub_number: 12,
    hizb_number: 3,
    juz_number: 2,
    quarter: 4,
    ayah_number: 196,
    start_surah_number: 2,
    start_ayah_number: 189,
    end_surah_number: 2,
    end_ayah_number: 202,
    ayah_count: 14,
    uthmani_pages_count: 3,
    uthmani_pages_range: [29, 31],
    index: 3,
  },
  {
    id: 13,
    rub_number: 13,
    hizb_number: 4,
    juz_number: 2,
    quarter: 1,
    ayah_number: 210,
    start_surah_number: 2,
    start_ayah_number: 203,
    end_surah_number: 2,
    end_ayah_number: 218,
    ayah_count: 16,
    uthmani_pages_count: 3,
    uthmani_pages_range: [32, 34],
    index: 0,
  },
  {
    id: 14,
    rub_number: 14,
    hizb_number: 4,
    juz_number: 2,
    quarter: 2,
    ayah_number: 226,
    start_surah_number: 2,
    start_ayah_number: 219,
    end_surah_number: 2,
    end_ayah_number: 232,
    ayah_count: 14,
    uthmani_pages_count: 4,
    uthmani_pages_range: [34, 37],
    index: 1,
  },
  {
    id: 15,
    rub_number: 15,
    hizb_number: 4,
    juz_number: 2,
    quarter: 3,
    ayah_number: 240,
    start_surah_number: 2,
    start_ayah_number: 233,
    end_surah_number: 2,
    end_ayah_number: 242,
    ayah_count: 10,
    uthmani_pages_count: 3,
    uthmani_pages_range: [37, 39],
    index: 2,
  },
  {
    id: 16,
    rub_number: 16,
    hizb_number: 4,
    juz_number: 2,
    quarter: 4,
    ayah_number: 250,
    start_surah_number: 2,
    start_ayah_number: 243,
    end_surah_number: 2,
    end_ayah_number: 252,
    ayah_count: 10,
    uthmani_pages_count: 3,
    uthmani_pages_range: [39, 41],
    index: 3,
  },
  {
    id: 17,
    rub_number: 17,
    hizb_number: 5,
    juz_number: 3,
    quarter: 1,
    ayah_number: 260,
    start_surah_number: 2,
    start_ayah_number: 253,
    end_surah_number: 2,
    end_ayah_number: 262,
    ayah_count: 10,
    uthmani_pages_count: 3,
    uthmani_pages_range: [42, 44],
    index: 0,
  },
  {
    id: 18,
    rub_number: 18,
    hizb_number: 5,
    juz_number: 3,
    quarter: 2,
    ayah_number: 270,
    start_surah_number: 2,
    start_ayah_number: 263,
    end_surah_number: 2,
    end_ayah_number: 271,
    ayah_count: 9,
    uthmani_pages_count: 3,
    uthmani_pages_range: [44, 46],
    index: 1,
  },
  {
    id: 19,
    rub_number: 19,
    hizb_number: 5,
    juz_number: 3,
    quarter: 3,
    ayah_number: 279,
    start_surah_number: 2,
    start_ayah_number: 272,
    end_surah_number: 2,
    end_ayah_number: 282,
    ayah_count: 11,
    uthmani_pages_count: 3,
    uthmani_pages_range: [46, 48],
    index: 2,
  },
  {
    id: 20,
    rub_number: 20,
    hizb_number: 5,
    juz_number: 3,
    quarter: 4,
    ayah_number: 290,
    start_surah_number: 2,
    start_ayah_number: 283,
    end_surah_number: 3,
    end_ayah_number: 14,
    ayah_count: 18,
    uthmani_pages_count: 3,
    uthmani_pages_range: [49, 51],
    index: 3,
  },
  {
    id: 21,
    rub_number: 21,
    hizb_number: 6,
    juz_number: 3,
    quarter: 1,
    ayah_number: 308,
    start_surah_number: 3,
    start_ayah_number: 15,
    end_surah_number: 3,
    end_ayah_number: 32,
    ayah_count: 18,
    uthmani_pages_count: 4,
    uthmani_pages_range: [51, 54],
    index: 0,
  },
  {
    id: 22,
    rub_number: 22,
    hizb_number: 6,
    juz_number: 3,
    quarter: 2,
    ayah_number: 326,
    start_surah_number: 3,
    start_ayah_number: 33,
    end_surah_number: 3,
    end_ayah_number: 51,
    ayah_count: 19,
    uthmani_pages_count: 3,
    uthmani_pages_range: [54, 56],
    index: 1,
  },
  {
    id: 23,
    rub_number: 23,
    hizb_number: 6,
    juz_number: 3,
    quarter: 3,
    ayah_number: 345,
    start_surah_number: 3,
    start_ayah_number: 52,
    end_surah_number: 3,
    end_ayah_number: 74,
    ayah_count: 23,
    uthmani_pages_count: 4,
    uthmani_pages_range: [56, 59],
    index: 2,
  },
  {
    id: 24,
    rub_number: 24,
    hizb_number: 6,
    juz_number: 3,
    quarter: 4,
    ayah_number: 368,
    start_surah_number: 3,
    start_ayah_number: 75,
    end_surah_number: 3,
    end_ayah_number: 92,
    ayah_count: 18,
    uthmani_pages_count: 4,
    uthmani_pages_range: [59, 62],
    index: 3,
  },
  {
    id: 25,
    rub_number: 25,
    hizb_number: 7,
    juz_number: 4,
    quarter: 1,
    ayah_number: 386,
    start_surah_number: 3,
    start_ayah_number: 93,
    end_surah_number: 3,
    end_ayah_number: 112,
    ayah_count: 20,
    uthmani_pages_count: 3,
    uthmani_pages_range: [62, 64],
    index: 0,
  },
  {
    id: 26,
    rub_number: 26,
    hizb_number: 7,
    juz_number: 4,
    quarter: 2,
    ayah_number: 406,
    start_surah_number: 3,
    start_ayah_number: 113,
    end_surah_number: 3,
    end_ayah_number: 132,
    ayah_count: 20,
    uthmani_pages_count: 3,
    uthmani_pages_range: [64, 66],
    index: 1,
  },
  {
    id: 27,
    rub_number: 27,
    hizb_number: 7,
    juz_number: 4,
    quarter: 3,
    ayah_number: 426,
    start_surah_number: 3,
    start_ayah_number: 133,
    end_surah_number: 3,
    end_ayah_number: 152,
    ayah_count: 20,
    uthmani_pages_count: 3,
    uthmani_pages_range: [67, 69],
    index: 2,
  },
  {
    id: 28,
    rub_number: 28,
    hizb_number: 7,
    juz_number: 4,
    quarter: 4,
    ayah_number: 446,
    start_surah_number: 3,
    start_ayah_number: 153,
    end_surah_number: 3,
    end_ayah_number: 170,
    ayah_count: 18,
    uthmani_pages_count: 4,
    uthmani_pages_range: [69, 72],
    index: 3,
  },
  {
    id: 29,
    rub_number: 29,
    hizb_number: 8,
    juz_number: 4,
    quarter: 1,
    ayah_number: 464,
    start_surah_number: 3,
    start_ayah_number: 171,
    end_surah_number: 3,
    end_ayah_number: 185,
    ayah_count: 15,
    uthmani_pages_count: 3,
    uthmani_pages_range: [72, 74],
    index: 0,
  },
  {
    id: 30,
    rub_number: 30,
    hizb_number: 8,
    juz_number: 4,
    quarter: 2,
    ayah_number: 479,
    start_surah_number: 3,
    start_ayah_number: 186,
    end_surah_number: 3,
    end_ayah_number: 200,
    ayah_count: 15,
    uthmani_pages_count: 3,
    uthmani_pages_range: [74, 76],
    index: 1,
  },
  {
    id: 31,
    rub_number: 31,
    hizb_number: 8,
    juz_number: 4,
    quarter: 3,
    ayah_number: 494,
    start_surah_number: 4,
    start_ayah_number: 1,
    end_surah_number: 4,
    end_ayah_number: 11,
    ayah_count: 11,
    uthmani_pages_count: 2,
    uthmani_pages_range: [77, 78],
    index: 2,
  },
  {
    id: 32,
    rub_number: 32,
    hizb_number: 8,
    juz_number: 4,
    quarter: 4,
    ayah_number: 505,
    start_surah_number: 4,
    start_ayah_number: 12,
    end_surah_number: 4,
    end_ayah_number: 23,
    ayah_count: 12,
    uthmani_pages_count: 3,
    uthmani_pages_range: [79, 81],
    index: 3,
  },
  {
    id: 33,
    rub_number: 33,
    hizb_number: 9,
    juz_number: 5,
    quarter: 1,
    ayah_number: 517,
    start_surah_number: 4,
    start_ayah_number: 24,
    end_surah_number: 4,
    end_ayah_number: 35,
    ayah_count: 12,
    uthmani_pages_count: 3,
    uthmani_pages_range: [82, 84],
    index: 0,
  },
  {
    id: 34,
    rub_number: 34,
    hizb_number: 9,
    juz_number: 5,
    quarter: 2,
    ayah_number: 529,
    start_surah_number: 4,
    start_ayah_number: 36,
    end_surah_number: 4,
    end_ayah_number: 57,
    ayah_count: 22,
    uthmani_pages_count: 4,
    uthmani_pages_range: [84, 87],
    index: 1,
  },
  {
    id: 35,
    rub_number: 35,
    hizb_number: 9,
    juz_number: 5,
    quarter: 3,
    ayah_number: 551,
    start_surah_number: 4,
    start_ayah_number: 58,
    end_surah_number: 4,
    end_ayah_number: 73,
    ayah_count: 16,
    uthmani_pages_count: 3,
    uthmani_pages_range: [87, 89],
    index: 2,
  },
  {
    id: 36,
    rub_number: 36,
    hizb_number: 9,
    juz_number: 5,
    quarter: 4,
    ayah_number: 567,
    start_surah_number: 4,
    start_ayah_number: 74,
    end_surah_number: 4,
    end_ayah_number: 87,
    ayah_count: 14,
    uthmani_pages_count: 4,
    uthmani_pages_range: [89, 92],
    index: 3,
  },
  {
    id: 37,
    rub_number: 37,
    hizb_number: 10,
    juz_number: 5,
    quarter: 1,
    ayah_number: 581,
    start_surah_number: 4,
    start_ayah_number: 88,
    end_surah_number: 4,
    end_ayah_number: 99,
    ayah_count: 12,
    uthmani_pages_count: 3,
    uthmani_pages_range: [92, 94],
    index: 0,
  },
  {
    id: 38,
    rub_number: 38,
    hizb_number: 10,
    juz_number: 5,
    quarter: 2,
    ayah_number: 593,
    start_surah_number: 4,
    start_ayah_number: 100,
    end_surah_number: 4,
    end_ayah_number: 113,
    ayah_count: 14,
    uthmani_pages_count: 3,
    uthmani_pages_range: [94, 96],
    index: 1,
  },
  {
    id: 39,
    rub_number: 39,
    hizb_number: 10,
    juz_number: 5,
    quarter: 3,
    ayah_number: 607,
    start_surah_number: 4,
    start_ayah_number: 114,
    end_surah_number: 4,
    end_ayah_number: 134,
    ayah_count: 21,
    uthmani_pages_count: 3,
    uthmani_pages_range: [97, 99],
    index: 2,
  },
  {
    id: 40,
    rub_number: 40,
    hizb_number: 10,
    juz_number: 5,
    quarter: 4,
    ayah_number: 628,
    start_surah_number: 4,
    start_ayah_number: 135,
    end_surah_number: 4,
    end_ayah_number: 147,
    ayah_count: 13,
    uthmani_pages_count: 2,
    uthmani_pages_range: [100, 101],
    index: 3,
  },
  {
    id: 41,
    rub_number: 41,
    hizb_number: 11,
    juz_number: 6,
    quarter: 1,
    ayah_number: 641,
    start_surah_number: 4,
    start_ayah_number: 148,
    end_surah_number: 4,
    end_ayah_number: 162,
    ayah_count: 15,
    uthmani_pages_count: 2,
    uthmani_pages_range: [102, 103],
    index: 0,
  },
  {
    id: 42,
    rub_number: 42,
    hizb_number: 11,
    juz_number: 6,
    quarter: 2,
    ayah_number: 656,
    start_surah_number: 4,
    start_ayah_number: 163,
    end_surah_number: 4,
    end_ayah_number: 176,
    ayah_count: 14,
    uthmani_pages_count: 3,
    uthmani_pages_range: [104, 106],
    index: 1,
  },
  {
    id: 43,
    rub_number: 43,
    hizb_number: 11,
    juz_number: 6,
    quarter: 3,
    ayah_number: 670,
    start_surah_number: 5,
    start_ayah_number: 1,
    end_surah_number: 5,
    end_ayah_number: 11,
    ayah_count: 11,
    uthmani_pages_count: 4,
    uthmani_pages_range: [106, 109],
    index: 2,
  },
  {
    id: 44,
    rub_number: 44,
    hizb_number: 11,
    juz_number: 6,
    quarter: 4,
    ayah_number: 681,
    start_surah_number: 5,
    start_ayah_number: 12,
    end_surah_number: 5,
    end_ayah_number: 26,
    ayah_count: 15,
    uthmani_pages_count: 4,
    uthmani_pages_range: [109, 112],
    index: 3,
  },
  {
    id: 45,
    rub_number: 45,
    hizb_number: 12,
    juz_number: 6,
    quarter: 1,
    ayah_number: 696,
    start_surah_number: 5,
    start_ayah_number: 27,
    end_surah_number: 5,
    end_ayah_number: 40,
    ayah_count: 14,
    uthmani_pages_count: 3,
    uthmani_pages_range: [112, 114],
    index: 0,
  },
  {
    id: 46,
    rub_number: 46,
    hizb_number: 12,
    juz_number: 6,
    quarter: 2,
    ayah_number: 710,
    start_surah_number: 5,
    start_ayah_number: 41,
    end_surah_number: 5,
    end_ayah_number: 50,
    ayah_count: 10,
    uthmani_pages_count: 3,
    uthmani_pages_range: [114, 116],
    index: 1,
  },
  {
    id: 47,
    rub_number: 47,
    hizb_number: 12,
    juz_number: 6,
    quarter: 3,
    ayah_number: 720,
    start_surah_number: 5,
    start_ayah_number: 51,
    end_surah_number: 5,
    end_ayah_number: 66,
    ayah_count: 16,
    uthmani_pages_count: 3,
    uthmani_pages_range: [117, 119],
    index: 2,
  },
  {
    id: 48,
    rub_number: 48,
    hizb_number: 12,
    juz_number: 6,
    quarter: 4,
    ayah_number: 736,
    start_surah_number: 5,
    start_ayah_number: 67,
    end_surah_number: 5,
    end_ayah_number: 81,
    ayah_count: 15,
    uthmani_pages_count: 3,
    uthmani_pages_range: [119, 121],
    index: 3,
  },
  {
    id: 49,
    rub_number: 49,
    hizb_number: 13,
    juz_number: 7,
    quarter: 1,
    ayah_number: 751,
    start_surah_number: 5,
    start_ayah_number: 82,
    end_surah_number: 5,
    end_ayah_number: 96,
    ayah_count: 15,
    uthmani_pages_count: 4,
    uthmani_pages_range: [121, 124],
    index: 0,
  },
  {
    id: 50,
    rub_number: 50,
    hizb_number: 13,
    juz_number: 7,
    quarter: 2,
    ayah_number: 766,
    start_surah_number: 5,
    start_ayah_number: 97,
    end_surah_number: 5,
    end_ayah_number: 108,
    ayah_count: 12,
    uthmani_pages_count: 2,
    uthmani_pages_range: [124, 125],
    index: 1,
  },
  {
    id: 51,
    rub_number: 51,
    hizb_number: 13,
    juz_number: 7,
    quarter: 3,
    ayah_number: 778,
    start_surah_number: 5,
    start_ayah_number: 109,
    end_surah_number: 6,
    end_ayah_number: 12,
    ayah_count: 24,
    uthmani_pages_count: 4,
    uthmani_pages_range: [126, 129],
    index: 2,
  },
  {
    id: 52,
    rub_number: 52,
    hizb_number: 13,
    juz_number: 7,
    quarter: 4,
    ayah_number: 802,
    start_surah_number: 6,
    start_ayah_number: 13,
    end_surah_number: 6,
    end_ayah_number: 35,
    ayah_count: 23,
    uthmani_pages_count: 3,
    uthmani_pages_range: [129, 131],
    index: 3,
  },
  {
    id: 53,
    rub_number: 53,
    hizb_number: 14,
    juz_number: 7,
    quarter: 1,
    ayah_number: 825,
    start_surah_number: 6,
    start_ayah_number: 36,
    end_surah_number: 6,
    end_ayah_number: 58,
    ayah_count: 23,
    uthmani_pages_count: 3,
    uthmani_pages_range: [132, 134],
    index: 0,
  },
  {
    id: 54,
    rub_number: 54,
    hizb_number: 14,
    juz_number: 7,
    quarter: 2,
    ayah_number: 848,
    start_surah_number: 6,
    start_ayah_number: 59,
    end_surah_number: 6,
    end_ayah_number: 73,
    ayah_count: 15,
    uthmani_pages_count: 3,
    uthmani_pages_range: [134, 136],
    index: 1,
  },
  {
    id: 55,
    rub_number: 55,
    hizb_number: 14,
    juz_number: 7,
    quarter: 3,
    ayah_number: 863,
    start_surah_number: 6,
    start_ayah_number: 74,
    end_surah_number: 6,
    end_ayah_number: 95,
    ayah_count: 21,
    uthmani_pages_count: 3,
    uthmani_pages_range: [137, 139],
    index: 2,
  },
  {
    id: 56,
    rub_number: 56,
    hizb_number: 14,
    juz_number: 7,
    quarter: 4,
    ayah_number: 885,
    start_surah_number: 6,
    start_ayah_number: 96,
    end_surah_number: 6,
    end_ayah_number: 110,
    ayah_count: 16,
    uthmani_pages_count: 2,
    uthmani_pages_range: [140, 141],
    index: 3,
  },
  {
    id: 57,
    rub_number: 57,
    hizb_number: 15,
    juz_number: 8,
    quarter: 1,
    ayah_number: 900,
    start_surah_number: 6,
    start_ayah_number: 111,
    end_surah_number: 6,
    end_ayah_number: 126,
    ayah_count: 16,
    uthmani_pages_count: 3,
    uthmani_pages_range: [142, 144],
    index: 0,
  },
  {
    id: 58,
    rub_number: 58,
    hizb_number: 15,
    juz_number: 8,
    quarter: 2,
    ayah_number: 916,
    start_surah_number: 6,
    start_ayah_number: 127,
    end_surah_number: 6,
    end_ayah_number: 140,
    ayah_count: 14,
    uthmani_pages_count: 3,
    uthmani_pages_range: [144, 146],
    index: 1,
  },
  {
    id: 59,
    rub_number: 59,
    hizb_number: 15,
    juz_number: 8,
    quarter: 3,
    ayah_number: 930,
    start_surah_number: 6,
    start_ayah_number: 141,
    end_surah_number: 6,
    end_ayah_number: 150,
    ayah_count: 10,
    uthmani_pages_count: 3,
    uthmani_pages_range: [146, 148],
    index: 2,
  },
  {
    id: 60,
    rub_number: 60,
    hizb_number: 15,
    juz_number: 8,
    quarter: 4,
    ayah_number: 940,
    start_surah_number: 6,
    start_ayah_number: 151,
    end_surah_number: 6,
    end_ayah_number: 165,
    ayah_count: 15,
    uthmani_pages_count: 3,
    uthmani_pages_range: [148, 150],
    index: 3,
  },
  {
    id: 61,
    rub_number: 61,
    hizb_number: 16,
    juz_number: 8,
    quarter: 1,
    ayah_number: 955,
    start_surah_number: 7,
    start_ayah_number: 1,
    end_surah_number: 7,
    end_ayah_number: 30,
    ayah_count: 30,
    uthmani_pages_count: 3,
    uthmani_pages_range: [151, 153],
    index: 0,
  },
  {
    id: 62,
    rub_number: 62,
    hizb_number: 16,
    juz_number: 8,
    quarter: 2,
    ayah_number: 985,
    start_surah_number: 7,
    start_ayah_number: 31,
    end_surah_number: 7,
    end_ayah_number: 46,
    ayah_count: 16,
    uthmani_pages_count: 3,
    uthmani_pages_range: [154, 156],
    index: 1,
  },
  {
    id: 63,
    rub_number: 63,
    hizb_number: 16,
    juz_number: 8,
    quarter: 3,
    ayah_number: 1001,
    start_surah_number: 7,
    start_ayah_number: 47,
    end_surah_number: 7,
    end_ayah_number: 64,
    ayah_count: 18,
    uthmani_pages_count: 3,
    uthmani_pages_range: [156, 158],
    index: 2,
  },
  {
    id: 64,
    rub_number: 64,
    hizb_number: 16,
    juz_number: 8,
    quarter: 4,
    ayah_number: 1019,
    start_surah_number: 7,
    start_ayah_number: 65,
    end_surah_number: 7,
    end_ayah_number: 87,
    ayah_count: 23,
    uthmani_pages_count: 4,
    uthmani_pages_range: [158, 161],
    index: 3,
  },
  {
    id: 65,
    rub_number: 65,
    hizb_number: 17,
    juz_number: 9,
    quarter: 1,
    ayah_number: 1042,
    start_surah_number: 7,
    start_ayah_number: 88,
    end_surah_number: 7,
    end_ayah_number: 116,
    ayah_count: 29,
    uthmani_pages_count: 3,
    uthmani_pages_range: [162, 164],
    index: 0,
  },
  {
    id: 66,
    rub_number: 66,
    hizb_number: 17,
    juz_number: 9,
    quarter: 2,
    ayah_number: 1071,
    start_surah_number: 7,
    start_ayah_number: 117,
    end_surah_number: 7,
    end_ayah_number: 141,
    ayah_count: 25,
    uthmani_pages_count: 4,
    uthmani_pages_range: [164, 167],
    index: 1,
  },
  {
    id: 67,
    rub_number: 67,
    hizb_number: 17,
    juz_number: 9,
    quarter: 3,
    ayah_number: 1096,
    start_surah_number: 7,
    start_ayah_number: 142,
    end_surah_number: 7,
    end_ayah_number: 155,
    ayah_count: 14,
    uthmani_pages_count: 3,
    uthmani_pages_range: [167, 169],
    index: 2,
  },
  {
    id: 68,
    rub_number: 68,
    hizb_number: 17,
    juz_number: 9,
    quarter: 4,
    ayah_number: 1110,
    start_surah_number: 7,
    start_ayah_number: 156,
    end_surah_number: 7,
    end_ayah_number: 170,
    ayah_count: 15,
    uthmani_pages_count: 3,
    uthmani_pages_range: [170, 172],
    index: 3,
  },
  {
    id: 69,
    rub_number: 69,
    hizb_number: 18,
    juz_number: 9,
    quarter: 1,
    ayah_number: 1125,
    start_surah_number: 7,
    start_ayah_number: 171,
    end_surah_number: 7,
    end_ayah_number: 188,
    ayah_count: 18,
    uthmani_pages_count: 3,
    uthmani_pages_range: [173, 175],
    index: 0,
  },
  {
    id: 70,
    rub_number: 70,
    hizb_number: 18,
    juz_number: 9,
    quarter: 2,
    ayah_number: 1143,
    start_surah_number: 7,
    start_ayah_number: 189,
    end_surah_number: 7,
    end_ayah_number: 206,
    ayah_count: 18,
    uthmani_pages_count: 2,
    uthmani_pages_range: [175, 176],
    index: 1,
  },
  {
    id: 71,
    rub_number: 71,
    hizb_number: 18,
    juz_number: 9,
    quarter: 3,
    ayah_number: 1161,
    start_surah_number: 8,
    start_ayah_number: 1,
    end_surah_number: 8,
    end_ayah_number: 21,
    ayah_count: 21,
    uthmani_pages_count: 3,
    uthmani_pages_range: [177, 179],
    index: 2,
  },
  {
    id: 72,
    rub_number: 72,
    hizb_number: 18,
    juz_number: 9,
    quarter: 4,
    ayah_number: 1182,
    start_surah_number: 8,
    start_ayah_number: 22,
    end_surah_number: 8,
    end_ayah_number: 40,
    ayah_count: 19,
    uthmani_pages_count: 3,
    uthmani_pages_range: [179, 181],
    index: 3,
  },
  {
    id: 73,
    rub_number: 73,
    hizb_number: 19,
    juz_number: 10,
    quarter: 1,
    ayah_number: 1201,
    start_surah_number: 8,
    start_ayah_number: 41,
    end_surah_number: 8,
    end_ayah_number: 60,
    ayah_count: 20,
    uthmani_pages_count: 3,
    uthmani_pages_range: [182, 184],
    index: 0,
  },
  {
    id: 74,
    rub_number: 74,
    hizb_number: 19,
    juz_number: 10,
    quarter: 2,
    ayah_number: 1221,
    start_surah_number: 8,
    start_ayah_number: 61,
    end_surah_number: 8,
    end_ayah_number: 75,
    ayah_count: 15,
    uthmani_pages_count: 3,
    uthmani_pages_range: [184, 186],
    index: 1,
  },
  {
    id: 75,
    rub_number: 75,
    hizb_number: 19,
    juz_number: 10,
    quarter: 3,
    ayah_number: 1236,
    start_surah_number: 9,
    start_ayah_number: 1,
    end_surah_number: 9,
    end_ayah_number: 18,
    ayah_count: 18,
    uthmani_pages_count: 3,
    uthmani_pages_range: [187, 189],
    index: 2,
  },
  {
    id: 76,
    rub_number: 76,
    hizb_number: 19,
    juz_number: 10,
    quarter: 4,
    ayah_number: 1254,
    start_surah_number: 9,
    start_ayah_number: 19,
    end_surah_number: 9,
    end_ayah_number: 33,
    ayah_count: 15,
    uthmani_pages_count: 4,
    uthmani_pages_range: [189, 192],
    index: 3,
  },
  {
    id: 77,
    rub_number: 77,
    hizb_number: 20,
    juz_number: 10,
    quarter: 1,
    ayah_number: 1269,
    start_surah_number: 9,
    start_ayah_number: 34,
    end_surah_number: 9,
    end_ayah_number: 45,
    ayah_count: 12,
    uthmani_pages_count: 3,
    uthmani_pages_range: [192, 194],
    index: 0,
  },
  {
    id: 78,
    rub_number: 78,
    hizb_number: 20,
    juz_number: 10,
    quarter: 2,
    ayah_number: 1281,
    start_surah_number: 9,
    start_ayah_number: 46,
    end_surah_number: 9,
    end_ayah_number: 59,
    ayah_count: 14,
    uthmani_pages_count: 3,
    uthmani_pages_range: [194, 196],
    index: 1,
  },
  {
    id: 79,
    rub_number: 79,
    hizb_number: 20,
    juz_number: 10,
    quarter: 3,
    ayah_number: 1295,
    start_surah_number: 9,
    start_ayah_number: 60,
    end_surah_number: 9,
    end_ayah_number: 74,
    ayah_count: 15,
    uthmani_pages_count: 4,
    uthmani_pages_range: [196, 199],
    index: 2,
  },
  {
    id: 80,
    rub_number: 80,
    hizb_number: 20,
    juz_number: 10,
    quarter: 4,
    ayah_number: 1310,
    start_surah_number: 9,
    start_ayah_number: 75,
    end_surah_number: 9,
    end_ayah_number: 92,
    ayah_count: 18,
    uthmani_pages_count: 3,
    uthmani_pages_range: [199, 201],
    index: 3,
  },
  {
    id: 81,
    rub_number: 81,
    hizb_number: 21,
    juz_number: 11,
    quarter_number: 1,
    ayah_number: 1328,
    start_surah_number: 9,
    start_ayah_number: 93,
    end_surah_number: 9,
    end_ayah_number: 110,
    ayah_count: 18,
    uthmani_pages_count: 4,
    uthmani_pages_range: [201, 204],
    index: 0,
  },
  {
    id: 82,
    rub_number: 82,
    hizb_number: 21,
    juz_number: 11,
    quarter: 2,
    ayah_number: 1346,
    start_surah_number: 9,
    start_ayah_number: 111,
    end_surah_number: 9,
    end_ayah_number: 121,
    ayah_count: 18,
    uthmani_pages_count: 3,
    uthmani_pages_range: [204, 206],
    index: 1,
  },
  {
    id: 83,
    rub_number: 83,
    hizb_number: 21,
    juz_number: 11,
    quarter: 3,
    ayah_number: 1357,
    start_surah_number: 9,
    start_ayah_number: 122,
    end_surah_number: 10,
    end_ayah_number: 10,
    ayah_count: 15,
    uthmani_pages_count: 4,
    uthmani_pages_range: [206, 209],
    index: 2,
  },
  {
    id: 84,
    rub_number: 84,
    hizb_number: 21,
    juz_number: 11,
    quarter: 4,
    ayah_number: 1375,
    start_surah_number: 10,
    start_ayah_number: 11,
    end_surah_number: 10,
    end_ayah_number: 25,
    ayah_count: 15,
    uthmani_pages_count: 3,
    uthmani_pages_range: [209, 211],
    index: 3,
  },
  {
    id: 85,
    rub_number: 85,
    hizb_number: 22,
    juz_number: 11,
    quarter: 1,
    ayah_number: 1390,
    start_surah_number: 10,
    start_ayah_number: 26,
    end_surah_number: 10,
    end_ayah_number: 52,
    ayah_count: 27,
    uthmani_pages_count: 3,
    uthmani_pages_range: [212, 214],
    index: 0,
  },
  {
    id: 86,
    rub_number: 86,
    hizb_number: 22,
    juz_number: 11,
    quarter: 2,
    ayah_number: 1417,
    start_surah_number: 10,
    start_ayah_number: 53,
    end_surah_number: 10,
    end_ayah_number: 70,
    ayah_count: 18,
    uthmani_pages_count: 3,
    uthmani_pages_range: [214, 216],
    index: 1,
  },
  {
    id: 87,
    rub_number: 87,
    hizb_number: 22,
    juz_number: 11,
    quarter: 3,
    ayah_number: 1435,
    start_surah_number: 10,
    start_ayah_number: 71,
    end_surah_number: 10,
    end_ayah_number: 89,
    ayah_count: 19,
    uthmani_pages_count: 3,
    uthmani_pages_range: [217, 219],
    index: 2,
  },
  {
    id: 88,
    rub_number: 88,
    hizb_number: 22,
    juz_number: 11,
    quarter: 4,
    ayah_number: 1454,
    start_surah_number: 10,
    start_ayah_number: 90,
    end_surah_number: 11,
    end_ayah_number: 5,
    ayah_count: 25,
    uthmani_pages_count: 3,
    uthmani_pages_range: [219, 221],
    index: 3,
  },
  {
    id: 89,
    rub_number: 89,
    hizb_number: 23,
    juz_number: 12,
    quarter_number: 1,
    ayah_number: 1479,
    start_surah_number: 11,
    start_ayah_number: 6,
    end_surah_number: 11,
    end_ayah_number: 23,
    ayah_count: 18,
    uthmani_pages_count: 3,
    uthmani_pages_range: [222, 224],
    index: 0,
  },
  {
    id: 90,
    rub_number: 90,
    hizb_number: 23,
    juz_number: 12,
    quarter: 2,
    ayah_number: 1497,
    start_surah_number: 11,
    start_ayah_number: 24,
    end_surah_number: 11,
    end_ayah_number: 40,
    ayah_count: 17,
    uthmani_pages_count: 3,
    uthmani_pages_range: [224, 226],
    index: 1,
  },
  {
    id: 91,
    rub_number: 91,
    hizb_number: 23,
    juz_number: 12,
    quarter: 3,
    ayah_number: 1514,
    start_surah_number: 11,
    start_ayah_number: 41,
    end_surah_number: 11,
    end_ayah_number: 60,
    ayah_count: 20,
    uthmani_pages_count: 3,
    uthmani_pages_range: [226, 228],
    index: 2,
  },
  {
    id: 92,
    rub_number: 92,
    hizb_number: 23,
    juz_number: 12,
    quarter: 4,
    ayah_number: 1534,
    start_surah_number: 11,
    start_ayah_number: 61,
    end_surah_number: 11,
    end_ayah_number: 83,
    ayah_count: 23,
    uthmani_pages_count: 4,
    uthmani_pages_range: [228, 231],
    index: 3,
  },
  {
    id: 93,
    rub_number: 93,
    hizb_number: 24,
    juz_number: 12,
    quarter: 1,
    ayah_number: 1557,
    start_surah_number: 11,
    start_ayah_number: 84,
    end_surah_number: 11,
    end_ayah_number: 107,
    ayah_count: 24,
    uthmani_pages_count: 3,
    uthmani_pages_range: [231, 233],
    index: 0,
  },
  {
    id: 94,
    rub_number: 94,
    hizb_number: 24,
    juz_number: 12,
    quarter: 2,
    ayah_number: 1581,
    start_surah_number: 11,
    start_ayah_number: 108,
    end_surah_number: 12,
    end_ayah_number: 6,
    ayah_count: 22,
    uthmani_pages_count: 4,
    uthmani_pages_range: [233, 236],
    index: 1,
  },
  {
    id: 95,
    rub_number: 95,
    hizb_number: 24,
    juz_number: 12,
    quarter: 3,
    ayah_number: 1603,
    start_surah_number: 12,
    start_ayah_number: 7,
    end_surah_number: 12,
    end_ayah_number: 29,
    ayah_count: 23,
    uthmani_pages_count: 3,
    uthmani_pages_range: [236, 238],
    index: 2,
  },
  {
    id: 96,
    rub_number: 96,
    hizb_number: 24,
    juz_number: 12,
    quarter: 4,
    ayah_number: 1626,
    start_surah_number: 12,
    start_ayah_number: 30,
    end_surah_number: 12,
    end_ayah_number: 52,
    ayah_count: 23,
    uthmani_pages_count: 4,
    uthmani_pages_range: [238, 241],
    index: 3,
  },
  {
    id: 97,
    rub_number: 97,
    hizb_number: 25,
    juz_number: 13,
    quarter_number: 1,
    ayah_number: 1649,
    start_surah_number: 12,
    start_ayah_number: 53,
    end_surah_number: 12,
    end_ayah_number: 76,
    ayah_count: 24,
    uthmani_pages_count: 3,
    uthmani_pages_range: [242, 244],
    index: 0,
  },
  {
    id: 98,
    rub_number: 98,
    hizb_number: 25,
    juz_number: 13,
    quarter: 2,
    ayah_number: 1673,
    start_surah_number: 12,
    start_ayah_number: 77,
    end_surah_number: 12,
    end_ayah_number: 100,
    ayah_count: 24,
    uthmani_pages_count: 4,
    uthmani_pages_range: [244, 247],
    index: 1,
  },
  {
    id: 99,
    rub_number: 99,
    hizb_number: 25,
    juz_number: 13,
    quarter: 3,
    ayah_number: 1697,
    start_surah_number: 12,
    start_ayah_number: 101,
    end_surah_number: 13,
    end_ayah_number: 4,
    ayah_count: 15,
    uthmani_pages_count: 3,
    uthmani_pages_range: [247, 249],
    index: 2,
  },
  {
    id: 100,
    rub_number: 100,
    hizb_number: 25,
    juz_number: 13,
    quarter: 4,
    ayah_number: 1712,
    start_surah_number: 13,
    start_ayah_number: 5,
    end_surah_number: 13,
    end_ayah_number: 18,
    ayah_count: 14,
    uthmani_pages_count: 3,
    uthmani_pages_range: [249, 251],
    index: 3,
  },
  {
    id: 101,
    rub_number: 101,
    hizb_number: 26,
    juz_number: 13,
    quarter: 1,
    ayah_number: 1726,
    start_surah_number: 13,
    start_ayah_number: 19,
    end_surah_number: 13,
    end_ayah_number: 34,
    ayah_count: 16,
    uthmani_pages_count: 2,
    uthmani_pages_range: [252, 253],
    index: 0,
  },
  {
    id: 102,
    rub_number: 102,
    hizb_number: 26,
    juz_number: 13,
    quarter: 2,
    ayah_number: 1742,
    start_surah_number: 13,
    start_ayah_number: 35,
    end_surah_number: 14,
    end_ayah_number: 9,
    ayah_count: 18,
    uthmani_pages_count: 3,
    uthmani_pages_range: [254, 256],
    index: 1,
  },
  {
    id: 103,
    rub_number: 103,
    hizb_number: 26,
    juz_number: 13,
    quarter: 3,
    ayah_number: 1760,
    start_surah_number: 14,
    start_ayah_number: 10,
    end_surah_number: 14,
    end_ayah_number: 27,
    ayah_count: 18,
    uthmani_pages_count: 4,
    uthmani_pages_range: [256, 259],
    index: 2,
  },
  {
    id: 104,
    rub_number: 104,
    hizb_number: 26,
    juz_number: 13,
    quarter: 4,
    ayah_number: 1778,
    start_surah_number: 14,
    start_ayah_number: 28,
    end_surah_number: 14,
    end_ayah_number: 52,
    ayah_count: 25,
    uthmani_pages_count: 3,
    uthmani_pages_range: [259, 261],
    index: 3,
  },
  {
    id: 105,
    rub_number: 105,
    hizb_number: 27,
    juz_number: 14,
    quarter_number: 1,
    ayah_number: 1803,
    start_surah_number: 15,
    start_ayah_number: 1,
    end_surah_number: 15,
    end_ayah_number: 48,
    ayah_count: 48,
    uthmani_pages_count: 3,
    uthmani_pages_range: [262, 264],
    index: 0,
  },
  {
    id: 106,
    rub_number: 106,
    hizb_number: 27,
    juz_number: 14,
    quarter: 2,
    ayah_number: 1851,
    start_surah_number: 15,
    start_ayah_number: 49,
    end_surah_number: 15,
    end_ayah_number: 99,
    ayah_count: 51,
    uthmani_pages_count: 4,
    uthmani_pages_range: [264, 267],
    index: 1,
  },
  {
    id: 107,
    rub_number: 107,
    hizb_number: 27,
    juz_number: 14,
    quarter: 3,
    ayah_number: 1902,
    start_surah_number: 16,
    start_ayah_number: 1,
    end_surah_number: 16,
    end_ayah_number: 29,
    ayah_count: 29,
    uthmani_pages_count: 4,
    uthmani_pages_range: [267, 270],
    index: 2,
  },
  {
    id: 108,
    rub_number: 108,
    hizb_number: 27,
    juz_number: 14,
    quarter: 4,
    ayah_number: 1931,
    start_surah_number: 16,
    start_ayah_number: 30,
    end_surah_number: 16,
    end_ayah_number: 50,
    ayah_count: 21,
    uthmani_pages_count: 3,
    uthmani_pages_range: [270, 272],
    index: 3,
  },
  {
    id: 109,
    rub_number: 109,
    hizb_number: 28,
    juz_number: 14,
    quarter: 1,
    ayah_number: 1952,
    start_surah_number: 16,
    start_ayah_number: 51,
    end_surah_number: 16,
    end_ayah_number: 74,
    ayah_count: 24,
    uthmani_pages_count: 4,
    uthmani_pages_range: [272, 275],
    index: 0,
  },
  {
    id: 110,
    rub_number: 110,
    hizb_number: 28,
    juz_number: 14,
    quarter: 2,
    ayah_number: 1976,
    start_surah_number: 16,
    start_ayah_number: 75,
    end_surah_number: 16,
    end_ayah_number: 89,
    ayah_count: 15,
    uthmani_pages_count: 3,
    uthmani_pages_range: [275, 277],
    index: 1,
  },
  {
    id: 111,
    rub_number: 111,
    hizb_number: 28,
    juz_number: 14,
    quarter: 3,
    ayah_number: 1991,
    start_surah_number: 16,
    start_ayah_number: 90,
    end_surah_number: 16,
    end_ayah_number: 110,
    ayah_count: 21,
    uthmani_pages_count: 3,
    uthmani_pages_range: [277, 279],
    index: 2,
  },
  {
    id: 112,
    rub_number: 112,
    hizb_number: 28,
    juz_number: 14,
    quarter: 4,
    ayah_number: 2012,
    start_surah_number: 16,
    start_ayah_number: 111,
    end_surah_number: 16,
    end_ayah_number: 128,
    ayah_count: 18,
    uthmani_pages_count: 2,
    uthmani_pages_range: [280, 281],
    index: 3,
  },
  {
    id: 113,
    rub_number: 113,
    hizb_number: 29,
    juz_number: 15,
    quarter_number: 1,
    ayah_number: 2030,
    start_surah_number: 17,
    start_ayah_number: 1,
    end_surah_number: 17,
    end_ayah_number: 22,
    ayah_count: 22,
    uthmani_pages_count: 3,
    uthmani_pages_range: [282, 284],
    index: 0,
  },
  {
    id: 114,
    rub_number: 114,
    hizb_number: 29,
    juz_number: 15,
    quarter: 2,
    ayah_number: 2052,
    start_surah_number: 17,
    start_ayah_number: 23,
    end_surah_number: 17,
    end_ayah_number: 49,
    ayah_count: 27,
    uthmani_pages_count: 3,
    uthmani_pages_range: [284, 286],
    index: 1,
  },
  {
    id: 115,
    rub_number: 115,
    hizb_number: 29,
    juz_number: 15,
    quarter: 3,
    ayah_number: 2079,
    start_surah_number: 17,
    start_ayah_number: 50,
    end_surah_number: 17,
    end_ayah_number: 69,
    ayah_count: 20,
    uthmani_pages_count: 3,
    uthmani_pages_range: [287, 289],
    index: 2,
  },
  {
    id: 116,
    rub_number: 116,
    hizb_number: 29,
    juz_number: 15,
    quarter: 4,
    ayah_number: 2099,
    start_surah_number: 17,
    start_ayah_number: 70,
    end_surah_number: 17,
    end_ayah_number: 98,
    ayah_count: 29,
    uthmani_pages_count: 4,
    uthmani_pages_range: [289, 292],
    index: 3,
  },
  {
    id: 117,
    rub_number: 117,
    hizb_number: 30,
    juz_number: 15,
    quarter: 1,
    ayah_number: 2128,
    start_surah_number: 17,
    start_ayah_number: 99,
    end_surah_number: 18,
    end_ayah_number: 16,
    ayah_count: 29,
    uthmani_pages_count: 4,
    uthmani_pages_range: [292, 295],
    index: 0,
  },
  {
    id: 118,
    rub_number: 118,
    hizb_number: 30,
    juz_number: 15,
    quarter: 2,
    ayah_number: 2157,
    start_surah_number: 18,
    start_ayah_number: 17,
    end_surah_number: 18,
    end_ayah_number: 31,
    ayah_count: 15,
    uthmani_pages_count: 3,
    uthmani_pages_range: [295, 297],
    index: 1,
  },
  {
    id: 119,
    rub_number: 119,
    hizb_number: 30,
    juz_number: 15,
    quarter: 3,
    ayah_number: 2172,
    start_surah_number: 18,
    start_ayah_number: 32,
    end_surah_number: 18,
    end_ayah_number: 50,
    ayah_count: 19,
    uthmani_pages_count: 3,
    uthmani_pages_range: [297, 299],
    index: 2,
  },
  {
    id: 120,
    rub_number: 120,
    hizb_number: 30,
    juz_number: 15,
    quarter: 4,
    ayah_number: 2191,
    start_surah_number: 18,
    start_ayah_number: 51,
    end_surah_number: 18,
    end_ayah_number: 75,
    ayah_count: 24,
    uthmani_pages_count: 3,
    uthmani_pages_range: [299, 301],
    index: 3,
  },
  {
    id: 121,
    rub_number: 121,
    hizb_number: 31,
    juz_number: 16,
    quarter_number: 1,
    ayah_number: 2215,
    start_surah_number: 18,
    start_ayah_number: 75,
    end_surah_number: 18,
    end_ayah_number: 98,
    ayah_count: 24,
    uthmani_pages_count: 3,
    uthmani_pages_range: [302, 304],
    index: 0,
  },
  {
    id: 122,
    rub_number: 122,
    hizb_number: 31,
    juz_number: 16,
    quarter: 2,
    ayah_number: 2239,
    start_surah_number: 18,
    start_ayah_number: 99,
    end_surah_number: 19,
    end_ayah_number: 21,
    ayah_count: 33,
    uthmani_pages_count: 3,
    uthmani_pages_range: [304, 306],
    index: 1,
  },
  {
    id: 123,
    rub_number: 123,
    hizb_number: 31,
    juz_number: 16,
    quarter: 3,
    ayah_number: 2272,
    start_surah_number: 19,
    start_ayah_number: 22,
    end_surah_number: 19,
    end_ayah_number: 58,
    ayah_count: 37,
    uthmani_pages_count: 4,
    uthmani_pages_range: [306, 309],
    index: 2,
  },
  {
    id: 124,
    rub_number: 124,
    hizb_number: 31,
    juz_number: 16,
    quarter: 4,
    ayah_number: 2309,
    start_surah_number: 19,
    start_ayah_number: 59,
    end_surah_number: 19,
    end_ayah_number: 98,
    ayah_count: 40,
    uthmani_pages_count: 5,
    uthmani_pages_range: [309, 312],
    index: 3,
  },
  {
    id: 125,
    rub_number: 125,
    hizb_number: 32,
    juz_number: 16,
    quarter: 1,
    ayah_number: 2349,
    start_surah_number: 20,
    start_ayah_number: 1,
    end_surah_number: 20,
    end_ayah_number: 54,
    ayah_count: 54,
    uthmani_pages_count: 4,
    uthmani_pages_range: [312, 315],
    index: 0,
  },
  {
    id: 126,
    rub_number: 126,
    hizb_number: 32,
    juz_number: 16,
    quarter: 2,
    ayah_number: 2403,
    start_surah_number: 20,
    start_ayah_number: 55,
    end_surah_number: 20,
    end_ayah_number: 82,
    ayah_count: 28,
    uthmani_pages_count: 3,
    uthmani_pages_range: [315, 317],
    index: 1,
  },
  {
    id: 127,
    rub_number: 127,
    hizb_number: 32,
    juz_number: 16,
    quarter: 3,
    ayah_number: 2431,
    start_surah_number: 20,
    start_ayah_number: 83,
    end_surah_number: 20,
    end_ayah_number: 110,
    ayah_count: 28,
    uthmani_pages_count: 3,
    uthmani_pages_range: [317, 319],
    index: 2,
  },
  {
    id: 128,
    rub_number: 128,
    hizb_number: 32,
    juz_number: 16,
    quarter: 4,
    ayah_number: 2459,
    start_surah_number: 20,
    start_ayah_number: 111,
    end_surah_number: 20,
    end_ayah_number: 135,
    ayah_count: 25,
    uthmani_pages_count: 3,
    uthmani_pages_range: [319, 321],
    index: 3,
  },
  {
    id: 129,
    rub_number: 129,
    hizb_number: 33,
    juz_number: 17,
    quarter_number: 1,
    ayah_number: 2484,
    start_surah_number: 21,
    start_ayah_number: 1,
    end_surah_number: 21,
    end_ayah_number: 28,
    ayah_count: 28,
    uthmani_pages_count: 3,
    uthmani_pages_range: [322, 324],
    index: 0,
  },
  {
    id: 130,
    rub_number: 130,
    hizb_number: 33,
    juz_number: 17,
    quarter: 2,
    ayah_number: 2512,
    start_surah_number: 21,
    start_ayah_number: 29,
    end_surah_number: 21,
    end_ayah_number: 50,
    ayah_count: 22,
    uthmani_pages_count: 3,
    uthmani_pages_range: [324, 326],
    index: 1,
  },
  {
    id: 131,
    rub_number: 131,
    hizb_number: 33,
    juz_number: 17,
    quarter: 3,
    ayah_number: 2534,
    start_surah_number: 21,
    start_ayah_number: 51,
    end_surah_number: 21,
    end_ayah_number: 82,
    ayah_count: 32,
    uthmani_pages_count: 4,
    uthmani_pages_range: [326, 329],
    index: 2,
  },
  {
    id: 132,
    rub_number: 132,
    hizb_number: 33,
    juz_number: 17,
    quarter: 4,
    ayah_number: 2566,
    start_surah_number: 21,
    start_ayah_number: 83,
    end_surah_number: 21,
    end_ayah_number: 112,
    ayah_count: 30,
    uthmani_pages_count: 3,
    uthmani_pages_range: [329, 331],
    index: 3,
  },
  {
    id: 133,
    rub_number: 133,
    hizb_number: 34,
    juz_number: 17,
    quarter: 1,
    ayah_number: 2596,
    start_surah_number: 22,
    start_ayah_number: 1,
    end_surah_number: 22,
    end_ayah_number: 18,
    ayah_count: 18,
    uthmani_pages_count: 4,
    uthmani_pages_range: [332, 334],
    index: 0,
  },
  {
    id: 134,
    rub_number: 134,
    hizb_number: 34,
    juz_number: 17,
    quarter: 2,
    ayah_number: 2614,
    start_surah_number: 22,
    start_ayah_number: 19,
    end_surah_number: 22,
    end_ayah_number: 37,
    ayah_count: 19,
    uthmani_pages_count: 3,
    uthmani_pages_range: [334, 336],
    index: 1,
  },
  {
    id: 135,
    rub_number: 135,
    hizb_number: 34,
    juz_number: 17,
    quarter: 3,
    ayah_number: 2633,
    start_surah_number: 22,
    start_ayah_number: 38,
    end_surah_number: 22,
    end_ayah_number: 59,
    ayah_count: 22,
    uthmani_pages_count: 4,
    uthmani_pages_range: [336, 339],
    index: 2,
  },
  {
    id: 136,
    rub_number: 136,
    hizb_number: 34,
    juz_number: 17,
    quarter: 4,
    ayah_number: 2655,
    start_surah_number: 22,
    start_ayah_number: 60,
    end_surah_number: 22,
    end_ayah_number: 78,
    ayah_count: 19,
    uthmani_pages_count: 3,
    uthmani_pages_range: [339, 341],
    index: 3,
  },
  {
    id: 137,
    rub_number: 137,
    hizb_number: 35,
    juz_number: 18,
    ayah_number: 2674,
    quarter_number: 23,
    start_surah_number: 1,
    start_ayah_number: 23,
    end_surah_number: 35,
    end_ayah_number: 25,
    ayah_count: 28,
    uthmani_pages_count: 3,
    uthmani_pages_range: [342, 344],
    index: 0,
  },
  {
    id: 138,
    rub_number: 138,
    hizb_number: 35,
    juz_number: 18,
    quarter: 2,
    ayah_number: 2709,
    start_surah_number: 23,
    start_ayah_number: 36,
    end_surah_number: 23,
    end_ayah_number: 74,
    ayah_count: 22,
    uthmani_pages_count: 3,
    uthmani_pages_range: [344, 346],
    index: 1,
  },
  {
    id: 139,
    rub_number: 139,
    hizb_number: 35,
    juz_number: 18,
    quarter: 3,
    ayah_number: 2748,
    start_surah_number: 23,
    start_ayah_number: 75,
    end_surah_number: 23,
    end_ayah_number: 118,
    ayah_count: 32,
    uthmani_pages_count: 3,
    uthmani_pages_range: [347, 349],
    index: 2,
  },
  {
    id: 140,
    rub_number: 140,
    hizb_number: 35,
    juz_number: 18,
    quarter: 4,
    ayah_number: 2792,
    start_surah_number: 24,
    start_ayah_number: 1,
    end_surah_number: 24,
    end_ayah_number: 20,
    ayah_count: 30,
    uthmani_pages_count: 2,
    uthmani_pages_range: [350, 351],
    index: 3,
  },
  {
    id: 141,
    rub_number: 141,
    hizb_number: 36,
    juz_number: 18,
    quarter: 1,
    ayah_number: 2812,
    start_surah_number: 24,
    start_ayah_number: 21,
    end_surah_number: 24,
    end_ayah_number: 34,
    ayah_count: 18,
    uthmani_pages_count: 3,
    uthmani_pages_range: [352, 354],
    index: 0,
  },
  {
    id: 142,
    rub_number: 142,
    hizb_number: 36,
    juz_number: 18,
    quarter: 2,
    ayah_number: 2826,
    start_surah_number: 24,
    start_ayah_number: 35,
    end_surah_number: 24,
    end_ayah_number: 52,
    ayah_count: 19,
    uthmani_pages_count: 3,
    uthmani_pages_range: [354, 356],
    index: 1,
  },
  {
    id: 143,
    rub_number: 143,
    hizb_number: 36,
    juz_number: 18,
    quarter: 3,
    ayah_number: 2844,
    start_surah_number: 24,
    start_ayah_number: 53,
    end_surah_number: 24,
    end_ayah_number: 64,
    ayah_count: 22,
    uthmani_pages_count: 4,
    uthmani_pages_range: [356, 359],
    index: 2,
  },
  {
    id: 144,
    rub_number: 8,
    hizb_number: 36,
    juz_number: 18,
    quarter: 4,
    ayah_number: 2856,
    start_surah_number: 25,
    start_ayah_number: 1,
    end_surah_number: 25,
    end_ayah_number: 20,
    ayah_count: 19,
    uthmani_pages_count: 3,
    uthmani_pages_range: [359, 361],
    index: 3,
  },
  {
    id: 145,
    rub_number: 145,
    hizb_number: 37,
    juz_number: 19,
    quarter: 1,
    ayah_number: 2876,
    start_surah_number: 25,
    start_ayah_number: 21,
    end_surah_number: 25,
    end_ayah_number: 52,
    ayah_count: 32,
    uthmani_pages_count: 3,
    uthmani_pages_range: [362, 364],
    index: 0,
  },
  {
    id: 146,
    rub_number: 146,
    hizb_number: 37,
    juz_number: 19,
    quarter: 2,
    ayah_number: 2908,
    start_surah_number: 25,
    start_ayah_number: 53,
    end_surah_number: 25,
    end_ayah_number: 77,
    ayah_count: 25,
    uthmani_pages_count: 3,
    uthmani_pages_range: [364, 366],
    index: 1,
  },
  {
    id: 147,
    rub_number: 147,
    hizb_number: 37,
    juz_number: 19,
    quarter: 3,
    ayah_number: 2933,
    start_surah_number: 26,
    start_ayah_number: 1,
    end_surah_number: 26,
    end_ayah_number: 51,
    ayah_count: 51,
    uthmani_pages_count: 3,
    uthmani_pages_range: [367, 369],
    index: 2,
  },
  {
    id: 148,
    rub_number: 148,
    hizb_number: 37,
    juz_number: 19,
    quarter: 4,
    ayah_number: 2984,
    start_surah_number: 26,
    start_ayah_number: 52,
    end_surah_number: 26,
    end_ayah_number: 110,
    ayah_count: 59,
    uthmani_pages_count: 3,
    uthmani_pages_range: [369, 371],
    index: 3,
  },
  {
    id: 149,
    rub_number: 149,
    hizb_number: 38,
    juz_number: 19,
    quarter: 1,
    ayah_number: 3043,
    start_surah_number: 26,
    start_ayah_number: 111,
    end_surah_number: 26,
    end_ayah_number: 180,
    ayah_count: 70,
    uthmani_pages_count: 4,
    uthmani_pages_range: [371, 374],
    index: 0,
  },
  {
    id: 150,
    rub_number: 150,
    hizb_number: 38,
    juz_number: 19,
    quarter: 2,
    ayah_number: 3113,
    start_surah_number: 26,
    start_ayah_number: 181,
    end_surah_number: 26,
    end_ayah_number: 227,
    ayah_count: 47,
    uthmani_pages_count: 3,
    uthmani_pages_range: [374, 376],
    index: 1,
  },
  {
    id: 151,
    rub_number: 151,
    hizb_number: 38,
    juz_number: 19,
    quarter: 3,
    ayah_number: 3160,
    start_surah_number: 27,
    start_ayah_number: 1,
    end_surah_number: 27,
    end_ayah_number: 26,
    ayah_count: 26,
    uthmani_pages_count: 3,
    uthmani_pages_range: [377, 379],
    index: 2,
  },
  {
    id: 152,
    rub_number: 152,
    hizb_number: 38,
    juz_number: 19,
    quarter: 4,
    ayah_number: 3186,
    start_surah_number: 27,
    start_ayah_number: 27,
    end_surah_number: 27,
    end_ayah_number: 55,
    ayah_count: 29,
    uthmani_pages_count: 3,
    uthmani_pages_range: [379, 381],
    index: 3,
  },
  {
    id: 153,
    rub_number: 153,
    hizb_number: 39,
    juz_number: 20,
    quarter: 1,
    ayah_number: 3215,
    start_surah_number: 27,
    start_ayah_number: 56,
    end_surah_number: 27,
    end_ayah_number: 81,
    ayah_count: 26,
    uthmani_pages_count: 3,
    uthmani_pages_range: [382, 384],
    index: 0,
  },
  {
    id: 154,
    rub_number: 154,
    hizb_number: 39,
    juz_number: 20,
    quarter: 2,
    ayah_number: 3241,
    start_surah_number: 27,
    start_ayah_number: 82,
    end_surah_number: 28,
    end_ayah_number: 11,
    ayah_count: 23,
    uthmani_pages_count: 3,
    uthmani_pages_range: [384, 386],
    index: 1,
  },
  {
    id: 155,
    rub_number: 155,
    hizb_number: 39,
    juz_number: 20,
    quarter: 3,
    ayah_number: 3264,
    start_surah_number: 28,
    start_ayah_number: 12,
    end_surah_number: 28,
    end_ayah_number: 28,
    ayah_count: 23,
    uthmani_pages_count: 3,
    uthmani_pages_range: [386, 388],
    index: 2,
  },
  {
    id: 156,
    rub_number: 156,
    hizb_number: 39,
    juz_number: 20,
    quarter: 4,
    ayah_number: 3281,
    start_surah_number: 28,
    start_ayah_number: 29,
    end_surah_number: 28,
    end_ayah_number: 50,
    ayah_count: 22,
    uthmani_pages_count: 3,
    uthmani_pages_range: [389, 391],
    index: 3,
  },
  {
    id: 157,
    rub_number: 157,
    hizb_number: 40,
    juz_number: 20,
    quarter: 1,
    ayah_number: 3303,
    start_surah_number: 28,
    start_ayah_number: 51,
    end_surah_number: 28,
    end_ayah_number: 75,
    ayah_count: 25,
    uthmani_pages_count: 3,
    uthmani_pages_range: [392, 394],
    index: 0,
  },
  {
    id: 158,
    rub_number: 158,
    hizb_number: 40,
    juz_number: 20,
    quarter: 2,
    ayah_number: 3328,
    start_surah_number: 28,
    start_ayah_number: 76,
    end_surah_number: 28,
    end_ayah_number: 88,
    ayah_count: 13,
    uthmani_pages_count: 3,
    uthmani_pages_range: [394, 396],
    index: 1,
  },
  {
    id: 159,
    rub_number: 159,
    hizb_number: 40,
    juz_number: 20,
    quarter: 3,
    ayah_number: 3341,
    start_surah_number: 29,
    start_ayah_number: 1,
    end_surah_number: 29,
    end_ayah_number: 25,
    ayah_count: 25,
    uthmani_pages_count: 4,
    uthmani_pages_range: [396, 399],
    index: 2,
  },
  {
    id: 160,
    rub_number: 160,
    hizb_number: 40,
    juz_number: 20,
    quarter: 4,
    ayah_number: 3366,
    start_surah_number: 29,
    start_ayah_number: 26,
    end_surah_number: 29,
    end_ayah_number: 45,
    ayah_count: 20,
    uthmani_pages_count: 3,
    uthmani_pages_range: [399, 401],
    index: 3,
  },
  {
    id: 161,
    rub_number: 161,
    hizb_number: 41,
    juz_number: 21,
    quarter: 1,
    ayah_number: 3386,
    start_surah_number: 29,
    start_ayah_number: 46,
    end_surah_number: 29,
    end_ayah_number: 69,
    ayah_count: 24,
    uthmani_pages_count: 3,
    uthmani_pages_range: [402, 404],
    index: 0,
  },
  {
    id: 162,
    rub_number: 162,
    hizb_number: 41,
    juz_number: 21,
    quarter: 2,
    ayah_number: 3410,
    start_surah_number: 30,
    start_ayah_number: 1,
    end_surah_number: 30,
    end_ayah_number: 30,
    ayah_count: 30,
    uthmani_pages_count: 4,
    uthmani_pages_range: [404, 407],
    index: 1,
  },
  {
    id: 163,
    rub_number: 163,
    hizb_number: 41,
    juz_number: 21,
    quarter: 3,
    ayah_number: 3440,
    start_surah_number: 30,
    start_ayah_number: 31,
    end_surah_number: 30,
    end_ayah_number: 53,
    ayah_count: 23,
    uthmani_pages_count: 4,
    uthmani_pages_range: [407, 410],
    index: 2,
  },
  {
    id: 164,
    rub_number: 164,
    hizb_number: 41,
    juz_number: 21,
    quarter: 4,
    ayah_number: 3463,
    start_surah_number: 30,
    start_ayah_number: 54,
    end_surah_number: 31,
    end_ayah_number: 21,
    ayah_count: 28,
    uthmani_pages_count: 4,
    uthmani_pages_range: [410, 413],
    index: 3,
  },
  {
    id: 165,
    rub_number: 165,
    hizb_number: 42,
    juz_number: 21,
    quarter: 1,
    ayah_number: 3491,
    start_surah_number: 31,
    start_ayah_number: 22,
    end_surah_number: 32,
    end_ayah_number: 10,
    ayah_count: 23,
    uthmani_pages_count: 3,
    uthmani_pages_range: [413, 415],
    index: 0,
  },
  {
    id: 166,
    rub_number: 166,
    hizb_number: 42,
    juz_number: 21,
    quarter: 2,
    ayah_number: 3514,
    start_surah_number: 32,
    start_ayah_number: 11,
    end_surah_number: 32,
    end_ayah_number: 30,
    ayah_count: 20,
    uthmani_pages_count: 3,
    uthmani_pages_range: [415, 417],
    index: 1,
  },
  {
    id: 167,
    rub_number: 167,
    hizb_number: 42,
    juz_number: 21,
    quarter: 3,
    ayah_number: 3534,
    start_surah_number: 33,
    start_ayah_number: 1,
    end_surah_number: 33,
    end_ayah_number: 17,
    ayah_count: 17,
    uthmani_pages_count: 3,
    uthmani_pages_range: [418, 420],
    index: 2,
  },
  {
    id: 168,
    rub_number: 168,
    hizb_number: 42,
    juz_number: 21,
    quarter: 4,
    ayah_number: 3551,
    start_surah_number: 33,
    start_ayah_number: 18,
    end_surah_number: 33,
    end_ayah_number: 30,
    ayah_count: 13,
    uthmani_pages_count: 2,
    uthmani_pages_range: [420, 421],
    index: 3,
  },
  {
    id: 169,
    rub_number: 169,
    hizb_number: 43,
    juz_number: 22,
    quarter: 1,
    ayah_number: 3564,
    start_surah_number: 33,
    start_ayah_number: 31,
    end_surah_number: 33,
    end_ayah_number: 50,
    ayah_count: 20,
    uthmani_pages_count: 3,
    uthmani_pages_range: [422, 424],
    index: 0,
  },
  {
    id: 170,
    rub_number: 170,
    hizb_number: 43,
    juz_number: 22,
    quarter: 2,
    ayah_number: 3584,
    start_surah_number: 33,
    start_ayah_number: 51,
    end_surah_number: 33,
    end_ayah_number: 59,
    ayah_count: 9,
    uthmani_pages_count: 2,
    uthmani_pages_range: [425, 426],
    index: 1,
  },
  {
    id: 171,
    rub_number: 171,
    hizb_number: 43,
    juz_number: 22,
    quarter: 3,
    ayah_number: 3593,
    start_surah_number: 33,
    start_ayah_number: 60,
    end_surah_number: 34,
    end_ayah_number: 9,
    ayah_count: 23,
    uthmani_pages_count: 4,
    uthmani_pages_range: [426, 429],
    index: 2,
  },
  {
    id: 172,
    rub_number: 172,
    hizb_number: 43,
    juz_number: 22,
    quarter: 4,
    ayah_number: 3616,
    start_surah_number: 34,
    start_ayah_number: 10,
    end_surah_number: 34,
    end_ayah_number: 23,
    ayah_count: 14,
    uthmani_pages_count: 3,
    uthmani_pages_range: [429, 431],
    index: 3,
  },
  {
    id: 173,
    rub_number: 173,
    hizb_number: 44,
    juz_number: 22,
    quarter: 1,
    ayah_number: 3630,
    start_surah_number: 34,
    start_ayah_number: 24,
    end_surah_number: 34,
    end_ayah_number: 45,
    ayah_count: 22,
    uthmani_pages_count: 3,
    uthmani_pages_range: [431, 433],
    index: 0,
  },
  {
    id: 174,
    rub_number: 174,
    hizb_number: 44,
    juz_number: 22,
    quarter: 2,
    ayah_number: 3652,
    start_surah_number: 34,
    start_ayah_number: 46,
    end_surah_number: 35,
    end_ayah_number: 14,
    ayah_count: 23,
    uthmani_pages_count: 4,
    uthmani_pages_range: [433, 436],
    index: 1,
  },
  {
    id: 175,
    rub_number: 175,
    hizb_number: 44,
    juz_number: 22,
    quarter: 3,
    ayah_number: 3675,
    start_surah_number: 35,
    start_ayah_number: 15,
    end_surah_number: 35,
    end_ayah_number: 40,
    ayah_count: 26,
    uthmani_pages_count: 4,
    uthmani_pages_range: [436, 439],
    index: 2,
  },
  {
    id: 176,
    rub_number: 176,
    hizb_number: 44,
    juz_number: 22,
    quarter: 4,
    ayah_number: 3701,
    start_surah_number: 35,
    start_ayah_number: 41,
    end_surah_number: 36,
    end_ayah_number: 27,
    ayah_count: 32,
    uthmani_pages_count: 3,
    uthmani_pages_range: [439, 441],
    index: 3,
  },
  {
    id: 177,
    rub_number: 177,
    hizb_number: 45,
    juz_number: 23,
    quarter: 1,
    ayah_number: 3733,
    start_surah_number: 36,
    start_ayah_number: 28,
    end_surah_number: 36,
    end_ayah_number: 59,
    ayah_count: 32,
    uthmani_pages_count: 3,
    uthmani_pages_range: [442, 444],
    index: 0,
  },
  {
    id: 178,
    rub_number: 178,
    hizb_number: 45,
    juz_number: 23,
    quarter: 2,
    ayah_number: 3765,
    start_surah_number: 36,
    start_ayah_number: 60,
    end_surah_number: 37,
    end_ayah_number: 21,
    ayah_count: 45,
    uthmani_pages_count: 3,
    uthmani_pages_range: [444, 446],
    index: 1,
  },
  {
    id: 179,
    rub_number: 179,
    hizb_number: 45,
    juz_number: 23,
    quarter: 3,
    ayah_number: 3810,
    start_surah_number: 37,
    start_ayah_number: 22,
    end_surah_number: 37,
    end_ayah_number: 82,
    ayah_count: 61,
    uthmani_pages_count: 4,
    uthmani_pages_range: [446, 449],
    index: 2,
  },
  {
    id: 180,
    rub_number: 180,
    hizb_number: 45,
    juz_number: 23,
    quarter: 4,
    ayah_number: 3871,
    start_surah_number: 37,
    start_ayah_number: 83,
    end_surah_number: 37,
    end_ayah_number: 144,
    ayah_count: 62,
    uthmani_pages_count: 3,
    uthmani_pages_range: [449, 451],
    index: 3,
  },
  {
    id: 181,
    rub_number: 181,
    hizb_number: 46,
    juz_number: 23,
    quarter: 1,
    ayah_number: 3933,
    start_surah_number: 37,
    start_ayah_number: 145,
    end_surah_number: 38,
    end_ayah_number: 20,
    ayah_count: 58,
    uthmani_pages_count: 4,
    uthmani_pages_range: [451, 454],
    index: 0,
  },
  {
    id: 182,
    rub_number: 182,
    hizb_number: 46,
    juz_number: 23,
    quarter: 2,
    ayah_number: 3991,
    start_surah_number: 38,
    start_ayah_number: 21,
    end_surah_number: 38,
    end_ayah_number: 51,
    ayah_count: 31,
    uthmani_pages_count: 3,
    uthmani_pages_range: [454, 456],
    index: 1,
  },
  {
    id: 183,
    rub_number: 183,
    hizb_number: 46,
    juz_number: 23,
    quarter: 3,
    ayah_number: 4022,
    start_surah_number: 38,
    start_ayah_number: 52,
    end_surah_number: 39,
    end_ayah_number: 7,
    ayah_count: 26,
    uthmani_pages_count: 4,
    uthmani_pages_range: [456, 459],
    index: 2,
  },
  {
    id: 184,
    rub_number: 184,
    hizb_number: 46,
    juz_number: 23,
    quarter: 4,
    ayah_number: 4066,
    start_surah_number: 39,
    start_ayah_number: 8,
    end_surah_number: 39,
    end_ayah_number: 31,
    ayah_count: 32,
    uthmani_pages_count: 3,
    uthmani_pages_range: [459, 461],
    index: 3,
  },
  {
    id: 185,
    rub_number: 185,
    hizb_number: 47,
    juz_number: 24,
    quarter: 1,
    ayah_number: 4099,
    start_surah_number: 39,
    start_ayah_number: 32,
    end_surah_number: 39,
    end_ayah_number: 52,
    ayah_count: 21,
    uthmani_pages_count: 3,
    uthmani_pages_range: [462, 464],
    index: 0,
  },
  {
    id: 186,
    rub_number: 186,
    hizb_number: 47,
    juz_number: 24,
    quarter: 2,
    ayah_number: 4111,
    start_surah_number: 39,
    start_ayah_number: 53,
    end_surah_number: 39,
    end_ayah_number: 75,
    ayah_count: 23,
    uthmani_pages_count: 4,
    uthmani_pages_range: [464, 467],
    index: 1,
  },
  {
    id: 187,
    rub_number: 187,
    hizb_number: 47,
    juz_number: 24,
    quarter: 3,
    ayah_number: 4134,
    start_surah_number: 40,
    start_ayah_number: 1,
    end_surah_number: 40,
    end_ayah_number: 20,
    ayah_count: 20,
    uthmani_pages_count: 3,
    uthmani_pages_range: [467, 469],
    index: 2,
  },
  {
    id: 188,
    rub_number: 188,
    hizb_number: 47,
    juz_number: 24,
    quarter: 4,
    ayah_number: 4154,
    start_surah_number: 40,
    start_ayah_number: 21,
    end_surah_number: 40,
    end_ayah_number: 40,
    ayah_count: 20,
    uthmani_pages_count: 3,
    uthmani_pages_range: [469, 471],
    index: 3,
  },
  {
    id: 189,
    rub_number: 189,
    hizb_number: 48,
    juz_number: 24,
    quarter: 1,
    ayah_number: 4174,
    start_surah_number: 40,
    start_ayah_number: 41,
    end_surah_number: 40,
    end_ayah_number: 65,
    ayah_count: 25,
    uthmani_pages_count: 3,
    uthmani_pages_range: [472, 474],
    index: 0,
  },
  {
    id: 190,
    rub_number: 190,
    hizb_number: 48,
    juz_number: 24,
    quarter: 2,
    ayah_number: 4199,
    start_surah_number: 40,
    start_ayah_number: 66,
    end_surah_number: 41,
    end_ayah_number: 8,
    ayah_count: 28,
    uthmani_pages_count: 4,
    uthmani_pages_range: [474, 477],
    index: 1,
  },
  {
    id: 191,
    rub_number: 191,
    hizb_number: 48,
    juz_number: 24,
    quarter: 3,
    ayah_number: 4227,
    start_surah_number: 41,
    start_ayah_number: 9,
    end_surah_number: 41,
    end_ayah_number: 24,
    ayah_count: 16,
    uthmani_pages_count: 3,
    uthmani_pages_range: [477, 479],
    index: 2,
  },
  {
    id: 192,
    rub_number: 192,
    hizb_number: 48,
    juz_number: 24,
    quarter: 4,
    ayah_number: 4243,
    start_surah_number: 41,
    start_ayah_number: 25,
    end_surah_number: 41,
    end_ayah_number: 26,
    ayah_count: 22,
    uthmani_pages_count: 3,
    uthmani_pages_range: [479, 481],
    index: 3,
  },
  {
    id: 193,
    rub_number: 193,
    hizb_number: 49,
    juz_number: 25,
    quarter: 1,
    ayah_number: 4265,
    start_surah_number: 41,
    start_ayah_number: 47,
    end_surah_number: 42,
    end_ayah_number: 12,
    ayah_count: 20,
    uthmani_pages_count: 3,
    uthmani_pages_range: [482, 484],
    index: 0,
  },
  {
    id: 194,
    rub_number: 194,
    hizb_number: 49,
    juz_number: 25,
    quarter: 2,
    ayah_number: 4285,
    start_surah_number: 42,
    start_ayah_number: 13,
    end_surah_number: 42,
    end_ayah_number: 26,
    ayah_count: 14,
    uthmani_pages_count: 3,
    uthmani_pages_range: [484, 486],
    index: 1,
  },
  {
    id: 195,
    rub_number: 195,
    hizb_number: 49,
    juz_number: 25,
    quarter: 3,
    ayah_number: 4299,
    start_surah_number: 42,
    start_ayah_number: 27,
    end_surah_number: 42,
    end_ayah_number: 50,
    ayah_count: 24,
    uthmani_pages_count: 3,
    uthmani_pages_range: [486, 488],
    index: 2,
  },
  {
    id: 196,
    rub_number: 196,
    hizb_number: 49,
    juz_number: 25,
    quarter: 4,
    ayah_number: 4323,
    start_surah_number: 42,
    start_ayah_number: 51,
    end_surah_number: 43,
    end_ayah_number: 23,
    ayah_count: 26,
    uthmani_pages_count: 4,
    uthmani_pages_range: [488, 491],
    index: 3,
  },
  {
    id: 197,
    rub_number: 197,
    hizb_number: 50,
    juz_number: 25,
    quarter: 1,
    ayah_number: 4349,
    start_surah_number: 43,
    start_ayah_number: 24,
    end_surah_number: 43,
    end_ayah_number: 56,
    ayah_count: 33,
    uthmani_pages_count: 3,
    uthmani_pages_range: [491, 493],
    index: 0,
  },
  {
    id: 198,
    rub_number: 198,
    hizb_number: 50,
    juz_number: 25,
    quarter: 2,
    ayah_number: 4382,
    start_surah_number: 43,
    start_ayah_number: 57,
    end_surah_number: 44,
    end_ayah_number: 16,
    ayah_count: 49,
    uthmani_pages_count: 4,
    uthmani_pages_range: [493, 496],
    index: 1,
  },
  {
    id: 199,
    rub_number: 199,
    hizb_number: 50,
    juz_number: 25,
    quarter: 3,
    ayah_number: 4431,
    start_surah_number: 44,
    start_ayah_number: 17,
    end_surah_number: 45,
    end_ayah_number: 11,
    ayah_count: 54,
    uthmani_pages_count: 4,
    uthmani_pages_range: [496, 499],
    index: 2,
  },
  {
    id: 200,
    rub_number: 200,
    hizb_number: 50,
    juz_number: 25,
    quarter: 4,
    ayah_number: 4485,
    start_surah_number: 45,
    start_ayah_number: 12,
    end_surah_number: 45,
    end_ayah_number: 37,
    ayah_count: 26,
    uthmani_pages_count: 4,
    uthmani_pages_range: [499, 502],
    index: 3,
  },
  {
    id: 201,
    rub_number: 201,
    hizb_number: 51,
    juz_number: 26,
    quarter: 1,
    ayah_number: 4511,
    start_surah_number: 46,
    start_ayah_number: 1,
    end_surah_number: 46,
    end_ayah_number: 20,
    ayah_count: 20,
    uthmani_pages_count: 3,
    uthmani_pages_range: [502, 504],
    index: 0,
  },
  {
    id: 202,
    rub_number: 202,
    hizb_number: 51,
    juz_number: 26,
    quarter: 2,
    ayah_number: 4531,
    start_surah_number: 46,
    start_ayah_number: 21,
    end_surah_number: 47,
    end_ayah_number: 9,
    ayah_count: 24,
    uthmani_pages_count: 3,
    uthmani_pages_range: [505, 507],
    index: 1,
  },
  {
    id: 203,
    rub_number: 203,
    hizb_number: 51,
    juz_number: 26,
    quarter: 3,
    ayah_number: 4555,
    start_surah_number: 47,
    start_ayah_number: 10,
    end_surah_number: 47,
    end_ayah_number: 32,
    ayah_count: 23,
    uthmani_pages_count: 4,
    uthmani_pages_range: [507, 510],
    index: 2,
  },
  {
    id: 204,
    rub_number: 204,
    hizb_number: 51,
    juz_number: 26,
    quarter: 4,
    ayah_number: 4578,
    start_surah_number: 47,
    start_ayah_number: 33,
    end_surah_number: 48,
    end_ayah_number: 17,
    ayah_count: 23,
    uthmani_pages_count: 4,
    uthmani_pages_range: [510, 513],
    index: 3,
  },
  {
    id: 205,
    rub_number: 205,
    hizb_number: 52,
    juz_number: 26,
    quarter: 1,
    ayah_number: 4601,
    start_surah_number: 48,
    start_ayah_number: 18,
    end_surah_number: 48,
    end_ayah_number: 29,
    ayah_count: 12,
    uthmani_pages_count: 3,
    uthmani_pages_range: [513, 515],
    index: 0,
  },
  {
    id: 206,
    rub_number: 206,
    hizb_number: 52,
    juz_number: 26,
    quarter: 2,
    ayah_number: 4613,
    start_surah_number: 49,
    start_ayah_number: 1,
    end_surah_number: 49,
    end_ayah_number: 13,
    ayah_count: 13,
    uthmani_pages_count: 3,
    uthmani_pages_range: [515, 517],
    index: 1,
  },
  {
    id: 207,
    rub_number: 207,
    hizb_number: 52,
    juz_number: 26,
    quarter: 3,
    ayah_number: 4626,
    start_surah_number: 49,
    start_ayah_number: 14,
    end_surah_number: 50,
    end_ayah_number: 26,
    ayah_count: 31,
    uthmani_pages_count: 3,
    uthmani_pages_range: [517, 519],
    index: 2,
  },
  {
    id: 208,
    rub_number: 208,
    hizb_number: 52,
    juz_number: 26,
    quarter: 4,
    ayah_number: 4657,
    start_surah_number: 50,
    start_ayah_number: 27,
    end_surah_number: 51,
    end_ayah_number: 30,
    ayah_count: 49,
    uthmani_pages_count: 3,
    uthmani_pages_range: [519, 521],
    index: 3,
  },
  {
    id: 209,
    rub_number: 209,
    hizb_number: 53,
    juz_number: 27,
    quarter: 1,
    ayah_number: 4706,
    start_surah_number: 51,
    start_ayah_number: 31,
    end_surah_number: 52,
    end_ayah_number: 23,
    ayah_count: 53,
    uthmani_pages_count: 3,
    uthmani_pages_range: [522, 524],
    index: 0,
  },
  {
    id: 210,
    rub_number: 210,
    hizb_number: 53,
    juz_number: 27,
    quarter: 2,
    ayah_number: 4759,
    start_surah_number: 52,
    start_ayah_number: 24,
    end_surah_number: 53,
    end_ayah_number: 25,
    ayah_count: 51,
    uthmani_pages_count: 3,
    uthmani_pages_range: [524, 526],
    index: 1,
  },
  {
    id: 211,
    rub_number: 211,
    hizb_number: 53,
    juz_number: 27,
    quarter: 3,
    ayah_number: 4810,
    start_surah_number: 53,
    start_ayah_number: 26,
    end_surah_number: 54,
    end_ayah_number: 8,
    ayah_count: 45,
    uthmani_pages_count: 4,
    uthmani_pages_range: [526, 529],
    index: 2,
  },
  {
    id: 212,
    rub_number: 212,
    hizb_number: 53,
    juz_number: 27,
    quarter: 4,
    ayah_number: 4855,
    start_surah_number: 54,
    start_ayah_number: 9,
    end_surah_number: 54,
    end_ayah_number: 55,
    ayah_count: 47,
    uthmani_pages_count: 3,
    uthmani_pages_range: [529, 531],
    index: 3,
  },
  {
    id: 213,
    rub_number: 213,
    hizb_number: 54,
    juz_number: 27,
    quarter: 1,
    ayah_number: 4902,
    start_surah_number: 55,
    start_ayah_number: 1,
    end_surah_number: 55,
    end_ayah_number: 78,
    ayah_count: 78,
    uthmani_pages_count: 4,
    uthmani_pages_range: [531, 534],
    index: 0,
  },
  {
    id: 214,
    rub_number: 214,
    hizb_number: 54,
    juz_number: 27,
    quarter: 2,
    ayah_number: 4980,
    start_surah_number: 56,
    start_ayah_number: 1,
    end_surah_number: 56,
    end_ayah_number: 74,
    ayah_count: 74,
    uthmani_pages_count: 3,
    uthmani_pages_range: [534, 536],
    index: 1,
  },
  {
    id: 215,
    rub_number: 215,
    hizb_number: 54,
    juz_number: 27,
    quarter: 3,
    ayah_number: 5054,
    start_surah_number: 56,
    start_ayah_number: 75,
    end_surah_number: 57,
    end_ayah_number: 15,
    ayah_count: 37,
    uthmani_pages_count: 4,
    uthmani_pages_range: [536, 539],
    index: 2,
  },
  {
    id: 216,
    rub_number: 216,
    hizb_number: 54,
    juz_number: 27,
    quarter: 4,
    ayah_number: 5091,
    start_surah_number: 57,
    start_ayah_number: 16,
    end_surah_number: 57,
    end_ayah_number: 29,
    ayah_count: 14,
    uthmani_pages_count: 3,
    uthmani_pages_range: [539, 541],
    index: 3,
  },
  {
    id: 217,
    rub_number: 217,
    hizb_number: 55,
    juz_number: 28,
    quarter: 1,
    ayah_number: 5105,
    start_surah_number: 58,
    start_ayah_number: 1,
    end_surah_number: 58,
    end_ayah_number: 13,
    ayah_count: 13,
    uthmani_pages_count: 3,
    uthmani_pages_range: [542, 544],
    index: 0,
  },
  {
    id: 218,
    rub_number: 218,
    hizb_number: 55,
    juz_number: 28,
    quarter: 2,
    ayah_number: 5118,
    start_surah_number: 58,
    start_ayah_number: 14,
    end_surah_number: 59,
    end_ayah_number: 10,
    ayah_count: 19,
    uthmani_pages_count: 4,
    uthmani_pages_range: [544, 547],
    index: 1,
  },
  {
    id: 219,
    rub_number: 219,
    hizb_number: 55,
    juz_number: 28,
    quarter: 3,
    ayah_number: 5137,
    start_surah_number: 59,
    start_ayah_number: 11,
    end_surah_number: 60,
    end_ayah_number: 6,
    ayah_count: 20,
    uthmani_pages_count: 4,
    uthmani_pages_range: [547, 550],
    index: 2,
  },
  {
    id: 220,
    rub_number: 220,
    hizb_number: 55,
    juz_number: 28,
    quarter: 4,
    ayah_number: 5157,
    start_surah_number: 60,
    start_ayah_number: 7,
    end_surah_number: 61,
    end_ayah_number: 14,
    ayah_count: 21,
    uthmani_pages_count: 3,
    uthmani_pages_range: [550, 552],
    index: 3,
  },
  {
    id: 221,
    rub_number: 221,
    hizb_number: 56,
    juz_number: 28,
    quarter: 1,
    ayah_number: 5178,
    start_surah_number: 62,
    start_ayah_number: 1,
    end_surah_number: 63,
    end_ayah_number: 3,
    ayah_count: 14,
    uthmani_pages_count: 2,
    uthmani_pages_range: [553, 554],
    index: 0,
  },
  {
    id: 222,
    rub_number: 222,
    hizb_number: 56,
    juz_number: 28,
    quarter: 2,
    ayah_number: 5192,
    start_surah_number: 63,
    start_ayah_number: 4,
    end_surah_number: 64,
    end_ayah_number: 18,
    ayah_count: 26,
    uthmani_pages_count: 4,
    uthmani_pages_range: [554, 557],
    index: 1,
  },
  {
    id: 223,
    rub_number: 223,
    hizb_number: 56,
    juz_number: 28,
    quarter: 3,
    ayah_number: 5218,
    start_surah_number: 65,
    start_ayah_number: 1,
    end_surah_number: 65,
    end_ayah_number: 12,
    ayah_count: 12,
    uthmani_pages_count: 2,
    uthmani_pages_range: [558, 559],
    index: 2,
  },
  {
    id: 224,
    rub_number: 224,
    hizb_number: 56,
    juz_number: 28,
    quarter: 4,
    ayah_number: 5230,
    start_surah_number: 66,
    start_ayah_number: 1,
    end_surah_number: 66,
    end_ayah_number: 12,
    ayah_count: 12,
    uthmani_pages_count: 2,
    uthmani_pages_range: [560, 561],
    index: 3,
  },
  {
    id: 225,
    rub_number: 225,
    hizb_number: 57,
    juz_number: 29,
    quarter: 1,
    ayah_number: 5242,
    start_surah_number: 67,
    start_ayah_number: 1,
    end_surah_number: 67,
    end_ayah_number: 30,
    ayah_count: 30,
    uthmani_pages_count: 3,
    uthmani_pages_range: [562, 564],
    index: 0,
  },
  {
    id: 226,
    rub_number: 226,
    hizb_number: 57,
    juz_number: 29,
    quarter: 2,
    ayah_number: 5272,
    start_surah_number: 68,
    start_ayah_number: 1,
    end_surah_number: 68,
    end_ayah_number: 52,
    ayah_count: 52,
    uthmani_pages_count: 3,
    uthmani_pages_range: [564, 566],
    index: 1,
  },
  {
    id: 227,
    rub_number: 227,
    hizb_number: 57,
    juz_number: 29,
    quarter: 3,
    ayah_number: 5324,
    start_surah_number: 69,
    start_ayah_number: 1,
    end_surah_number: 70,
    end_ayah_number: 18,
    ayah_count: 70,
    uthmani_pages_count: 4,
    uthmani_pages_range: [566, 569],
    index: 2,
  },
  {
    id: 228,
    rub_number: 228,
    hizb_number: 57,
    juz_number: 29,
    quarter: 4,
    ayah_number: 5394,
    start_surah_number: 70,
    start_ayah_number: 19,
    end_surah_number: 71,
    end_ayah_number: 28,
    ayah_count: 54,
    uthmani_pages_count: 3,
    uthmani_pages_range: [569, 571],
    index: 3,
  },
  {
    id: 229,
    rub_number: 229,
    hizb_number: 58,
    juz_number: 29,
    quarter: 1,
    ayah_number: 5448,
    start_surah_number: 72,
    start_ayah_number: 1,
    end_surah_number: 73,
    end_ayah_number: 19,
    ayah_count: 47,
    uthmani_pages_count: 3,
    uthmani_pages_range: [572, 574],
    index: 0,
  },
  {
    id: 230,
    rub_number: 230,
    hizb_number: 58,
    juz_number: 29,
    quarter: 2,
    ayah_number: 5495,
    start_surah_number: 73,
    start_ayah_number: 20,
    end_surah_number: 74,
    end_ayah_number: 56,
    ayah_count: 57,
    uthmani_pages_count: 3,
    uthmani_pages_range: [575, 577],
    index: 1,
  },
  {
    id: 231,
    rub_number: 231,
    hizb_number: 58,
    juz_number: 29,
    quarter: 3,
    ayah_number: 5552,
    start_surah_number: 75,
    start_ayah_number: 1,
    end_surah_number: 76,
    end_ayah_number: 18,
    ayah_count: 58,
    uthmani_pages_count: 3,
    uthmani_pages_range: [577, 579],
    index: 2,
  },
  {
    id: 232,
    rub_number: 232,
    hizb_number: 58,
    juz_number: 29,
    quarter: 4,
    ayah_number: 5610,
    start_surah_number: 76,
    start_ayah_number: 19,
    end_surah_number: 77,
    end_ayah_number: 50,
    ayah_count: 63,
    uthmani_pages_count: 3,
    uthmani_pages_range: [579, 581],
    index: 3,
  },
  {
    id: 233,
    rub_number: 233,
    hizb_number: 59,
    juz_number: 30,
    quarter: 1,
    ayah_number: 5673,
    start_surah_number: 78,
    start_ayah_number: 1,
    end_surah_number: 79,
    end_ayah_number: 46,
    ayah_count: 86,
    uthmani_pages_count: 3,
    uthmani_pages_range: [582, 584],
    index: 0,
  },
  {
    id: 234,
    rub_number: 234,
    hizb_number: 59,
    juz_number: 30,
    quarter: 2,
    ayah_number: 5759,
    start_surah_number: 80,
    start_ayah_number: 1,
    end_surah_number: 81,
    end_ayah_number: 29,
    ayah_count: 71,
    uthmani_pages_count: 2,
    uthmani_pages_range: [585, 586],
    index: 1,
  },
  {
    id: 235,
    rub_number: 235,
    hizb_number: 59,
    juz_number: 30,
    quarter: 3,
    ayah_number: 5830,
    start_surah_number: 82,
    start_ayah_number: 1,
    end_surah_number: 83,
    end_ayah_number: 36,
    ayah_count: 55,
    uthmani_pages_count: 3,
    uthmani_pages_range: [587, 589],
    index: 2,
  },
  {
    id: 236,
    rub_number: 236,
    hizb_number: 59,
    juz_number: 30,
    quarter: 4,
    ayah_number: 5885,
    start_surah_number: 84,
    start_ayah_number: 1,
    end_surah_number: 86,
    end_ayah_number: 17,
    ayah_count: 64,
    uthmani_pages_count: 3,
    uthmani_pages_range: [589, 591],
    index: 3,
  },
  {
    id: 237,
    rub_number: 237,
    hizb_number: 60,
    juz_number: 30,
    quarter: 1,
    ayah_number: 5949,
    start_surah_number: 87,
    start_ayah_number: 1,
    end_surah_number: 89,
    end_ayah_number: 30,
    ayah_count: 75,
    uthmani_pages_count: 4,
    uthmani_pages_range: [591, 594],
    index: 0,
  },
  {
    id: 238,
    rub_number: 238,
    hizb_number: 60,
    juz_number: 30,
    quarter: 2,
    ayah_number: 6024,
    start_surah_number: 90,
    start_ayah_number: 1,
    end_surah_number: 93,
    end_ayah_number: 11,
    ayah_count: 67,
    uthmani_pages_count: 3,
    uthmani_pages_range: [594, 596],
    index: 1,
  },
  {
    id: 239,
    rub_number: 239,
    hizb_number: 60,
    juz_number: 30,
    quarter: 3,
    ayah_number: 6091,
    start_surah_number: 94,
    start_ayah_number: 1,
    end_surah_number: 100,
    end_ayah_number: 8,
    ayah_count: 67,
    uthmani_pages_count: 4,
    uthmani_pages_range: [596, 599],
    index: 2,
  },
  {
    id: 240,
    rub_number: 240,
    hizb_number: 60,
    juz_number: 30,
    quarter: 4,
    ayah_number: 6155,
    start_surah_number: 100,
    start_ayah_number: 9,
    end_surah_number: 114,
    end_ayah_number: 6,
    ayah_count: 82,
    uthmani_pages_count: 6,
    uthmani_pages_range: [599, 604],
    index: 3,
  },
];
module.exports=rubeHezbData;
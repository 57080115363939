import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import PrevIcon from '@material-ui/icons/SkipNext';
import NextIcon from '@material-ui/icons/SkipPrevious';
import TopIcon from '@material-ui/icons/VerticalAlignTop';
import NextPageIcon from '@material-ui/icons/KeyboardArrowLeft';
import PrevPageIcon from '@material-ui/icons/KeyboardArrowRight';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { connect } from 'react-redux';
import convertToArabicNumber from '../../utility/convertToArabicNumber';
import { bindActionCreators } from 'redux';
import * as quranActions from '../../actions/actionCreators';
import { withRouter } from 'react-router-dom';
import ReactPlayer from 'react-player';
import QuranMeta from '../../quranMeta/QuranMeta';
import AppBar from '@material-ui/core/AppBar';
import compareObjects from '../../utility/compareObjects';

class ReciteBar extends React.Component{
    constructor(props){
        super(props);

        this.state={
            play:false,
            playList:'',
            bismillah:true,
        }

		this.goToNextPage = this.goToNextPage.bind(this);
        this.goToPrevPage = this.goToPrevPage.bind(this);
        this.goToPrevAya = this.goToPrevAya.bind(this);
		this.goToTop = this.goToTop.bind(this);
		this.goToNextAya = this.goToNextAya.bind(this);
		this.playAya = this.playAya.bind(this);
		this.endAudio = this.endAudio.bind(this);
		this.selectByLang=this.selectByLang.bind(this);
    }

	shouldComponentUpdate(nextProps,nextState){
		return(
			this.props.store.userData.sura!==nextProps.store.userData.sura ||
			this.props.store.userData.aya!==nextProps.store.userData.aya ||
            this.props.store.userData.theme!==nextProps.store.userData.theme ||
			this.props.store.userData.langInfo.lang!==nextProps.store.userData.langInfo.lang ||
            !compareObjects(nextState,this.state)
		)
	}
   

	static getDerivedStateFromProps(nextProps,state) {

        let suraFile = "0".repeat(3-(nextProps.store.userData.sura.toString().length)).concat(nextProps.store.userData.sura);
		let ayaFile = "0".repeat(3-(nextProps.store.userData.aya.toString().length)).concat(nextProps.store.userData.aya);
        let playList = "";
		
		//if there is no selected recitation
		if(!nextProps.store.reciteData.selectedRecite.reciteQuality || !nextProps.store.reciteData.selectedRecite.reciteQuality.quality_url){
			playList='';
		}// check for bismillah needs
        else if (nextProps.store.userData.sura !== 1 && nextProps.store.userData.sura !== 9 &&
                    nextProps.store.userData.aya === 1 && state.bismillah){
            playList = nextProps.store.reciteData.selectedRecite.reciteQuality.quality_url + "/001001.mp3";
		} else {
			playList = nextProps.store.reciteData.selectedRecite.reciteQuality.quality_url + "/" + suraFile + ayaFile + ".mp3";
		}

        return {
			playList:playList,
        }
    }

	selectByLang(rtl,ltr){
        return (this.props.store.userData.langInfo.direction === 'rtl') ? rtl : ltr;
	}
	
    goTo(lang, sura, aya) {
		this.props.history.push("/" + lang + "/" + sura + ":" + aya);
		this.props.actions.changeSura(parseInt(sura));
		this.props.actions.changeAya(parseInt(aya));
	}

    goToNextPage() {
		
		let page=1;
		try{
			page=QuranMeta.SAToPage(this.props.store.userData.sura, this.props.store.userData.aya);
		}catch(er){
			console.error(er);
		}

		if (page >= 604) {
			return;
		};

        page++;
        let PageFirstSA = QuranMeta.pageToSA(page);
		this.goTo(this.props.store.userData.langInfo.lang, PageFirstSA.sura, PageFirstSA.aya);
	}

	goToPrevPage() {
		let page=1;
		try{
			page=QuranMeta.SAToPage(this.props.store.userData.sura, this.props.store.userData.aya);
		}catch(er){}

		if (page <= 1) {
			return;
        };
        
        page--;
        let PageFirstSA = QuranMeta.pageToSA(page);
		this.goTo(this.props.store.userData.langInfo.lang, PageFirstSA.sura, PageFirstSA.aya);
    }
    
    goToNextAya() {
        
		let sura = this.props.store.userData.sura;
		let aya = this.props.store.userData.aya;

        let Ayas = QuranMeta.getSuraDetails(sura).ayas;
        
        //if current aya is 1st aya and bismillah is true it means
        //we hear bismillah and not 1st aya
        //then just set bismillah to false and leave aya to 1
        if(parseInt(aya)===1 && this.state.bismillah){
            this.setState({bismillah:false});
        }else{
            aya++;
            if (aya > Ayas) {
                sura++;
				aya = 1;
				if (sura !== 9)
					this.setState({bismillah:true});	
            };
        }
		
		this.goTo(this.props.store.userData.langInfo.lang, sura, aya);
    }
    
    goToPrevAya() {
		let sura = this.props.store.userData.sura;
		let aya = this.props.store.userData.aya;

		if (parseInt(aya) === 1) {
			sura--;
			let Ayas = QuranMeta.getSuraDetails(sura).ayas;
			aya = Ayas;
		} else {
			aya--;
		}

		this.goTo(this.props.store.userData.langInfo.lang, sura, aya);
    }
    
    goToTop() {
		window.scrollTo({top:0,behavior:'smooth'});
    }
    
    playAya() {
		this.setState({ play: !this.state.play });
    }

    endAudio() {

		let sura = this.props.store.userData.sura;
		let aya = this.props.store.userData.aya;
		let flag=false;//false means player should not continue
		let next=undefined;//detail of next juz, hisb, manzil, ...
		let nextSura=undefined;//first sura of next...
		let nextAya=undefined;//first aya of next...
		let lastSura=undefined;//last sura of current juz,hizb,...
		let lastAya=undefined;//

		switch(this.props.store.reciteData.playerCnf.playMode){
			case 1://play 1 aya only
				if(parseInt(aya)===1 && this.state.bismillah){
					this.goToNextAya();
				}else{
					this.setState({play:false});
				}
				break;
			case 2: // Page
				var pageData = this.props.pageMeta;				
				pageData.forEach(function (item, index) {
					//check is current sura and aya the last one in page
					//if no flag remains true that means continue 
					if (item.meta.sura === sura) {
						if (item.meta.aya === aya) {
							if ((pageData.length - 1) !== index) {
								flag = true;
							}
						}
					}
				});

				if (flag) {
					this.goToNextAya();
				} else {
					this.setState({play:false});
				}
				break;
			case 3: // Sura
				if (aya < QuranMeta.getSuraDetails(sura).ayas) {
					this.goToNextAya();
				} else {
					this.setState({play:false});
				}
				break;
			case 4:  // Juz
				try{
					next=QuranMeta.getJuzDetails(QuranMeta.SAToJuz(sura, aya) + 1);//detail of next juz, hisb, manzil, ...
				}catch(er){
					next=QuranMeta.getJuzDetails(1);
				}
				nextSura=next.sura;//first sura of next...
				nextAya=next.aya;//first aya of next...

				//find last sura and aya count that chould been played
				if (nextAya === 1) {//if next juz begins with aya number one then last sura of current juz is pervious sura
					lastSura= nextSura - 1;
					lastAya = QuranMeta.getSuraDetails(lastSura).ayas;
				} else {//last sura of current juz is same as first sura of next juz
					lastSura = nextSura;
					lastAya = nextAya - 1;
				}
				//is this last aya of juz?
				if (lastSura === sura && lastAya === aya) {
					flag = true;
				}

				if (!flag) {
					this.goToNextAya();
				} else {
					this.setState({play:false});
				}
				break;
			case 5: // Hizb
				try{
					next = QuranMeta.getHizbDetails(QuranMeta.SAToHizb(sura, aya) + 1);
				}catch(er){
					next=QuranMeta.getHizbDetails(1);
				}
				nextSura = next.sura;
				nextAya = next.aya;

				if (nextAya === 1) {
					lastSura = nextSura - 1;
					lastAya = QuranMeta.getSuraDetails(lastSura).ayas;
				} else {
					lastSura = nextSura;
					lastAya = nextAya - 1;
				}

				if (lastSura === sura) {
					if (lastAya === aya) {
						flag = true;
					}
				}

				if (!flag) {
					this.goToNextAya();
				} else {
					this.setState({play:false});
				}

				break;
			case 6: // Manzil
				try{
					next = QuranMeta.getManzilDetails(QuranMeta.SAToManzil(sura, aya) + 1);
				}catch(err){
					next=QuranMeta.getManzilDetails(1);
				}
				nextSura = next.sura;
				nextAya = next.aya;

				if (nextAya === 1) {
					lastSura = nextSura - 1;
					lastAya = QuranMeta.getSuraDetails(lastSura).ayas;
				} else {
					lastSura = nextSura;
					lastAya = nextAya - 1;
				}

				if (lastSura === sura) {
					if (lastAya === aya) {
						flag = true;
					}
				}

				if (!flag) {
					this.goToNextAya();
				} else {
					this.setState({play:false});
				}
				break;
			case 7:  // Ruku
				try{
					next = QuranMeta.getRukuDetails(QuranMeta.SAToRuku(sura, aya) + 1);
				}catch(err){
					next = QuranMeta.getRukuDetails(1);
				}
				nextSura = next.sura;
				nextAya = next.aya;

				if (nextAya === 1) {
					lastSura = nextSura - 1;
					lastAya = QuranMeta.getSuraDetails(lastSura).ayas;
				} else {
					lastSura = nextSura;
					lastAya = nextAya - 1;
				}

				if (lastSura === sura) {
					if (lastAya === aya) {
						flag = true;
					}
				}

				if (!flag) {
					this.goToNextAya();
				} else {
					this.setState({play:false});
				}
				break;
			case 8:  // Continuous

				if (sura === 114 && aya === 6) {
					this.setState({ play: false });
				} else {
					this.goToNextAya();
				}
				break;
			default:
				if (parseInt(sura) === 114 && parseInt(aya) === 6) {
					this.setState({ play: false });
				} else {
					this.goToNextAya();
				}
		}
    }
    
    render(){
		
		let reciteBarRootStyle=(this.props.drawerState.isDrawerOpen ? 
								'recitebarHolder_od '.concat(this.selectByLang('recitebarHolder_rtl ','recitebarHolder_ltr ')):
								'recitebarHolder_cd ').concat(this.props.store.userData.theme==='light'?'recitebarHolder_light':'recitebarHolder_dark');

		let iconStyle='recitebarIcon '.concat(this.props.store.userData.theme==='light'?'recitebarIcon_light':'recitebarIcon_dark');						

		let page=1;
		try{
			page=QuranMeta.SAToPage(this.props.store.userData.sura, this.props.store.userData.aya);
		}catch(er){}
		
        return(
			<AppBar 
					id="recitebarHolder" 
					classes={{root:reciteBarRootStyle}}
			>
                <div style={{ display: 'none' }}>
					 <ReactPlayer 
						url={this.state.playList} 
						playing={this.state.play}
						onEnded={this.endAudio}
						onError={()=>this.setState({ play: false })}							
						/>
				</div>
                <div id="recitebar">
                    <div id="recitebarPlayerControls">
                        <IconButton onClick={this.goToTop}
								className={iconStyle}
						>
                            <TopIcon />
                        </IconButton>

                        <IconButton
                            onClick={this.goToNextAya}
                            disabled={parseInt(this.props.store.userData.sura) === 114 && parseInt(this.props.store.userData.aya) === 6}
							className={iconStyle}
                        >
                            <NextIcon />
                        </IconButton>

                        <IconButton
                            onClick={this.playAya}
							className={iconStyle}
						>
                            {(this.state.play) ? <PauseIcon /> : <PlayIcon />}
                        </IconButton>

                        <IconButton
                            onClick={this.goToPrevAya}
							disabled={parseInt(this.props.store.userData.sura) === 1 && parseInt(this.props.store.userData.aya) === 1}
							className={iconStyle}
                        >
                            <PrevIcon />
                        </IconButton>
                    </div>
                    <div id="recitebarPageNav">
                        <IconButton
                            onClick={this.goToNextPage}
							disabled={parseInt(page) === 604}
							className={iconStyle}
                        >
                            <NextPageIcon />
                        </IconButton>
                
                        <span className="page_number_style">
                            {(this.props.store.userData.langInfo.lang !== undefined && this.props.store.userData.langInfo.direction === 'rtl') ? 
                                        convertToArabicNumber(page) : page}
                        </span>

                        <IconButton
                            onClick={this.goToPrevPage}
							disabled={parseInt(page) === 1}
							className={iconStyle}
                        >
                            <PrevPageIcon />
                        </IconButton>
                    </div>
                </div>
            </AppBar>
        );
    }
}

function mapStateToProps(state, ownProps){
	return {
		'store': {
            userData : {
                sura:state.userData.sura,
                aya:state.userData.aya,
				langInfo : state.userData.langInfo,
				theme: state.userData.theme,
			},
			reciteData:{
				'selectedRecite':{
					reciteQuality:state.reciteData.selectedRecite.reciteQuality,
				},
				playerCnf:{
					playMode:state.reciteData.playerCnf.playMode,
				}
			},
        }
	};
}

function mapDispatchToProps(dispatch){
	return {
		actions: bindActionCreators(quranActions, dispatch)
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReciteBar));
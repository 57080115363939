// Main Dependencies
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as quranActions from '../../actions/actionCreators';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import SidebarDrawer from './SidebarDrawer';
import ServicesMenu from '../Global/ServicesMenu';
import LanguagesMenu from '../Global/LanguagesMenu';
import { Box } from '@material-ui/core';
import Search from './Search';
import LoginProfile from '../Global/LoginProfile';
import compareObjects from '../../utility/compareObjects';

class ActionBar extends React.Component{
	constructor(props) {
		super(props);
		this.state={
			appbarIconsStyle: "",
			appbarStyle:'',
		}
		

		this.goToPolicy=this.goToPolicy.bind(this);
	}	

	shouldComponentUpdate(nextProps,nextState){
		return(
            !compareObjects(nextState,this.state)
		)
	}

	static getDerivedStateFromProps(props,currenState){
		
		//determine about appbarStyle
		//appbarIconsStyle is about direction of buttons in root of AppBar
		//	row puts elements left to right and proper for rtl lang
		//	rpw-reverse puts elements right to left and used for ltr lang
		let appbarIconsStyle=currenState.appbarIconsStyle;
		let appbarStyle=currenState.appBarRootStyle;

		appbarStyle=(props.drawerState.isDrawerOpen ? "appbar_od ": "appbar_cd ")
				.concat(props.store.userData.langInfo.direction === 'rtl'?'appbar_rtl ':'appbar_ltr ')
				.concat(props.store.userData.theme==='light'?"appbar_light":"appbar_dark");
		appbarIconsStyle=props.store.userData.langInfo.direction === 'rtl'?
				"appbarIcons_rtl":"appbarIcons_ltr";

		
		return {
			appbarIconsStyle:appbarIconsStyle,
			appbarStyle:appbarStyle,
		};
	}

	goToPolicy(){
		this.props.history.push("/privacy-policy");
		window.scrollTo(0,0);
	}

	render(){
		return (
				<AppBar id='appbar' className={this.state.appbarStyle}>
						<Box id='appbarIcons' className={this.state.appbarIconsStyle}>
							<Search />
							<LanguagesMenu caller={"khatam"}/>
							<ServicesMenu />
							<LoginProfile/>
						</Box>

						{/*<SidebarDrawer drawerState={this.props.drawerState}/>*/}

				</AppBar>
		);
	}

};

function mapStateToProps(state, ownProps){
	return {
		store:{
			'userData':{
				langInfo:state.userData.langInfo,
				theme:state.userData.theme,
			}
		}
	};
}

function mapDispatchToProps(dispatch){
	return {
		actions: bindActionCreators(quranActions, dispatch)
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActionBar));
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as quranActions from '../../actions/actionCreators';
import QuranMeta from '../../quranMeta/QuranMeta';
import convertToArabicNumber from '../../utility/convertToArabicNumber';
import Divider from '@material-ui/core/Divider';
import LangInfo from '../../locale/langInfo';
import ActionBar from './ActionBar';
import QuranHeader from './QuranHeader';
import ReciteBar from './ReciteBar';
import { withRouter } from 'react-router-dom';
import smoothscrolling from '../../utility/smoothScrolling';
import compareObjects from '../../utility/compareObjects';
import { CircularProgress, Typography } from '@material-ui/core';

class Quran extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//we have sura,aya and lang as state beacause there might be an invalid value for them
			//and needs to set a valid one, the new valid value stores in this states until dispatch
			//to redux store in componentDidMount function
			sura:25,
			aya:1,
			langInfo:LangInfo["en"],
			isDrawerOpen: false,
		}
		
		//in lg and xl drawer is open by default
		if(window.matchMedia(`(min-width: 992px) and (max-width: 1200px)`).matches ||
			window.matchMedia(`(min-width: 1200px)`).matches){
		this.state.isDrawerOpen=true;
		}
		
		// Method bindings
		this.generatePageContent = this.generatePageContent.bind(this);
		this.generateSuraName = this.generateSuraName.bind(this);
		this.generateBismillah = this.generateBismillah.bind(this);
		this.highlightAya = this.highlightAya.bind(this);
		this.selectByLang=this.selectByLang.bind(this);
		this.applyTajwidColors=this.applyTajwidColors.bind(this);
		this.clickOnAya=this.clickOnAya.bind(this);
	}

	componentDidMount(){
		//in case that user insert sura, aya and lang in url and it is diffrent to sura, aya and lang in local storege
		//uers inputs are prior and this part of code sync local stoage and Qoran component state
		if(parseInt(this.state.sura)!==parseInt(this.props.store.userData.sura)){
			this.props.actions.changeSura(parseInt(this.state.sura));
		}

		if(parseInt(this.state.aya)!==parseInt(this.props.store.userData.aya))
			this.props.actions.changeAya(parseInt(this.state.aya));
		
		if((this.props.store.userData.langInfo.lang!==this.state.langInfo.lang) ||
				(this.props.store.userData.langInfo.direction!==this.state.langInfo.direction) ||
				(this.props.store.userData.langInfo.font!==this.state.langInfo.font) ||
				(this.props.store.userData.langInfo.displayName!==this.state.langInfo.displayName)){
			this.props.actions.changeLanguage(this.state.langInfo);
		}
	}

	componentDidUpdate(prevProps, prevState){
		// Object.entries(this.props).forEach(([key, val]) =>
		// 	prevProps[key] !== val && console.log(`Prop '${key}' changed`)
		// );
		// if (this.state) {
		// 	Object.entries(this.state).forEach(([key, val]) =>
		// 	prevState[key] !== val && console.log(`State '${key}' changed ${JSON.stringify(prevState[key])} to ${JSON.stringify(val)}`)
		// 	);
		// }

		this.highlightAya(this.state.sura, this.state.aya);
	}

	shouldComponentUpdate(nextProps,nextState){
		
		return (this.props.store.transData.selectedTrans.length !== nextProps.store.transData.selectedTrans.length || 
			this.props.store.transData.showTranslate !== nextProps.store.transData.showTranslate ||
			this.props.store.transData.transText.length !== nextProps.store.transData.transText.length ||
			// this.props.store.userData.aya!==nextProps.store.userData.aya ||
			// this.props.store.userData.sura!==nextProps.store.userData.sura ||
			this.props.store.userData.theme!==nextProps.store.userData.theme ||
			this.props.store.userData.langInfo.lang!==nextProps.store.userData.langInfo.lang ||
			this.props.store.quranData.edition !== nextProps.store.quranData.edition ||
			this.props.store.quranData.quranText.length !== nextProps.store.quranData.quranText.length||
            !compareObjects(nextState,this.state)
		)
	}
	
	static getDerivedStateFromProps(nextProps,state) {
		
		//there are two different situation: 
		//1.user insert sura,aya and lang in url bar
		//2.system change sura,aya and lang via interface(e.g. next page button)
		//3.system use saved sura, aya and lang and redirect to proper url
		//in first one current params are prior to saved data and we use them and validate them before use them
		//in second one system push new url via history and reroute and system set url params and we use them
		//in third one system set saved data in params
		//in each of above, params have currect version of sura, aya and lang
		
		let sura,aya,langFromUrl;
		//when some sura,aya or lang are invalid we should use a default valid one
		//in this situation we set pushToHistory to true because update url to new value
		let pushToHistory=false;

		//we are sure about current url, it has ./lang/sura:aya format
		//load lang, sura, aya from url
		sura=parseInt(nextProps.match.params.id.split(':')[0]);
		aya=parseInt(nextProps.match.params.id.split(':')[1]);
		langFromUrl=LangInfo[nextProps.match.params.lang];

		//validate sura, aya and lang 
		if (sura >= 1 && sura <= 114) {
			let suraData = QuranMeta.getSuraDetails(sura);
			if (aya < 1 || aya > parseInt(suraData.ayas)) {
				//invalid aya, use value in localstorage
				aya=nextProps.store.userData.aya;
				pushToHistory=true;
			}
		}else{//invalid sura, use value in localstorage
			sura=nextProps.store.userData.sura;
			aya=nextProps.store.userData.aya;
			pushToHistory=true;
		}
		//if language from url (user entry) is invalid then use stored langInfo  and refine url else use it
		//or if current lang is different from nextProps lang use nextProps lang (may occer in first time)
		if(!langFromUrl || state.langInfo.lang!==nextProps.store.userData.langInfo.lang){
			langFromUrl=nextProps.store.userData.langInfo;
			pushToHistory=true;
		}

		//if sura, aya or lang are modified then change url to correct one
		if(pushToHistory)
			nextProps.history.push(("/" + langFromUrl.lang + "/" + sura + ":" + aya));

		//if lang from url is same as current lang then use same langInfo not langFromUrl
		let notLangChanged=(langFromUrl.lang===state.langInfo.lang) &&
						  (langFromUrl.direction===state.langInfo.direction) &&
						  (langFromUrl.font===state.langInfo.font) &&
						  (langFromUrl.displayName===state.langInfo.displayName);
		return  {
			sura,
			aya,
			langInfo:(notLangChanged ? state.langInfo:langFromUrl)
		};

	}

	selectByLang(rtl,ltr){
        return (this.props.store.userData.langInfo.direction === 'rtl') ? rtl : ltr;
    }
	

	goTo(lang, sura, aya) {
		this.props.history.push("/" + lang + "/" + sura + ":" + aya);
		this.props.actions.changeSura(parseInt(sura));
		this.props.actions.changeAya(parseInt(aya));
	}

	/* ------- Handle Quran Functionality ------- */
	highlightAya(sura, aya) {

		let currentAyaId = sura + '-' + aya;

		setTimeout(() => {
			var elems = document.querySelectorAll('.aya');

			[].forEach.call(elems, function (el) {
				el.classList.remove("aya-active");
			});

			let currentAya = document.getElementById(currentAyaId);
			if (currentAya != null) {
				currentAya.classList.add("aya-active");
				smoothscrolling.scrollTo(currentAyaId);
			};
		}, 100)
	}
	
	/* ------- Handle Quran Navigation and User Manipulatios ------- */
	clickOnAya(sura, aya) {
		this.goTo(this.props.store.userData.langInfo.lang, sura, aya);
	}

	/* ------- Prepare Display Functions ------- */
	generateSuraName(sura, aya, suraname) {
		if (aya === 1) {
			return (
				<div className="sura-name">
					<img src={'../../img/titles/' + this.toFileName(sura) +'.svg'} alt={suraname}/>
				</div>
			);
		}
		return (
			<span></span>
		);
	}

	toFileName(file) {
		let item = "" + file;
		if (item.length === 1) {
			item = "00" + item;
		};
		if (item.length === 2) {
			item = "0" + item;
		};
		return item;
	}

	generateBismillah(sura, aya) {
		if (aya === 1 && sura !== 1 && sura !== 9) {
			return (
				<div className="bismillah">بِسْمِ اللَّـهِ الرَّحْمَـٰنِ الرَّحِيمِ</div>
			);
		};
		return (
			<span></span>
		);
	}

	applyTajwidColors(ayaText){
		if(!ayaText){
			return;
		}
		//if text edition is tajwid then apply tajwid color rules
		if(this.props.store.quranData.edition==='tj'){
			//without tajweed
			if(!this.props.store.quranData.quranTajwid.useTajwidColor){
				return ayaText.replaceAll(/\[.{1}:?\d*\[/g,'').replaceAll(/\]/g,"");
			}
			//hamzat Ul Wasl
			return ayaText
				.replaceAll(/\[h:{1}\d+\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.hamzatUlWasl + ';>')
				.replaceAll(/\[s\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.silent + ';>')
				.replaceAll(/\[l\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.lamShamsiyah + ';>')
				.replaceAll(/\[n\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.maddaNormal + ';>')
				.replaceAll(/\[p\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.maddaPermissible + ';>')
				.replaceAll(/\[m\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.maddaNecessary + ';>')
				.replaceAll(/\[q:?\d*\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.qalaqah + ';>')
				.replaceAll(/\[o\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.maddaObligatory + ';>')
				.replaceAll(/\[c:{1}\d+\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.ikhafaShafawi + ';>')
				.replaceAll(/\[f:{1}\d+\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.ikhafa + ';>')
				.replaceAll(/\[w:{1}\d+\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.idghamShafawi + ';>')
				.replaceAll(/\[i:{1}\d+\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.iqlab + ';>')
				.replaceAll(/\[a:?\d*\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.idghamWithGhunnah + ';>')
				.replaceAll(/\[u:{1}\d+\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.idghamWithoutGhunnah + ';>')
				.replaceAll(/\[d:{1}\d+\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.idghamMutajanisayn + ';>')
				.replaceAll(/\[b:{1}\d+\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.idghamMutaqaribayn + ';>')
				.replaceAll(/\[g\[/g,'<span style=color:' + this.props.store.quranData.quranTajwid.ghunnah + ';>')
				.replaceAll(/\]/g,'</span>');
		}else{//if text edition is NOT tajwid then return aya itself
			return ayaText;
		}
	}

	generatePageContent(item, index) {
		function createMarkup(item) {
			return { __html: item };
		}
		function generateHr() {
			return (<Divider className="DividerStyle" />);
		}

		let transMeta = this.props.store.transData.selectedTrans;
		let ayaText=this.applyTajwidColors(item.text);
		
		return (
			<span key={index}>
				{/* aya text    */}
				<span>
					{this.generateSuraName(item.meta.sura, item.meta.aya, item.meta.suraname)}
					{this.generateBismillah(item.meta.sura, item.meta.aya)}
					<span className="aya" id={`${item.meta.sura}-${item.meta.aya}`}
						dangerouslySetInnerHTML={createMarkup(ayaText)}
						style={this.props.store.quranData.quranDisplay}
					></span>
					<span onClick={()=>{this.clickOnAya(item.meta.sura, item.meta.aya)}} 
							className="ayaNumber">{`﴿ ${convertToArabicNumber(item.meta.aya)} ﴾`}
					</span>
				</span>

				{/* aya translation text */}
				<span style={{display:this.props.store.transData.showTranslate?'inline':'none'}}>
					{
						item.trans.map((tr, i) => {
							//let data = transMeta[i];
							let data=transMeta.find((tt)=>tr.id===tt.id)
							if (typeof data === undefined) {
								return null;
							};

							return (
								tr.text?
								<span key={i} >
									<div
										className={"translate ".concat(data['direction']==='rtl'?'translate_rtl':'translate_ltr')}
										style={this.props.store.transData.translateDisplay}
									>
										<img width="20" src={`../../img/flags/4x3/${data['flag']}.svg`} alt={data['language_ln']} />&nbsp;
										<b>{data['name_ln']} : </b>
										<span className="ng-binding">
											{tr.text}
										</span>
									</div>

									{(i + 1 === this.props.store.transData.selectedTrans.length) ? generateHr() : null}
								</span>
								:<span key={i} ><div><CircularProgress /></div></span>
							);
						}
						, this)
					}
				</span>
			</span>
		);
	}

	/* ------- Render Function ------- */
	render() {
		
		//this.highlightAya(this.state.sura, this.state.aya);

		//determine component ids based on drawer status
		let wrapperStyle = "";
		let contentStyle = "";
		let footerStyle='';
		if (this.state.isDrawerOpen) {
			wrapperStyle = "wrapper_od ".concat(this.selectByLang('wrapper_rtl','wrapper_ltr'));
			contentStyle = "content_od ".concat(this.props.store.userData.theme==='light'?'content_light':'content_dark');
			footerStyle='footer_od '.concat(this.selectByLang('footer_rtl','footer_ltr'));
		} else {
			wrapperStyle = "wrapper_cd";
			contentStyle = "content_cd ".concat(this.props.store.userData.theme==='light'?'content_light':'content_dark');;
		}

		let drawerState = {
			isDrawerOpen: this.state.isDrawerOpen,
			toggleDrawer: () => { this.setState({ isDrawerOpen: !this.state.isDrawerOpen }); }
		}
		
		let page = 1;//page number
		let PageIndexes={start:1,end:7};//first and last total aya number in page
		let PageFirstSA={sura:1,aya:1};//first sura and aya of page
		try{
			page=QuranMeta.SAToPage(this.state.sura, this.state.aya);
			PageIndexes=QuranMeta.getPageStartEndAya(this.state.sura,this.state.aya);
			PageFirstSA = QuranMeta.pageToSA(page);
		}catch(er){}

		//array of objects, each object has a text for aya text and an array of texts
		//for aya translations and meta for each aya
		let pageContent=[];

		let translatetext = this.props.store.transData.transText;
		let selectedTrans=this.props.store.transData.selectedTrans;
		let quranText=this.props.store.quranData.quranText;

		let pageSura = PageFirstSA.sura;
		let pageAya = PageFirstSA.aya;

		//extract text of current page
		for (let i = PageIndexes.start; i < PageIndexes.end; i++) {
			let ithAya=quranText[i];
			let ithTrans=[];
			// translatetext.forEach(function(trans){
			// 	ithTrans.push({id:trans.id,text:trans.text[i]});
			// });
			selectedTrans.forEach(function(trans){
				let transText=translatetext.find((tt)=>tt.id===trans.id);
				ithTrans.push({id:trans.id,text:(transText ? transText.text[i]:null)});
			});
			
			let suraData = QuranMeta.getSuraDetails(pageSura);

			//pageMeta contains record for each aya in page
			let ithMeta={
				'suraname' : suraData.name,
				'sura' : pageSura,
				'aya' : pageAya
			};
			
			//pageContent has {text:string , trans: array of {id,text}, {suraname,sura,aya}}
			pageContent.push({text:ithAya,trans:ithTrans,meta:ithMeta});

			//prepare meta for next aya
			if (pageAya < suraData.ayas) {
				pageAya++;
			}else{
				pageSura++;
				pageAya = 1;
			}
		};

		return (
			<React.Fragment>

				<ActionBar drawerState={drawerState} />

				<section id={"wrapper"} className={wrapperStyle}>

					<section id="nav"></section>
					
					<QuranHeader id='wrapper' drawerState={drawerState} />

					<section id={'content'} className={contentStyle} >

						<div id="reading-mode" style={this.props.store.quranData.quranDisplay}>

							{
								(
									this.props.store.quranData.quranText &&
									this.props.store.quranData.quranText.length===6236
								)
								?
								pageContent.map(this.generatePageContent)
								:
								<CircularProgress id="quranProgress"/>
							}
						</div>
					</section>

				</section>

				<ReciteBar pageMeta={pageContent} drawerState={drawerState}/>

				<footer id="footer" className={footerStyle}>
					<Typography variant='caption' color='textSecondary'>©2017 Forghan</Typography>
				</footer>

			</React.Fragment>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		'store':{
			'quranData': state.quranData,
			'transData': {
				transText : state.transData.transText,
				showTranslate:state.transData.showTranslate,
				selectedTrans:state.transData.selectedTrans,
			},
			userData : {
                langInfo : state.userData.langInfo,
                theme:state.userData.theme,
				sura:state.userData.sura,
				aya:state.userData.aya,
            },
		}
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(quranActions, dispatch)
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Quran));
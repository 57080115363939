/* eslint-disable no-console */
import React from 'react';
import {render} from 'react-dom';
import './styles/styles.css';
import App from './containers/App';

render(
	<App/>,
	document.getElementById('app')
); 


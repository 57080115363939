import * as types from '../actions/actions';

export default function quranTextReducer(state = [], action) {
	// console.log(JSON.stringify(action.payload));
	switch(action.type){

		case types.LOAD_QURANTEXT_SUCCESS:
      		return {...state,quranText : action.payload.quranText,edition:action.payload.edition};
			  
		case types.CHANGE_QURAN_DISPLAY_OPTIONS:{
			return {...state,quranDisplay:action.payload};
		}
		case types.SET_TAJWID_COLORS:{
			return {...state,quranTajwid:action.payload}
		}
		default:
      		return state;
	}
}
import {Box, Button, CircularProgress, Dialog, DialogTitle, IconButton, Snackbar, TextField, Typography} from '@material-ui/core';
import React from 'react';
import {connect} from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import env from '../../env.js';
import convertToArabicNumbers from '../../utility/convertToArabicNumber';
import { bindActionCreators } from 'redux';
import * as quranActions from '../../actions/actionCreators';
import { withRouter } from 'react-router-dom'
import compareObjects from '../../utility/compareObjects.js';

class JoinKhatamDialog extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isOpen:props.open,
            amount:1,
            waitForServer:false,
            serverOk:false,
            from1:{aya:0},
            to1:{aya:0},
            from2:{aya:0},
            to2:{aya:0},
            snack:{open:false,msg:''},
        }

        this.handleCloseDialog=this.handleCloseDialog.bind(this);
        this.khatamJoinRequest=this.khatamJoinRequest.bind(this);
        this.goTo=this.goTo.bind(this);
    }

    shouldComponentUpdate(nextProps,nextState){
		
		return (
			this.props.store.userData.langInfo.lang!==nextProps.store.userData.langInfo.lang ||
            !compareObjects(nextState,this.state)
		)
	}


    static getDerivedStateFromProps(np,cs){
        return {isOpen:np.open};
    }

    handleCloseDialog(){
        this.props.onClose();
        this.setState({amount:1});
    }

    goTo(lang, sura, aya) {
		this.props.history.push("/" + lang + "/" + sura + ":" + aya);
		this.props.actions.changeSura(parseInt(sura));
		this.props.actions.changeAya(parseInt(aya));
	}

    khatamJoinRequest(){
        this.setState({waitForServer:true});

        if(!this.state.amount || this.state.amount.length===0 || this.state.amount.length<1){
            this.setState({snack:{open:true,msg:'must have an amount'}});
            return;
        }
        if((!this.props.khatam || !this.props.khatam.uuid)){
            this.setState({snack:{open:true,msg:'invalid khatam'}});
            return;
        }

        axios.post(env.baseURL+'/join_khatam',
            {khatamUuid:this.props.khatam.uuid,amount:this.state.amount,username:this.props.store.userData.login.username}
        )
        .then((result)=>{
            
            if(!result || !result.data || !result.data.part1){
                this.setState({serverOk:false,snack:{open:true,msg:'no data error! Call Ehsan'}})
            }else{
                this.setState({
                    serverOk:true,
                    from1:result.data.part1.from,
                    to1:result.data.part1.to,
                    from2:(result.data.part2?result.data.part2.from:{aya:0}),
                    to2:(result.data.part2?result.data.part2.to:{aya:0}),
                });
            }
            
        })
        .catch((err)=>{this.setState({serverOk:false,snack:{open:true,msg:err.response.data}});})
        .finally(()=>{this.setState({waitForServer:false});});
    }

    render(){
        let slicingName='';
        
        switch(this.props.khatam?this.props.khatam.slicing:0){
            case 604:
                slicingName=this.props.store.userData.locale.page;
                break;
            case 240:
                slicingName=this.props.store.userData.locale.rubElHizb;
                break;
            case 60:
                slicingName=this.props.store.userData.locale.hizb;
                break;
            case 30:
                slicingName=this.props.store.userData.locale.juz;
                break;
            case 7:
                slicingName=this.props.store.userData.locale.manzil;
                break;
            default:
                slicingName='';
        }
        
        return(
            <Dialog open={this.state.isOpen} maxWidth='lg' BackdropProps={{style:{backgroundColor:'#00000000'}}}
                id='newKhatamBackdrop'
                onClose={this.handleCloseDialog}
                PaperProps={{id:'newKhatamDialog'}}
            >
                <Box display='flex'
                    flexDirection={this.props.store.userData.langInfo.direction==='rtl'?'row-reverse':'row'}
                    justifyContent='space-between' alignItems='center'
                >
                    <DialogTitle id='newKhatamTitle'
                        style={{direction:this.props.store.userData.langInfo.direction}}
                        disableTypography
                    >
                        {this.props.store.userData.locale.jazakumullah}
                    </DialogTitle>
                    <IconButton className={'drawerCloseIcon_dark'} onClick={this.handleCloseDialog}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box display='flex'
                    flexDirection={this.props.store.userData.langInfo.direction==='rtl'?'row-reverse':'row'}
                    justifyContent='center' marginTop='3vh' marginBottom='3vh'
                >
                    <TextField variant='outlined' defaultValue='1' type='number'
                        onBlur={(e)=>{this.setState({amount:e.target.value})}}
                        style={{width:'10%'}}
                    />
                    <Typography component='div' variant='body1' style={{direction:this.props.store.userData.langInfo.direction}}
                        style={{margin:'auto 8px'}}
                    >
                        {slicingName + ' ' +this.props.store.userData.locale.dedicatedToYou}
                    </Typography>
                </Box>

                <Box display='flex' justifyContent='center' marginBottom='3vh' 
                    flexDirection={this.props.store.userData.langInfo.direction==='rtl'?'row-reverse':'row'}
                    style={{visibility:(this.state.serverOk && this.state.from1.aya>0 && this.state.to1.aya>0 ?'visible':'hidden')}}
                >
                    <Box display='flex' flexDirection='column' textAlign='center'>
                        <Typography variant='h4'>
                            {this.props.store.userData.langInfo.direction==='rtl'
                                ? 
                                this.state.from1.name_arabic
                                :
                                this.state.from1.name_english}
                        </Typography>
                        <Typography variant='caption'>
                            {this.props.store.userData.langInfo.direction==='rtl'
                                ? 
                                convertToArabicNumbers(this.state.from1.aya)
                                :
                                this.state.from1.aya}
                        </Typography>
                    </Box>
                    <Typography variant='h5' style={{margin:'0 5vw'}}>{this.props.store.userData.locale.to}</Typography>
                    <Box display='flex' flexDirection='column' textAlign='center'>
                        <Typography variant='h4'>
                            {this.props.store.userData.langInfo.direction==='rtl'
                                ? 
                                this.state.to1.name_arabic
                                :
                                this.state.to1.name_english}
                        </Typography>
                        <Typography variant='caption'>
                            {this.props.store.userData.langInfo.direction==='rtl'
                                ? 
                                convertToArabicNumbers(this.state.to1.aya)
                                :
                                this.state.to1.aya}
                        </Typography>
                    </Box>
                </Box>

                <Box display='flex' justifyContent='center' marginBottom='3vh' 
                    flexDirection={this.props.store.userData.langInfo.direction==='rtl'?'row-reverse':'row'}
                    style={{visibility:(this.state.serverOk && this.state.from2.aya>0 && this.state.to2.aya>0 ?'visible':'hidden')}}
                >
                    <Box display='flex' flexDirection='column' textAlign='center'>
                        <Typography variant='h4'>
                            {this.props.store.userData.langInfo.direction==='rtl'
                                ? 
                                this.state.from2.name_arabic
                                :
                                this.state.from2.name_english}
                        </Typography>
                        <Typography variant='caption'>
                            {this.props.store.userData.langInfo.direction==='rtl'
                                ? 
                                convertToArabicNumbers(this.state.from2.aya)
                                :
                                this.state.from2.aya}
                        </Typography>
                    </Box>
                    <Typography variant='h5' style={{margin:'0 5vw'}}>{this.props.store.userData.locale.to}</Typography>
                    <Box display='flex' flexDirection='column' textAlign='center'>
                        <Typography variant='h4'>
                            {this.props.store.userData.langInfo.direction==='rtl'
                                ? 
                                this.state.to2.name_arabic
                                :
                                this.state.to2.name_english}
                        </Typography>
                        <Typography variant='caption'>
                            {this.props.store.userData.langInfo.direction==='rtl'
                                ? 
                                convertToArabicNumbers(this.state.to2.aya)
                                :
                                this.state.to2.aya}
                        </Typography>
                    </Box>
                </Box>

                <Box display='flex' flexDirection='row-reverse'>
                    <Button className="button_dark" disabled={this.state.waitForServer} onClick={this.khatamJoinRequest}
                        style={{visibility:(this.state.serverOk?'hidden':'visible')}}                    
                    >
                            {this.state.waitForServer?
                                <CircularProgress style={{color:'#7BC5BE',width:'100%',height:'100%',
                                    visibility:(this.state.waitForServer ?'visible':'hidden')}}
                                />
                                :
                                this.props.store.userData.locale.requestQuotas
                            }
                    </Button>
                    <Button className="button_dark"
                        onClick={()=>{this.goTo(this.props.store.userData.langInfo.lang,this.state.from1.sura,this.state.from1.aya)}}
                        style={{visibility:(this.state.serverOk?'visible':'hidden')}}
                    >
                        {this.props.store.userData.locale.readQuotas}
                    </Button>
                    <Button className="button_dark" onClick={this.handleCloseDialog}>
                        {this.props.store.userData.locale.close}
                    </Button>
            </Box>
            <Snackbar
                open={this.state.snack.open}
                message={this.state.snack.msg}
                action="Ok"
                autoHideDuration={3000}
                onClose={()=>{this.setState({snack:{open:false,msg:''}})}}
            />
            </Dialog>
        );
    }
}

function mapStateToProps(state, ownProps){
	return {
		'store': {
            userData : {
                login: state.userData.login,
                langInfo : state.userData.langInfo,
                locale : {
                    jazakumullah: state.userData.locale.jazakumullah,
                    dedicatedToYou:state.userData.locale.dedicatedToYou,
                    juz:state.userData.locale.juz,
                    page:state.userData.locale.page,
                    hizb:state.userData.locale.hizb,
                    rubElHizb:state.userData.locale.rubElHizb,
                    manzil:state.userData.locale.manzil,
                    to:state.userData.locale.to,
                    cancel:state.userData.locale.cancel,
                    readQuotas:state.userData.locale.readQuotas,
                    requestQuotas:state.userData.locale.requestQuotas,
                    close:state.userData.locale.close,
                },
            },
        }
	};
}

function mapDispatchToProps(dispatch){
	return {
		actions: bindActionCreators(quranActions, dispatch)
	};
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(JoinKhatamDialog));
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as quranActions from '../../actions/actionCreators';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import LanguageIcon from '@material-ui/icons/Language';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router-dom';
import langInfo from '../../locale/langInfo';
import compareObjects from '../../utility/compareObjects';

class LanguagesMenu extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            anchorEl : null,
            iconStyle:(props.store.userData.theme==='light'?'appbarIcon_light':'appbarIcon_dark'),
            menuItems:[],
            selectedLang:this.props.store.userData.langInfo.lang
        }

        for(let lang in langInfo){
            this.state.menuItems.push(<MenuItem 
                                key={langInfo[lang].displayName}
                                className={this.languageDir('listItem_rtl','listItem_ltr')}
                                onClick={()=>{this.changeLanguage(langInfo[lang])}}
                                selected={this.state.selectedLang===lang}
                            >
                                {/* <img className="flag" src="../../img/flags/4x3/gb.svg" alt="En" /> */}
                                &nbsp; {langInfo[lang].displayName}
                            </MenuItem>
            );
        }


        this.languageDir = this.languageDir.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    shouldComponentUpdate(nextProps,nextState){

		return(
			// this.props.store.userData.langInfo.lang!==nextProps.store.userData.langInfo.lang||
			// this.props.store.userData.theme!==nextProps.store.userData.theme ||
            !compareObjects(nextState,this.state));
	}

    static getDerivedStateFromProps(nextProps,state) {
        let mi=state.menuItems.map(el=>
            ({...el,props:{...el.props,selected:nextProps.store.userData.langInfo.displayName===el.key}})
        );
        return {
            selectedLang:nextProps.store.userData.langInfo.lang,
            menuItems:mi
        }
    }

    changeLanguage(langInfo) {
        
        this.props.history.push("/" + langInfo.lang + "/"+this.props.caller);
        this.setState({anchorEl:null});
        this.props.actions.changeLanguage(langInfo);
        
    }

    languageDir(rtl,ltr){
        return (this.props.store.userData.langInfo.lang !== undefined && this.props.store.userData.langInfo.direction === 'rtl') ?
            rtl : ltr;
    }

 

    render() {
        
        return (
            <React.Fragment>
                <Tooltip title={this.props.store.userData.locale.language}
                    classes={{tooltip:'tooltip'}}
                >
                    <IconButton 
                        onClick={(event)=>{this.setState({anchorEl:event.currentTarget});}}
                        aria-controls="simple-menu" aria-haspopup="true"
                        className={'appbarIcon '.concat(this.state.iconStyle)}
                    >
                        <LanguageIcon fontSize='small'/>
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    anchorOrigin={{vertical: 'top',horizontal: 'center'}}
                    getContentAnchorEl={null}
                    onClose={()=>{this.setState({anchorEl:null})}}
                    variant='menu'
                >
                    {this.state.menuItems}
                </Menu>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state, ownProps){
    return {
        'store':{
            'userData':  {
                langInfo:state.userData.langInfo,
                locale:{
                    language:state.userData.locale.language,
                },
				theme:state.userData.theme
            },
        }        
    };
}

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(quranActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguagesMenu));
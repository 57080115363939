var SmoothScrolling = {
	timer: null,
  
	stop: function() {
	  clearTimeout(this.timer);
	},
  
	scrollTo: function(id, callback) {
	  var settings = {
		duration: 100,
		easing: {
		  outQuint: function(x, t, b, c, d) {
			return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
		  }
		}
	  };
	  var percentage;
	  var startTime;
	  var node = document.getElementById(id);
	  var nodeTop = node.offsetTop;
	  var nodeHeight = node.offsetHeight;
	  var body = document.body;
	  var html = document.documentElement;
	  var height = Math.max(//height of page
		body.scrollHeight,//e.g. 1900
		body.offsetHeight,//e.g. 1900
		html.clientHeight,//e.g. 1200 screen size
		html.scrollHeight,//e.g. 2000
		html.offsetHeight//e.g. 2000
	  );
	  
		var windowHeight = window.innerHeight;
		var offset = window.pageYOffset;//how much page scrolled, 0 means scroll is on top of page, in other word no scrolled
		
		let visibleStart=offset;//start of visible frame
		let visibleEnd=visibleStart+windowHeight;//end of visible frame

		let targetY=0;

		let newStart=0;//new start position of visible frame(default is 0)
		let newEnd=windowHeight;//new end position of visible frame(default is windowHeight)

		if(nodeTop>=(windowHeight/2) && (height-nodeTop)>=(windowHeight/2)){//node can be in the middle of visible frame
			newStart=nodeTop-(windowHeight/2);
			newEnd=nodeTop+(windowHeight/2);
		}else if(nodeTop<(windowHeight/2)){
			newStart=0;
			newEnd=windowHeight;
		}else if((height-nodeTop)<(windowHeight/2)){
			newStart=height-windowHeight;
			newEnd=height;
		}else{
			newStart=0;
			newEnd=windowHeight;
		}

		targetY=newStart-visibleStart;

	//   var delta = nodeTop - offset;
	//   var bottomScrollableY = height - windowHeight;
	//   var targetY =
	// 	bottomScrollableY < delta
	// 	  ? bottomScrollableY - (height - nodeTop - nodeHeight + offset)
	// 	  : delta;

	  startTime = Date.now();
	  percentage = 0;
  
	  if (this.timer) {
		clearInterval(this.timer);
	  }
  
	  function step() {
		var yScroll;
		var elapsed = Date.now() - startTime;
  
		if (elapsed > settings.duration) {
		  clearTimeout(this.timer);
		}
  
		percentage = elapsed / settings.duration;
  
		if (percentage > 1) {
		  clearTimeout(this.timer);
  
		  if (callback) {
			callback();
		  }
		} else {
		  yScroll = settings.easing.outQuint(
			0,
			elapsed,
			// offset-70,
			// targetY-70,
			offset,
			targetY,
			settings.duration
		  );
		  window.scrollTo(0, yScroll);
		  this.timer = setTimeout(step, 10);
		}
	  }
  
	  this.timer = setTimeout(step, 10);
	}
  };
  
  export default SmoothScrolling;
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as quranActions from '../../actions/actionCreators';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import AppsIcon from '@material-ui/icons/Apps';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router-dom';
import compareObjects from '../../utility/compareObjects';

class ServicesMenu extends React.Component{
	constructor(props) {
		super(props);

		this.state={
			anchorEl:null,
			iconStyle:(props.store.userData.theme==='light'?'appbarIcon_light':'appbarIcon_dark')
		};
		this.languageDir = this.languageDir.bind(this);
		this.goToQuran = this.goToQuran.bind(this);
		this.goToIstikharah = this.goToIstikharah.bind(this);
		this.goToKhatam = this.goToKhatam.bind(this);
	}

	shouldComponentUpdate(nextProps,nextState){

		return(
			//this.props.store.userData.sura!==nextProps.store.userData.sura ||
			// this.props.store.userData.aya!==nextProps.store.userData.aya ||
			// this.props.store.userData.theme!==nextProps.store.userData.theme ||
			//this.props.store.userData.langInfo.lang!==nextProps.store.userData.langInfo.lang||
            !compareObjects(nextState,this.state));
	}

	goToQuran() {
		let sura = parseInt(this.props.store.userData.sura);
		let aya = parseInt(this.props.store.userData.aya);
		let lang = this.props.store.userData.langInfo.lang;

		this.props.history.push("/" + lang + "/" + sura + ":" + aya);
		this.props.actions.changeSura(parseInt(sura));
		this.props.actions.changeAya(parseInt(aya));
	}

	goToIstikharah(){
  		let lang = this.props.store.userData.langInfo.lang;
		this.props.history.push("/" + lang + "/istikharah");
	}

	goToKhatam(){
		let lang = this.props.store.userData.langInfo.lang;
		this.props.history.push("/" + lang + "/khatam");
	}

	languageDir(rtl,ltr){
        return (this.props.store.userData.langInfo.lang !== undefined && this.props.store.userData.langInfo.direction === 'rtl') ?
            rtl : ltr;
    }

	render() {
		
		return (
			<React.Fragment>
				<Tooltip title={this.props.store.userData.locale.services}
                    classes={{tooltip:'tooltip'}}
                >
					<IconButton 
						onClick={(event)=>{this.setState({anchorEl:event.currentTarget});}}
						className={'appbarIcon '.concat(this.state.iconStyle)}
					>
						<AppsIcon fontSize='small'/>
					</IconButton>
				</Tooltip>
				<Menu
					anchorEl={this.state.anchorEl}
					open={Boolean(this.state.anchorEl)}
					anchorOrigin={{vertical: 'top',horizontal: 'center'}}
					getContentAnchorEl={null}
					onClose={()=>{this.setState({anchorEl:null})}}
					variant='menu'
				>
					<MenuItem 
						className={this.languageDir('listItem_rtl','listItem_ltr')}
						onClick={this.goToQuran}
						selected={!this.props.history.location.pathname.includes('istikharah') && !this.props.history.location.pathname.includes('khatam')}
					>
						{this.props.store.userData.locale.quran}
					</MenuItem>
					<MenuItem 
						className={this.languageDir('listItem_rtl','listItem_ltr')}
						onClick={this.goToIstikharah}
						selected={this.props.history.location.pathname.includes('istikharah')}
					>
						{this.props.store.userData.locale.ist_istikharah}
					</MenuItem>
					<MenuItem 
						className={this.languageDir('listItem_rtl','listItem_ltr')}
						onClick={this.goToKhatam}
						selected={this.props.history.location.pathname.includes('khatam')}
					>
						{this.props.store.userData.locale.khatam}
					</MenuItem>
				</Menu>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state, ownProps){
    return {
		'store':{
			'userData':  {
                langInfo:state.userData.langInfo,
				sura:state.userData.sura,
				aya:state.userData.aya,
                locale:{
                    services:state.userData.locale.services,
                    quran:state.userData.locale.quran,
                    ist_istikharah:state.userData.locale.ist_istikharah,
                    khatam:state.userData.locale.khatam,
                },
				theme:state.userData.theme
            },
		}
    };
}

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(quranActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServicesMenu));
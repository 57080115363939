import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as quranActions from '../../actions/actionCreators';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import env from '../../env.js';
import ActionBar from './ActionBar';
import SliderIst from './Slider';
import { Box } from '@material-ui/core';
import LangInfo from '../../locale/langInfo';
import compareObjects from '../../utility/compareObjects';

const Connector = () => (
	<div className="connector"></div>
)


class Istikharah extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
	    	stepIndex: 0,
			istikharah: {
			  "sura": "",
			  "aya": "",
			  "sura_number": null,
			  "aya_number": null,
			  "result": {
			    "fa": ""
			  },
			  "translates": {
			    "fa": ""
			  }
			},
			//we have langInfo in state in order to compare it with nextProps
			langInfo:props.store.userData.langInfo,
	  };

	  this.handleNext = this.handleNext.bind(this);
	  this.handlePrev = this.handlePrev.bind(this);
	  this.goToQuran = this.goToQuran.bind(this);
	  this.getIstakharah = this.getIstakharah.bind(this);
	  this.selectBylangDir = this.selectBylangDir.bind(this);
	}

	shouldComponentUpdate(nextProps,nextState){
		return(
			this.props.store.userData.langInfo.lang!==nextProps.store.userData.langInfo.lang ||
            !compareObjects(nextState,this.state)
		)
	}

	static getDerivedStateFromProps(nextProps,state) {
		let langFromUrl;
		//when lang is invalid we should use a default valid one
		//in this situation we set pushToHistory to true because update url to new value
		let pushToHistory=false;

		langFromUrl=LangInfo[nextProps.match.params.lang];

		//if language from url (user entry) is invalid then use stored langInfo  and refine url else use it
		//or if current lang is different from nextProps lang use nextProps lang (may occer in first time)
		if(!langFromUrl || state.langInfo.lang!==nextProps.store.userData.langInfo.lang){
			langFromUrl=nextProps.store.userData.langInfo;
			pushToHistory=true;
		}

		//if  lang is modified then change url to correct one
		if(pushToHistory)
			nextProps.history.push(("/" + langFromUrl.lang + "/istikharah"));

		//if lang from url is same as current lang then use same langInfo not langFromUrl
		let notLangChanged=(langFromUrl.lang===state.langInfo.lang) &&
						  (langFromUrl.direction===state.langInfo.direction) &&
						  (langFromUrl.font===state.langInfo.font) &&
						  (langFromUrl.displayName===state.langInfo.displayName);

		return  {
			langInfo:(notLangChanged ? state.langInfo:langFromUrl)
		};
	}

	componentDidMount(){
		
		// this.props.actions.changeLanguage(this.state.langInfo);

		if((this.props.store.userData.langInfo.lang!==this.state.langInfo.lang) ||
				(this.props.store.userData.langInfo.direction!==this.state.langInfo.direction) ||
				(this.props.store.userData.langInfo.font!==this.state.langInfo.font) ||
				(this.props.store.userData.langInfo.displayName!==this.state.langInfo.displayName)){
			this.props.actions.changeLanguage(this.state.langInfo);
		}
		
	}

	handleNext() {
    const {stepIndex} = this.state;
    this.setState({
      stepIndex: stepIndex + 1,
    });
  };

  handlePrev() {
    const {stepIndex} = this.state;
    if (stepIndex > 0) {
      this.setState({stepIndex: stepIndex - 1});
    }
  };

  getIstakharah() {
  	axios.get(env.baseURL + '/istikharah').then(istikharah => {
			this.setState({istikharah: istikharah.data});
			this.handleNext();
		}).catch(error => {
			throw(error);
		});
			
		
  }

  goToQuran() {
  	let sura = parseInt(this.state.istikharah.sura_number);
  	let aya = parseInt(this.state.istikharah.aya_number);
  	let lang = this.props.store.userData.langInfo.lang;

	  this.props.history.push("/" + lang + "/" + sura + ":" + aya);
		this.props.actions.changeSura(parseInt(sura));
		this.props.actions.changeAya(parseInt(aya));	
		
  }

  selectBylangDir(rtl,ltr){
	return (this.props.store.userData.langInfo.lang !== undefined && this.props.store.userData.langInfo.direction === 'rtl') ?
		rtl : ltr;
	}

  render() {

	const {stepIndex} = this.state;

	let istResLang=(this.props.store.userData.langInfo.lang==='en')?'fa':this.props.store.userData.langInfo.lang;

    return (
    	<React.Fragment>
			<SliderIst />
    		<ActionBar />
	    	
			<Stepper 
				id="ist_holder"
				activeStep={stepIndex}
				orientation="vertical"
				connector={<Connector />}
			>
	          <Step >
	            <StepLabel StepIconProps={{classes:{root:"istStepActiveIcon_light"}}} >
					{ this.props.store.userData.locale.ist_ceremonial }
				</StepLabel>
	            <StepContent className="istStepContent_rtl">
					<p>
					بعد از طهارت و گرفتن وضو<br/>  ۱:  سه مرتبه قرائت سوره اخلاص و ذکر صلوات<br/> ۲:  اللّهم إنی أسئلک بحقّ فاطمه و أبیها بَعلها و بَنیها و السّر المستودع فیها أن تصلی علی محمّد و آل محمّد و أن تنوّر بصری و بصیرتی و سرّی سریرتی بحقّهم یا أرحم الراحمین <br/>۳:  سه مرتبه « یا اللّه کما تَراه »
					</p>
					<Button
						variant="contained"
						disableTouchRipple={true}
						disableFocusRipple={true}
						primary={"true"}
						onClick={this.getIstakharah}
						className='button_light'
					>
						{ this.props.store.userData.locale.ist_istikharah }
					</Button>
	            </StepContent>
	          </Step>
	          <Step >
	            <StepLabel StepIconProps={{classes:{root:"istStepActiveIcon_light"}}}>
					{ this.props.store.userData.locale.ist_result }
				</StepLabel>
	            <StepContent className="istStepContent_rtl">
					<Box display='flex' m='20px'>
						<Avatar src="../img/sadeghitehrani.png" />
						<Box  display='flex' justifyContent='center' alignItems='center' mx='10px'>
						{(this.state.istikharah.sura === "") ?
							null : this.state.istikharah.result[istResLang]};
						</Box>
					</Box>
	              	<Button
						variant="contained"
						disableTouchRipple={true}
						disableFocusRipple={true}
						primary={"true"}
						onClick={this.handleNext}
						className='button_light'
					>
						{ this.props.store.userData.locale.ist_aya_display }
					</Button>
					<Button
						disabled={stepIndex === 0}
						disableTouchRipple={true}
						disableFocusRipple={true}
						onClick={this.handlePrev}
						className='button_light'
					>
						{ this.props.store.userData.locale.ist_repeat }
					</Button>
	            </StepContent>
	          </Step>
	          <Step >
	            <StepLabel StepIconProps={{classes:{root:"istStepActiveIcon_light"}}}>{ this.props.store.userData.locale.ist_aya_display }</StepLabel>
	            <StepContent className="istStepContent_rtl">
	              <p>{(this.state.istikharah.sura === "") ? null : this.state.istikharah.aya}</p>
	              <p>{(this.state.istikharah.sura === "") ? null : this.state.istikharah.translates[this.props.store.userData.langInfo.lang]}</p>
				        <a href={ '/' + this.props.store.userData.langInfo.lang + '/' + this.state.istikharah.sura_number + ':' + this.state.istikharah.aya_number } target="_blank" rel='noopener noreferrer'>
				        	<Button
								variant="contained"
								disableTouchRipple={true}
								disableFocusRipple={true}
								primary={"true"}
								className='button_light'
					        >
								{ this.props.store.userData.locale.ist_page_display }
							</Button>
				        </a>
			          <Button
			            disabled={stepIndex === 0}
			            disableTouchRipple={true}
			            disableFocusRipple={true}
						onClick={this.handlePrev}
						className='button_light'
			          >
						{ this.props.store.userData.locale.ist_result_display }
					  </Button>
	            </StepContent>
	          </Step>
	        </Stepper>

		  </React.Fragment>
    );
  }
} 

function mapStateToProps(state, ownProps){
    return {
		store:{
			'userData':  {
				locale:state.userData.locale,
				langInfo:state.userData.langInfo
			}
		}
        
    };
}

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(quranActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Istikharah));
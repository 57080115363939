import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import DisplayOption from './DisplayOption';
import DarkMood from '@material-ui/icons/WbIncandescent';
import QuranMeta from '../../quranMeta/QuranMeta';
import {connect} from 'react-redux';
import ImportContactIcon from '@material-ui/icons/LocalLibrary';
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import {bindActionCreators} from 'redux';
import * as quranActions from '../../actions/actionCreators';
import Checkbox from '@material-ui/core/Checkbox';
import compareObjects from '../../utility/compareObjects';

class QuranHeader extends React.Component{

    constructor(props){
        super(props);

        this.state={
            lastYPosition:window.pageYOffset,
            showHeader:true,
        }
        this.handleReadingMode=this.handleReadingMode.bind(this);
        this.handleScroll=this.handleScroll.bind(this);
    }
    
    componentDidMount(){
        window.addEventListener('scroll',this.handleScroll);
    }
    
    handleScroll(e){
        if(this.state.lastYPosition>window.pageYOffset && !this.state.showHeader){
            this.setState({showHeader:true,lastYPosition:window.pageYOffset});
        }else if(this.state.lastYPosition<window.pageYOffset && this.state.showHeader){
            this.setState({showHeader:false,lastYPosition:window.pageYOffset});
        }
    }

    handleReadingMode(){
        this.props.actions.setShowTranslation(!this.props.store.transData.showTranslate);
    }
    componentWillUnmount(){
        window.removeEventListener('scroll',this.handleScroll);
    }

    shouldComponentUpdate(nextProps,nextState){
		return(
			this.props.store.userData.sura!==nextProps.store.userData.sura ||
			this.props.store.transData.showTranslate!==nextProps.store.transData.showTranslate ||
            this.props.store.userData.theme!==nextProps.store.userData.theme ||
			this.props.store.userData.langInfo.lang!==nextProps.store.userData.langInfo.lang ||
            !compareObjects(nextState,this.state)
		)
	}

    render(){
        
        //determine component class based on drawer status
		let quranPageHeaderStyle = "";

        //determine component class based on language dir
        quranPageHeaderStyle=''.concat(this.props.store.userData.langInfo.direction==='ltr'?"quranPageHeader_ltr ":"quranPageHeader_rtl ")
                            .concat(this.props.store.userData.theme==='light'?'quranPageHeader_light ':'quranPageHeader_dark ')
                            .concat(this.props.drawerState.isDrawerOpen?"quranPageHeader_od":"quranPageHeader_cd");

        let quranHeaderIconsStyle=this.props.store.userData.langInfo.direction === 'rtl'?'quranPageHeaderIcons_rtl':'quranPageHeaderIcons_ltr';

        let iconStyle=(this.props.store.userData.theme==='light'?'quranHeaderIcon_light':'quranHeaderIcon_dark')
        
        let suraDetails=undefined;
        try{
            suraDetails=QuranMeta.getSuraDetails(this.props.store.userData.sura);
        }catch(er){
            suraDetails=QuranMeta.getSuraDetails(1);
        }

        return(
            <Slide appear={false} direction="down" in={this.state.showHeader}>
                <AppBar id='quranPageHeader' classes={{root:quranPageHeaderStyle}}>
                    <div id="quranPageHeaderIcons" className={quranHeaderIconsStyle}>
                        <Tooltip title={this.props.store.userData.locale.nightmode}
                            classes={{tooltip:'tooltip'}}
                        >
                            <IconButton
                                disabled={false}
                                className={'quranHeaderIcon '.concat(iconStyle)}
                            >
                                <DarkMood />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={this.props.store.userData.locale.readingMode}
                            classes={{tooltip:'tooltip'}}
                        >
                            <Checkbox
                                defaultChecked={!this.props.store.transData.showTranslate}
                                checkedIcon={<IconButton className={'quranHeaderIcon '.concat(iconStyle)}>
                                                <ImportContactIcon />
                                            </IconButton>}
                                icon={<IconButton className={'quranHeaderIcon quranHeaderIcon_disable'}>
                                         <ImportContactIcon />
                                     </IconButton>}
                                onChange={this.handleReadingMode}
                                className={'quranHeaderIcon '.concat(iconStyle)}
                            />
                        </Tooltip>
                        <DisplayOption />
                    </div>

                    <Typography id="quranPageHeaderTitle" variant='body1' color='textSecondary'>
                        {(this.props.store.userData.langInfo.lang === 'fa' || this.props.store.userData.langInfo.lang === 'ar') ? 
                            suraDetails.ar_name : suraDetails.en_name}
                    </Typography>
                </AppBar>
            </Slide>
        );
    }
}

function mapStateToProps(state, ownProps){
	return {
		'store': {
            userData : {
                sura:state.userData.sura,
                langInfo : state.userData.langInfo,
                locale : {
                    readingMode: state.userData.locale.readingMode,
                    nightmode:state.userData.locale.nightmode,
                },
                theme:state.userData.theme
            },
            transData:{
                showTranslate:state.transData.showTranslate,
            }
        }
	};
}

function mapDispatchToProps(dispatch){
	return {
		actions: bindActionCreators(quranActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(QuranHeader);
import React from 'react';
import { AutoRotatingCarousel, Slide } from 'material-auto-rotating-carousel'
import {connect} from 'react-redux';

class SliderIst extends React.Component{

  constructor(props, context) {
		super(props, context);
	}

  shouldComponentUpdate(nextProps,nextState){
		return(
            this.props.store.userData.theme!==nextProps.store.userData.theme 
		)
	}

  render(){
    let sliderImage=<img src="../../img/forghan-istikharah.svg" width="250px" alt={this.props.store.userData.locale.ist_istikharah}/>;
    return (
        <AutoRotatingCarousel
          label="راهنما"
          mobile={true}
          open
          interval={10000}
          autoplay={false}
          ButtonProps={{style:{backgroundColor:'#fff'}}}
          ModalProps={{id:'marc'}}
        >
          <Slide
            media={sliderImage}
            id={this.props.store.userData.theme==='light'?'istSlider_light':'istSlider_dark'}
            title="استخاره"
            subtitle="این سامانه استخاره را با ذکر مورد استفاده خادم‌القرآن، آیت‌الله‌العظمی دکتر محمد صادقی تهرانی و بر مبنای شرح استخاره معظمٌ له ارائه می‌دهد."
            
          />
          <Slide
            media={sliderImage}
            title="استخاره"
            id={this.props.store.userData.theme==='light'?'istSlider_light':'istSlider_dark'}
            subtitle="استخاره در لغت به معنای بهترین خواستن و  خیر خواستن از خدا است و در اصطلاح دینی،  واگذاردن انتخاب به خداوند در کاری است که انسان در انجام دادن آن درنگ و تأمل دارد."
          />
          
        </AutoRotatingCarousel>
    );
  }
};

function mapStateToProps(state, ownProps){
  return {
  store:{
    'userData':  {
      theme:state.userData.theme,
      locale:state.userData.locale
    }
  }
      
  };
}

function mapDispatchToProps(dispatch){
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SliderIst);
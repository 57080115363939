import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as quranActions from '../../actions/actionCreators';
import initialState from '../../store/initialState';
import { TwitterPicker } from 'react-color';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DisplayFormatIcon from '@material-ui/icons/FormatColorText';
import AlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import AlignRightIcon from '@material-ui/icons/FormatAlignRight';
import AlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import ColorFillIcon from '@material-ui/icons/FormatColorFill';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ListSubheader from '@material-ui/core/ListSubheader';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { Container, FormControlLabel, InputLabel, Switch, Tooltip } from '@material-ui/core';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import MyTwitterPicker from './MyTwitterPicker';

class DisplayOption extends React.Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			open: false,
			tabIndex: 0,
			displayColorPicker: false,
			colorPickerOwner:null,
			colorPickerPosition:{x:0,y:0},
		}

		this.handleOpenDialog = this.handleOpenDialog.bind(this);
		this.handleCloseDialog = this.handleCloseDialog.bind(this);
		this.handleChangeTabs = this.handleChangeTabs.bind(this);
		this.handleChangeColor = this.handleChangeColor.bind(this);
		this.changeLineHeight = this.changeLineHeight.bind(this);
		this.changeWordSpacing = this.changeWordSpacing.bind(this);
		this.changeLetterSpacing = this.changeLetterSpacing.bind(this);
		this.changeJustify = this.changeJustify.bind(this);
		this.changeFontFamily = this.changeFontFamily.bind(this);
		this.changeFontSize = this.changeFontSize.bind(this);
		this.resetDisplayOptions = this.resetDisplayOptions.bind(this);
		this.resetTajweedOptions = this.resetTajweedOptions.bind(this);
		this.selectByDir = this.selectByDir.bind(this);
		this.triggerTajwidColorPicker=this.triggerTajwidColorPicker.bind(this);
		this.tajwidOnChangeColor=this.tajwidOnChangeColor.bind(this);
		this.handleTajweedSwitch=this.handleTajweedSwitch.bind(this);
		this.generateTajweedItems=this.generateTajweedItems.bind(this);
		this.changeTextEdition=this.changeTextEdition.bind(this);
	}

	handleOpenDialog() {
		this.setState({open: true});
	};

	handleCloseDialog() {
		this.setState({open: false});
	};

	handleChangeTabs(e,value) {
		this.setState({
		  	tabIndex: value
		});
	};

	handleChangeColor(color) {
		let displayQuran =  Object.assign({}, this.props.store.quranData.quranDisplay, {
			color: color.hex
		});
		this.props.actions.changeQuranDisplay(displayQuran);
	};

	changeLineHeight(event) {
		let displayQuran =  Object.assign({}, this.props.store.quranData.quranDisplay, {
			lineHeight: event.target.value + "px"
		});
		this.props.actions.changeQuranDisplay(displayQuran);
	}

	changeWordSpacing(event) {
		let displayQuran =  Object.assign({}, this.props.store.quranData.quranDisplay, {
			wordSpacing: event.target.value + "px"
		});
		this.props.actions.changeQuranDisplay(displayQuran);
	}

	changeLetterSpacing(event) {
		let displayQuran =  Object.assign({}, this.props.store.quranData.quranDisplay, {
			letterSpacing: event.target.value + "px"
		});
		this.props.actions.changeQuranDisplay(displayQuran);
	}

	changeJustify(event, justify) {
		let justifyText = 'right';
		if (justify) {
			justifyText = 'justify';
		};

		let displayQuran =  Object.assign({}, this.props.store.quranData.quranDisplay, {
			textAlign: justifyText
		});

		this.props.actions.changeQuranDisplay(displayQuran);
	}

	changeFontFamily(event) {
		let displayQuran =  Object.assign({}, this.props.store.quranData.quranDisplay, {
			fontFamily: event.target.value
		});
		this.props.actions.changeQuranDisplay(displayQuran);
	}

	changeFontSize(event) {
		let fs=((''+event.target.value).length>0?parseInt(event.target.value):this.props.store.quranData.quranDisplay.fontSize);
		let displayQuran =  Object.assign({}, this.props.store.quranData.quranDisplay, {
			fontSize: fs + "px"
		});
		this.props.actions.changeQuranDisplay(displayQuran);
	}

	resetDisplayOptions() {
		this.props.actions.changeQuranDisplay(initialState.quranData.quranDisplay);
	}

	resetTajweedOptions() {
		let to=initialState.quranData.quranTajwid;
		to['useTajwidColor']=this.props.store.quranData.quranTajwid.useTajwidColor;
		this.props.actions.setTajwidColors(to);
	}

	selectByDir(rtl,ltr){
        return (this.props.store.userData.langInfo.lang !== undefined && this.props.store.userData.langInfo.direction === 'rtl') ?
            rtl : ltr;
	}
	
	triggerTajwidColorPicker(e,item){
		
		let xpos=(e.clientX+276)>window.innerWidth ? e.clientX-(e.clientX+276-window.innerWidth):e.clientX;
		this.setState({colorPickerOwner:item, displayColorPicker:true,colorPickerPosition:{x:xpos,y:e.clientY}})
	}

	tajwidOnChangeColor(color){
		let obj={...this.props.store.quranData.quranTajwid,[this.state.colorPickerOwner]:color.hex};
		this.setState({displayColorPicker: false,colorPickerOwner:null})
		this.props.actions.setTajwidColors(obj);
	}

	handleTajweedSwitch(event){
		this.props.actions.setTajwidColors({...this.props.store.quranData.quranTajwid,useTajwidColor: event.target.checked})
	}
	generateTajweedItems(item){
		return(
			<Container key={item}>
				<InputLabel disabled={!this.props.store.quranData.quranTajwid.useTajwidColor}
					className={'optionsInputLabel '.concat(this.selectByDir('optionsInputLable_rtl','optionsInputLable_ltr'))}
				>
					{this.props.store.userData.locale[item]}
				</InputLabel>
				<IconButton disabled={!this.props.store.quranData.quranTajwid.useTajwidColor}
					className={'optionsInputLabel '.concat(this.selectByDir('optionsInputLable_ltr','optionsInputLable_rtl'))}
					onClick={(e)=>{this.triggerTajwidColorPicker(e,item)}}
				>
					<FiberManualRecordRoundedIcon style={{color:(this.props.store.quranData.quranTajwid.useTajwidColor)?this.props.store.quranData.quranTajwid[item]:'rgba(0, 0, 0, 0.54)'}}/>
				</IconButton>
			</Container>
		)
	}

	changeTextEdition(event){
		this.props.actions.loadQuranText(event.target.value);
	}

  render() {

	let iconStyle=(this.props.store.userData.theme==='light'?'quranHeaderIcon_light':'quranHeaderIcon_dark')
	let tajweedItems=['hamzatUlWasl','silent','lamShamsiyah','maddaNormal','maddaPermissible','maddaNecessary'
			,'qalaqah','maddaObligatory','ikhafaShafawi','ikhafa','idghamShafawi','iqlab','idghamWithGhunnah'
			,'idghamWithoutGhunnah','idghamMutajanisayn','idghamMutaqaribayn','ghunnah'];
    return (
      <React.Fragment>
		<Tooltip title={this.props.store.userData.locale.displayOption}
                    classes={{tooltip:'tooltip'}}
                >
			<IconButton
				tooltip={this.props.store.userData.locale.displayOption}
				onClick={this.handleOpenDialog}
				className={'quranHeaderIcon '.concat(iconStyle) }
			>
				<DisplayFormatIcon />
			</IconButton>
		</Tooltip>
        <Dialog
			modal={'false'}
			open={this.state.open}
			onClose={this.handleCloseDialog}
			PaperProps={{id:'optionsDialog'}}
        >
			<Tabs
				onChange={this.handleChangeTabs}
				value={this.state.tabIndex}
				className={this.selectByDir("tabs_rtl","tabs_ltr")}
				centered
			>
				<Tab className='optionsTab' label={this.props.store.userData.locale.appearance} value={0} />
				<Tab className='optionsTab' label={this.props.store.userData.locale.tajweed} value={1} 
					disabled={this.props.store.quranData.edition==='tj'?false:true}
				/>
			</Tabs>

			<div id='optionsDisplayTabContent' style={{display:(this.state.tabIndex===0 ? 'grid':'none')}}>
				{/* Font options */}
				<div className="optionsDisplayInside">
					<ListSubheader 
						className={this.selectByDir('optionsAppereanceTitle_rtl','optionsAppereanceTitle_ltr')}
					>
						{this.props.store.userData.locale.font}
					</ListSubheader>
					<br/><br/><br/>

					<InputLabel 
						className={'optionsInputLabel '.concat(this.selectByDir('optionsInputLable_rtl','optionsInputLable_ltr'))}
					>
						{this.props.store.userData.locale.fontFamily}
					</InputLabel>
					<Select
						value={this.props.store.quranData.quranDisplay.fontFamily}
						onChange={this.changeFontFamily}
						className={'optionsInput'}
					>
						<MenuItem value={'Noon'} >Noon</MenuItem>
						<MenuItem value={'Neyrizi'} >Neyrizi</MenuItem>
						<MenuItem value={'Quran Taha'} >Quran Taha</MenuItem>
						<MenuItem value={'Kitab'} >Kitab</MenuItem>
					</Select>

					<InputLabel 
						className={'optionsInputLabel '.concat(this.selectByDir('optionsInputLable_rtl','optionsInputLable_ltr'))}
					>
						{this.props.store.userData.locale.fontSize}
					</InputLabel>
					<TextField
						type="number"
						value={parseInt(this.props.store.quranData.quranDisplay.fontSize)}
						onChange={this.changeFontSize}
						name="fontSize" 
						className={'optionsInput'}
					/>

					<br/><br/><br/>

					<InputLabel 
						className={'optionsInputLabel '.concat(this.selectByDir('optionsInputLable_rtl','optionsInputLable_ltr'))}
					>
						{this.props.store.userData.locale.fontColor}
					</InputLabel>
					<ColorFillIcon className={'optionsInputLabel '.concat(this.selectByDir('optionsInputLable_rtl','optionsInputLable_ltr'))}/> 
					

					<br/><br/><br/><br/><br/>

					<TwitterPicker 
						color={this.props.store.quranData.quranDisplay.color}
						onChange={ this.handleChangeColor }
						triangle={this.selectByDir('top-right','top-left')}
						colors={['#212121', '#263238', '#3E2723', '#BF360C', '#004D40', '#1B5E20', 
							'#827717', '#1A237E', '#0D47A1', '#006064', '#311B92', '#B71C1C', '#880E4F',
							'#4A148C']}
						width='100%'
					/>
				</div>

				{/* text options */}
				<div className="optionsDisplayInside">
					<ListSubheader className={this.selectByDir('optionsAppereanceTitle_rtl','optionsAppereanceTitle_ltr')}>
						{this.props.store.userData.locale.text}
					</ListSubheader>
					<br/><br/><br/>

					<InputLabel 
						className={'optionsInputLabel '.concat(this.selectByDir('optionsInputLable_rtl','optionsInputLable_ltr'))}
					>
						{this.props.store.userData.locale.lineHeight}
					</InputLabel>
					<TextField
						type="number"
						value={parseInt(this.props.store.quranData.quranDisplay.lineHeight)}
						onChange={this.changeLineHeight}
						name="lineHeight" 
						className={'optionsInput'}
					/>

					<InputLabel 
						className={'optionsInputLabel '.concat(this.selectByDir('optionsInputLable_rtl','optionsInputLable_ltr'))}
					>
						{this.props.store.userData.locale.wordSpacing}
					</InputLabel>
					<TextField
						type="number"
						value={parseInt(this.props.store.quranData.quranDisplay.wordSpacing)}
						onChange={this.changeWordSpacing}
						name="wordSpacing"
						className={'optionsInput'}
					/>

					<InputLabel 
						className={'optionsInputLabel '.concat(this.selectByDir('optionsInputLable_rtl','optionsInputLable_ltr'))}
					>
						{this.props.store.userData.locale.letterSpacing}
					</InputLabel>
					<TextField
						type="number"
						value={parseInt(this.props.store.quranData.quranDisplay.letterSpacing)}
						onChange={this.changeLetterSpacing}
						name="letterSpacing"
						className={'optionsInput'}
					/>

					<FormControlLabel
						className={'optionsInput'}
						control={<Checkbox
									defaultChecked={this.props.store.quranData.quranDisplay.textAlign === 'justify' ? true : false}
									checkedIcon={<AlignJustifyIcon />}
									icon={this.selectByDir(<AlignRightIcon />,<AlignLeftIcon />)}
									onChange={this.changeJustify}
									/>
								}
						label={this.props.store.userData.locale.alignment} 
					/>

					<InputLabel 
						className={'optionsInputLabel '.concat(this.selectByDir('optionsInputLable_rtl','optionsInputLable_ltr'))}
					>
						{this.props.store.userData.locale.textEdition}
					</InputLabel>
					<Select
						value={this.props.store.quranData.edition}
						onChange={this.changeTextEdition}
						className={'optionsInput'}
					>
						<Tooltip value={'si'} title={this.props.store.userData.locale.simpleDescription}
                    classes={{tooltip:'tooltip'}}
                >
							<MenuItem >Simple</MenuItem>
						</Tooltip>
						<Tooltip value={'se'} title={this.props.store.userData.locale.simpleEnhancedDescription}
                    classes={{tooltip:'tooltip'}}
                >
							<MenuItem  >Simple-Enhanced</MenuItem>
						</Tooltip>
						<Tooltip value={'sm'} title={this.props.store.userData.locale.simpleMinimalDescription}
                    classes={{tooltip:'tooltip'}}
                >
							<MenuItem >Simple-Minimal</MenuItem>
						</Tooltip>
						<Tooltip value={'sc'} title={this.props.store.userData.locale.simpleCleanDescription}
                    classes={{tooltip:'tooltip'}}
                >
							<MenuItem >Simple-Clean</MenuItem>
						</Tooltip>
						<Tooltip value={'ut'} title={this.props.store.userData.locale.uthmaniDescription}
                    classes={{tooltip:'tooltip'}}
                >
							<MenuItem >Uthmani</MenuItem>
						</Tooltip>
						<Tooltip value={'um'} title={this.props.store.userData.locale.uthmaniMinimalDescription}
                    classes={{tooltip:'tooltip'}}
                >
							<MenuItem >Uthmani-Minimal</MenuItem>
						</Tooltip>
						<Tooltip value={'tj'} title={this.props.store.userData.locale.tajwidDescription}
                    classes={{tooltip:'tooltip'}}
                >
							<MenuItem >Tajwid</MenuItem>
						</Tooltip>
					</Select>

				</div>

				<Button
					onClick={this.resetDisplayOptions}
				>
					{this.props.store.userData.locale.reset}
				</Button>

			</div>
			
			{/* tajwid tab */}
			<div id='optionsTajwidTabContent' style={{display:(this.state.tabIndex===1 ? 'grid':'none')}}>
				{/* tajweed switch */}
				<Container>
					<InputLabel className={'optionsInputLabel '.concat(this.selectByDir('optionsInputLable_rtl','optionsInputLable_ltr'))}>{this.props.store.userData.locale.tajweedRules}</InputLabel>
					<Switch
						className={'optionsSwitch '.concat(this.selectByDir('optionsSwitch_ltr','optionsSwitch_rtl'))}
						checked={this.props.store.quranData.quranTajwid.useTajwidColor}
						onChange={this.handleTajweedSwitch}
					/>
				</Container>

				{/*tajweed items */}
				{tajweedItems.map(this.generateTajweedItems)}

				<Button
					onClick={this.resetTajweedOptions}
					disabled={!this.props.store.quranData.quranTajwid.useTajwidColor}
				>
					{this.props.store.userData.locale.reset}
				</Button>

				{ /*color picker */}
				<MyTwitterPicker
					show={this.state.displayColorPicker}
					top={this.state.colorPickerPosition.y}
					left={this.state.colorPickerPosition.x}
					onOutClick={()=>{this.setState({colorPickerOwner:null,displayColorPicker:false})}}
					onChange={this.tajwidOnChangeColor}
				/>
			</div>

        </Dialog>
      </React.Fragment>
    );
  }
}


function mapStateToProps(state, ownProps){
	return {
		store:{
			'quranData': {
				edition:state.quranData.edition,
				quranDisplay:state.quranData.quranDisplay,
				quranTajwid:state.quranData.quranTajwid
			},
			'userData':{
				langInfo:state.userData.langInfo,
				locale:state.userData.locale,
				theme:state.userData.theme
			}
		}
    };
}

function mapDispatchToProps(dispatch){
	return {
		actions: bindActionCreators(quranActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayOption);
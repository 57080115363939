import React from 'react';
import { AutoRotatingCarousel, Slide } from 'material-auto-rotating-carousel'
import {connect} from 'react-redux';

class SliderKh extends React.Component {
  constructor(props){
    super(props);
  }

  shouldComponentUpdate(nextProps,nextState){
		return(
            this.props.store.userData.theme!==nextProps.store.userData.theme 
		)
	}
  
  render(){
    let sliderImage=<img src="../../img/forghan-istikharah.svg" width="250px" alt={this.props.store.userData.locale.ist_istikharah}/>;
    return (
        <AutoRotatingCarousel
          // label="راهنما"
          mobile={true}
          open
          interval={10000}
          autoplay={false}
          ButtonProps={{style:{backgroundColor:'#fff'}}}
          ModalProps={{id:'marc'}}
        >
          <Slide
            media={sliderImage}
            id={this.props.store.userData.theme==='light'?'istSlider_light':'istSlider_dark'}
            title={this.props.store.userData.locale.khatam + ' ' + this.props.store.userData.locale.quran}
            subtitle=""
          />        
        </AutoRotatingCarousel>
    );
  }
};

function mapStateToProps(state, ownProps){
  return {
  store:{
    'userData':  {
      theme:state.userData.theme,
      locale:state.userData.locale
    }
  }
      
  };
}

function mapDispatchToProps(dispatch){
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SliderKh);
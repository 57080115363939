import {Box, Button, Dialog, DialogTitle, IconButton, TextField, Tooltip} from "@material-ui/core";
import React, { useState } from 'react';
import {  useSelector } from "react-redux";
import CloseIcon from '@material-ui/icons/Close';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

export default function ShareKhatamDialog(props){

    const [isOpen,setOpen]= useState(props.open);

    React.useEffect(() => setOpen(props.open), [props.open]);

    const langInfo = useSelector((state) => state.userData.langInfo);
    const localeShareKhatam = useSelector((state) => state.userData.locale.shareKhatam);

    function handleCloseDialog(){
        props.onClose();
    }

    function copyToClipboard(){
        let copyText = document.getElementById("khatamLink");
        copyText.removeAttribute('disabled');
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");

        copyText.setAttribute('disabled','true');
    }

    return(
        <Dialog open={isOpen} maxWidth='lg' BackdropProps={{style:{backgroundColor:'#00000000'}}}
            id='newKhatamBackdrop'
            onClose={handleCloseDialog}
            PaperProps={{id:'newKhatamDialog'}}
        >
            <Box display='flex' flexDirection={langInfo.direction==='rtl'?'row-reverse':'row'} justifyContent='space-between'>
                <DialogTitle id='newKhatamTitle' style={{direction:langInfo.direction}} disableTypography>
                    {localeShareKhatam}
                </DialogTitle>
                <IconButton className={'drawerCloseIcon_dark'} onClick={handleCloseDialog}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box display='flex' direction='row' justifyContent='center' marginBottom='5vh' marginTop='5vh' alignItems='center'>
                <TextField 
                    variant='filled'
                    defaultValue={props.link}
                    disabled
                    style={{width:'85%',marginInlineStart:'10px'}}
                    inputProps={{id:'khatamLink'}}
                />
                <Tooltip title={'dd'}
                    classes={{tooltip:'tooltip'}}
                >
                    <Button>
                        <FileCopyOutlinedIcon className={'quranHeaderIcon'} fontSize='large' onClick={copyToClipboard}/>
                    </Button>
                </Tooltip>
            </Box>

        </Dialog>

    );
}
import React ,{ useState }from 'react';
import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import cyan from '@material-ui/core/colors/cyan';
import teal from '@material-ui/core/colors/teal';
import green from '@material-ui/core/colors/green';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lime from '@material-ui/core/colors/lime';
import yellow from '@material-ui/core/colors/yellow';
import amber from '@material-ui/core/colors/amber';
import orange from '@material-ui/core/colors/orange';
import deepOrange from '@material-ui/core/colors/deepOrange';
import brown from '@material-ui/core/colors/brown';
import grey from '@material-ui/core/colors/grey';
import blueGrey from '@material-ui/core/colors/blueGrey';
import { TwitterPicker } from 'react-color';
import { Slider } from '@material-ui/core';

function MyTwitterPicker(props){
    function handleShadeChange(event,value){
		setShade(value);
    };
    const [shade,setShade]=useState(500);
    return(
        props.show ? 
            <div style={{position: 'fixed',zIndex: '2',top:props.top,left:props.left,backgroundColor:"#fff",borderRadius:'4px'}}>
                <div style={{position: 'fixed',top: '0px',right: '0px',bottom: '0px',left: '0px'}} onClick={props.onOutClick}/>
                <TwitterPicker  onChange={props.onChange}
                    colors={['#333333',red[shade],pink[shade],purple[shade],
                        deepPurple[shade],indigo[shade],blue[shade],
                        lightBlue[shade],cyan[shade],teal[shade],green[shade],
                        lightGreen[shade],lime[shade],yellow[shade],amber[shade],
                        orange[shade],deepOrange[shade],brown[shade],grey[shade],
                        blueGrey[shade]
                    ]}
                />
                <Slider
                    className={'optionsSlider '}
                    defaultValue={500}
                    onChange={handleShadeChange}
                    marks={[{value:50},{value:100},{value:200},{value:300},{value:400},{value:500},
                        {value:600},{value:700},{value:800},{value:900}]}
                    step={null}
                    min={50}
                    max={900}
                    valueLabelDisplay="auto"
                />
            </div> :
             null
    );
}
export default MyTwitterPicker;